import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { PotsExtension } from 'src/app/models/voice-config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { nameValidator } from '../../../validators/nameValidator';
import { descriptionRegEx } from 'src/app/util/regex-utils';

@Component({
  selector: 'app-edit-pots-extension',
  templateUrl: './edit-pots-extension.component.html',
  styleUrls: ['./edit-pots-extension.component.scss']
})
export class EditPotsExtensionComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() isViewing = false;
  @Input() isReviewer = false;
  @Input() potsExtension?: PotsExtension;
  @Input() potsExtensionBase?: PotsExtension;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isEditing = false;
  configUtils = ConfigUtils;

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  line1Base = false;
  line2Base = false;
  extensionBase = '';
  regionBase = '';
  outboundLineBase = '';
  descriptionBase = '';

  // Form
  outboundLines = [
    { name: "Line 1", value: "line_1" },
    { name: "Line 2", value: "line_2" },
    { name: "Any Available", value: "any" }
  ];

  potsExtForm = this.fb.group({
    extension: [{ value: null, disabled: true }, Validators.required],
    inboundLine1: [{ value: null }],
    inboundLine2: [{ value: null }],
    outboundLine: [{ value: null }],
    description: [{ value: null },
      [Validators.maxLength(50), nameValidator(descriptionRegEx)]],
  });
  
  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;

    } finally {
      this.isLoading = false;
    }
  }

  async onShow() {
    console.log("onShow() potsExtension = ", this.potsExtension);
    if (this.isViewing || this.isReviewer) {
      this.disableControlsForView();
      if (this.potsExtensionBase) {
        this.extensionBase = this.potsExtensionBase.ext;
        this.line1Base = this.potsExtensionBase.inbound.line_1;
        this.line2Base = this.potsExtensionBase.inbound.line_2;
        this.outboundLineBase = this.potsExtensionBase.outbound;
        this.descriptionBase = this.potsExtensionBase.description;
      }
    }

    if (this.potsExtension) {
      this.isEditing = true;
      this.potsExtForm.patchValue({
        extension: this.potsExtension.ext,
        inboundLine1: this.potsExtension.inbound.line_1,
        inboundLine2: this.potsExtension.inbound.line_2,
        outboundLine: this.potsExtension.outbound,
        description: this.potsExtension.description
      });
    }
  }

  async save() {
    console.log("save() - this.potsExtForm.value = ", this.potsExtForm.value);
    try {
      this.blockUI?.start();

      const outPotsExtension = {
        ext: this.potsExtension?.ext,
        inbound: {
          line_1: this.potsExtForm.value.inboundLine1,
          line_2: this.potsExtForm.value.inboundLine2
        },
        outbound: this.potsExtForm.value.outboundLine,
        description: this.potsExtForm.value.description
      }

      // This calls the onClose defined in the parent ie. (onClose)="savePotsExtension($event)"
      this.onSave.emit(outPotsExtension);
      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    console.log("onCloseDialog() potsExtension = ", this.potsExtension);
    this.potsExtForm.reset();

    this.line1Base = false;
    this.line2Base = false;
    this.extensionBase = '';
    this.regionBase = '';
    this.outboundLineBase = '';
    this.descriptionBase = '';
  }

  disableControlsForView() {
    this.potsExtForm.get('extension')?.disable();
    this.potsExtForm.get('inboundLine1')?.disable();
    this.potsExtForm.get('inboundLine2')?.disable();
    this.potsExtForm.get('outboundLine')?.disable();
    this.potsExtForm.get('description')?.disable();
  }
}
