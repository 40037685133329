import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  isLoading = false;

  firmwareVersions: any[] = [];
  //baseDownloadUrl = environment.api.baseUrl + "/firmware/download";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private breadcrumbService: AppBreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Help' }]);
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;

    } finally {
      this.isLoading = false;
    }
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onClose() {
    this.load();
  }
}
