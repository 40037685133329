
// Validation RegEx for Device Config
export const apnRegEx = /^[0-9a-zA-Z._()#\-\/ ]+$/;
export const cellUserRegEx = /^[0-9a-zA-Z._()#\-\/ ]*$/;
export const cellPwdRegEx = /^[0-9a-zA-Z._()#\-\/ ]*$/;

// Validation RegEx for SIPS Extension
export const usernameRegEx = /^[0-9a-zA-Z._()#\-\/ ]*$/;
export const pwdRegEx = /^[0-9a-zA-Z._()#\-\/ ]*$/;

// Validation RegEx for SIPS and POTS Extension 
export const descriptionRegEx = /^[0-9a-zA-Z%&+,.:=_()#*@\/\-\/\ ]*$/;

// Validation RegEx for Device
export const friendlyNameRegEx = /^[0-9a-zA-Z%&+,.:=_()#*@\/\-\/\ ]*$/;
export const assetTagRegEx = /^[0-9a-zA-Z%&+,.:=_()#*@\/\-\/\ ]*$/;
