<div class="container">
  <h1>Frequently Asked Questions</h1>
  <div class="card">
    <p-accordion [multiple]="true">
      <p-accordionTab header="1.	Why can I not connect to the SkyLink WiFi?">
        Verify that the WiFi SSID (located on the box and back panel of the device)
        matches the WiFi network shown on your personal device. If they match, try
        one (or all) of the following:
        <ul>
          <li>If typing in the WiFi password, use the number zero instead of the letter “O”.</li>
          <li>If you did not previously, use a phone or tablet to scan the QR code found on the label to automatically connect to the WiFi.</li>
          <li>Switch your mobile device into airplane mode before connecting to the SkyLink WiFi.</li>
          <li>If you used a mobile device, try using a tablet (or vice versa) to log on.</li>
        </ul>
        If you are still unable to connect to the SkyLink device’s WiFi, please contact 
        the Blue Sky Network support team for further assistance.         
      </p-accordionTab>
      <p-accordionTab header="2.	What are the cellular modem options? Can they be exchanged?">
        Yes, Blue Sky Network can support any Key B M.2 cellular card with advanced notice. <br/>
        Currently, the cellular modem options are:
        <ul>
          <li>Quectel EM06-A (North America / Mexico)</li>
          <li>Quectel EM06-E (Europe / Middle East / Africa / Asia Pacific / Brazil)</li>
          <li>Quectel EM12G (Brazil)</li>
        </ul>
      </p-accordionTab>
      <p-accordionTab header="3.	Which WiFi frequencies are used for the SkyLink Data Gateway?">
        The WiFi uses 2.4Ghz and 5Ghz, and the channels depend on the regulatory
        domain selected. More information can be found in the User Guide under
        the WiFi & Cellular section. If using a more capable Companion WiFi option,
        you will want to disable the Gateway WiFi.
      </p-accordionTab>
      <p-accordionTab header="4.	I am not receiving incoming call notifications on SIP extensions.">
        The SkyLink device has 2 incoming Iridium phone lines. It is possible that you are not receiving
        call notifications because the SIP extension you are using is not configured to receive
        incoming calls from the Iridium line you are calling. <br/>
        <br/>
        In the SkyLink Device Interface:
        <ol>
          <li>Navigate to the Voice Extensions screen</li>
          <li>Click ‘Edit’ on your extension</li>
          <li>Ensure that both boxes in the Inbound Line(s) field are ticked, then click ‘Save.’</li>
        </ol>
      </p-accordionTab>
      <p-accordionTab header="5.	Why did my device not power on after I connected the power cable?">
        Confirm that the correct cable is attached to the power port on the left-hand side of the
        device’s bottom panel. Your SkyLink kit will come with one of the following power adapters:
        <ul>
          <li>12V Cigarette Lighter Adapter</li>
          <li>SkyLink DC Pigtail Harness</li>
          <li>POE Injector (self-assembly kit)</li>
          <li>AC/DC Power Supply (self-assembly kit)</li>
        </ul>
        After verifying the correct cable and port, disconnect and then re-apply the power cable into
        the port. Connect the adapter into the appropriate power source (e.g., a wall or vehicle outlet).
        The power LED indicator located on the top panel of the device will show a steady blue light
        when the device is ready to use.<br/>
        <br/>
        <b>NOTE</b>: If self-installing cellular and Iridium SIM cards, verify that they are in the correct
        holders. Additionally, ensure they are not placed in the micro SD slot also present in the
        connectivity port.
      </p-accordionTab>
      <p-accordionTab header="6.  My kit came with a Power Over Ethernet (POE) power source. What is it and how do I use it?">
        Power over Ethernet (POE) is a technology that passes electric power to your device
        over an Ethernet cable, powering it without the need for a traditional AC power supply.<br/>
        <br/>
        To use this technology, you will need the POE Injector (which adds power to a
        non-powered ethernet connection) included in your kit or a POE enabled switch
        (which is a network switch that provides power to connected devices).<br/>
        <br/>
        We support both common standards: regular POE (sometimes referred to as 802.3at)
        and POE+ (sometimes referred to as 802.3af).<br/>
        <br/>
      </p-accordionTab>
      <p-accordionTab header="7.	Can I use an alternative cellular antenna?">
        Yes. The SkyLink device has 3 antennas:
        <ol>
          <li>1 internal GPS</li>
          <li>1 internal LTE diversity</li>
          <li>1 external LTE main</li>
        </ol>
        The LTE main antenna can be replaced with any proper LTE antenna that you wish to use.
      </p-accordionTab>
      <p-accordionTab header="8.	Can I use the existing Iridium antenna installed in my building with my Data Gateway device?">
        No, Certus antennas for SkyLink are required. The following are acceptable to use:
        <ul>
          <li>MARUWA (MHL-1621C)</li>
          <li>High-Profile Maxtena (M1621HCT-HP)</li>
          <li>Sensor Systems (S67-1575-414)</li>
          <li>SCAN (65020-000)</li>
        </ul>
      </p-accordionTab>
      <p-accordionTab header="9.	What is included in an RS232 cable kit? ">
        The SkyLink RS232 cable kit comes with a DB9F connector and conforms to
        the RS232 standard pinout for a DB9. NOTE: RS232 cables for SkyLink are
        proprietary and available only from Blue Sky Network.
        Please contact <a href = "mailto: sales@blueskynetwork.com">sales@blueskynetwork.com</a> or your reseller to purchase.
      </p-accordionTab>
      <p-accordionTab header="10.	I received a SkyLink DC Pigtail Harness. What are its specs? ">
        This is the cable you receive if you request the currently labeled ‘SL100-IS, SkyLink 10’
        Power Interface Cable with QuickPosition (QPos).’ The specs are as follows:<br/>
        <br/>
        <b>OPERATING PARAMETERS</b><br/>
        Electrical<br/>
        •	External power:
        <ul>
          <li>10-34 VDC (12V nominal power)</li>
          <li>POE</li>
        </ul>
        •	Power consumption<br/>
        <table>
          <tr>
            <th>Function</th>
            <th>Color</th>
          </tr>
          <tr>
            <td>+Vin</td>
            <td>Brown</td>
          </tr>
          <tr>
            <td>GND</td>
            <td>Green</td>
          </tr>
          <tr>
            <td>SOS</td>
            <td>White</td>
          </tr>
        </table>

      </p-accordionTab>
      <p-accordionTab header="11.	 How do I prioritize data routing to use GSM or SAT first?">
        Navigate to the General Settings screen on the SkyLink Device Interface. Choose your desired
        configuration from the Data Routing drop-down menu and save your selection. Settings are
        applied immediately.<br/>
        <br/>
        <b>NOTE</b>: Iridium QoS (also called Iridium secondary data flows) are not supported on Certus
        100, therefore, BSN cannot offer per-stream priorities or VLANs over the Iridium link.
      </p-accordionTab>
      <p-accordionTab header="12.	Where can I find the current SkyLink and satellite firmware versions? ">
        The Status screen on the SkyLink Device Interface has the unit’s current hardware and software
        versions. ‘SYN267 REV F’ refers to the chipsets/hardware revision and ‘2.02-210627-0009’
        refers to the firmware revision/date/time. The Diagnostics screen contains the Certus 9770
        modem’s current version.
      </p-accordionTab>
      <p-accordionTab header="13.	How can I manage a fleet of SkyLink devices?">
        The SkyLink Cloud Services portal allows you to configure, monitor, and manage fleets of devices.
        Use the SkyLink Device Interface for local management of a particular device.
      </p-accordionTab>
      <p-accordionTab header="14.	How do I set up my apps to low bandwidth?">
        Navigate to each app’s Settings page and select the option to use less data. Please note
        that not all apps have this capability. On iPhone, ‘Low Data Mode’ is available on the WiFi
        Config screen for each WiFi network.
      </p-accordionTab>
      <p-accordionTab header="15.	How can I optimize the use of my SIP phone apps (e.g., GS Wave, Linphone)?">
        Below are a few setup pointers for SIP phone apps.
        iPhone/Android settings:
        <ul>
          <li>Airplane mode</li>
          <li>Smartphone Settings > Wave Lite App > Enable ‘Local Network’ access</li>
          <li>Smartphone Settings > Wave Lite App > Disable ‘Cellular Data’</li>
        </ul>
        GS Wave settings:
        <ul>
          <li>‘WiFi only’ mode enabled</li>
          <li>Account Name: 510 (510-516 available default)</li>
          <li>SIP Server: 192.168.111.1 (unless SkyLink Device Interface IP has been changed)</li>
          <li>SIP User ID: 510</li>
          <li>Authentication ID: 510</li>
          <li>Password: 510</li>
        </ul>
        Additional settings for other SIP phone apps (Linphone, etc.):
        <ul>
          <li>Codecs (if setting is available): Enable – A-LAW, 722. Disable – U-LAW</li>
        </ul>
      </p-accordionTab>
      <p-accordionTab header="16.	What is the battery performance of the SkyLink Battery Pack?">
        The SkyLink Battery Pack has a run-time of up to 48 hours based on a typical
        80 stand-by/20 transmitting duty cycle.
      </p-accordionTab>
      <p-accordionTab header="17.	What is the battery performance of the SkyLink TOC BOX?">
        The 2 batteries attached to the system provide up to 24 hours of operation in normal
        mode and up to 10 hours of operation in peak mode.
      </p-accordionTab>
      <p-accordionTab header="18.	Is the SkyLink Battery Pack safe/approved for aviation carry-on luggage?">
        Yes, the Battery Pack meets current <a href="https://www.tsa.gov/travel/security-screening/whatcanibring/items/lithium-batteries-more-100-watt-hours">TSA requirements</a> for carry-on luggage.
      </p-accordionTab>
      <p-accordionTab header="19.	What is the safety distance between the Data Gateway and human interaction?">
        To avoid exposure to antenna radiation, personnel should maintain a safe distance of 30 cm. (11.8 in.)
        minimum from the unit while it is in operation.
      </p-accordionTab>
      <p-accordionTab header="20.	Why is there a billing discrepancy between the data usage seen on my SkyLink device versus my invoice?">
        The SkyLink device’s billing feature is only an approximation, similar to what you see on your cellphone
        versus a bill from your service provider.
      </p-accordionTab>
      <p-accordionTab header="21.	Will my device overheat if left outside for too long?">
        The SkyLink device operates normally in temperatures between -40°C - +70°C. Prolonged exposure to
        temperatures outside of this range may cause the device to lose functionality. Please see the
        SkyLink User Guide for a full list of device specifications.
      </p-accordionTab>
    </p-accordion>
  </div>
</div>

