import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { DhcpReservationModel } from 'src/app/models/config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { commentValidator } from '../../../../validators/commentValidator';
import { duplicateValidator } from '../../../../validators/duplicateValidator';
import { hostnameValidator } from '../../../../validators/hostnameValidator';
import { ipv4Validator } from '../../../../validators/ipv4Validator';
import { macAddressValidator } from '../../../../validators/macAddressValidator';
import { nameValidator } from '../../../../validators/nameValidator';
import { tagValidator } from '../../../../validators/tagValidator';

@Component({
  selector: 'app-add-static-dhcp-lease',
  templateUrl: './add-static-dhcp-lease.component.html',
  styleUrls: ['./add-static-dhcp-lease.component.scss']
})
export class AddStaticDhcpLeaseComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() isAdding = false;
  @Input() isViewing = false;
  @Input() isReviewer = false;
  @Input() staticDhcp?: DhcpReservationModel;
  @Input() staticDhcpBase?: DhcpReservationModel;
  @Input() allStaticDhcpLeases: DhcpReservationModel[] = [];
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  configUtils = ConfigUtils;

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  name = '';
  macAddress = '';
  ipv4Address = '';
  hostname = '';
  comment? = '';

  // Form
  staticDhcpLeaseForm = this.fb.group({
    id: [{ value: null, disabled: false }],
    macAddress: [{ value: null, disabled: false }, [Validators.required, macAddressValidator(), duplicateValidator(() => this.allStaticDhcpLeases )]],
    ipv4Address: [{ value: null, disabled: false }, [Validators.required, ipv4Validator(), duplicateValidator(() => this.allStaticDhcpLeases )]],
    hostname: [{ value: null, disabled: false }, [Validators.required, hostnameValidator()]],
    name: [{ value: null, disabled: false },
      [Validators.minLength(5), Validators.maxLength(15), tagValidator()]],
    comment: [{ value: null, disabled: false },
      [Validators.maxLength(50), commentValidator()]],
  });

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit() isAdding = ", this.isAdding);
    this.load();
  }

  async load() {
    console.log("Loading AddStaticDhcpLeaseComponent");
    try {
      this.isLoading = true;
    } finally {
      this.isLoading = false;
    }
  }

  async onShow() {
    console.log("onShow() AddStaticDhcpLeaseComponent staticDhcp = ", JSON.stringify(this.staticDhcp));
    console.log("onShow() AddStaticDhcpLeaseComponent staticDhcpBase = ", JSON.stringify(this.staticDhcpBase));
    console.log("onShow() isViewing = ", this.isViewing);
    console.log("onShow() isAdding = ", this.isAdding);
    console.log("onShow() isReviewer = ", this.isReviewer);

    console.log("onShow() - allStaticDhcpLeases = ", JSON.stringify(this.allStaticDhcpLeases));

    if (this.isAdding) { // If adding new field, just clear it
      this.clearFields();
    }

    // Editing
    if (!this.isViewing && !this.isAdding) {
      this.staticDhcpLeaseForm.get('macAddress')?.disable();
    }

    if (this.isViewing || this.isReviewer) {
      this.disableControlsForView();

      // Load SIP Desired
      if (this.staticDhcpBase) {
        this.name = this.staticDhcpBase?.set_tag;
        this.macAddress = this.staticDhcpBase?.hwaddr;
        this.ipv4Address = this.staticDhcpBase?.ipv4;
        this.hostname = this.staticDhcpBase?.hostname ;
        this.comment = this.staticDhcpBase?.comment;
      }
    }

    if (this.staticDhcp) {
      this.staticDhcpLeaseForm.patchValue({
        id: this.staticDhcp.id,
        name: this.staticDhcp.set_tag,
        macAddress: this.staticDhcp.hwaddr,
        ipv4Address: this.staticDhcp.ipv4,
        hostname: this.staticDhcp.hostname,
        comment: this.staticDhcp.comment
      });
    }

    console.log("onShow() - allStaticDhcpLeases = ", JSON.stringify(this.allStaticDhcpLeases));
  }

  async onCloseDialog() {
    console.log("onCloseDialog() AddStaticDhcpLeaseComponent staticDhcp = ", JSON.stringify(this.staticDhcp));
    console.log("onCloseDialog() AddStaticDhcpLeaseComponent staticDhcpBase = ", JSON.stringify(this.staticDhcpBase));
    console.log("onCloseDialog() isViewing = ", this.isViewing);
    console.log("onCloseDialog() isAdding = ", this.isAdding);
    console.log("onCloseDialog() - allStaticDhcpLeases = ", JSON.stringify(this.allStaticDhcpLeases));

    this.staticDhcpLeaseForm.reset();
    this.enableControlsForView();
    // Only clear if you are editing
     console.log("onCloseDialog() AddStaticDhcpLeaseComponent = ", JSON.stringify(this.staticDhcp));
  }

  clearFields() {
    console.log("clearFields() AddStaticDhcpLeaseComponent advancedRule= ", JSON.stringify(this.staticDhcp));
    console.log("clearFields() AddStaticDhcpLeaseComponent advancedRuleBase= ", JSON.stringify(this.staticDhcpBase));
    console.log("clearFields() isViewing = ", this.isViewing);
    console.log("clearFields() isAdding = ", this.isAdding);

    this.staticDhcpLeaseForm.reset();

    console.log("clearFields() AddStaticDhcpLeaseComponent = ", JSON.stringify(this.staticDhcp));

    this.name = '';
    this.macAddress = '';
    this.ipv4Address = '';
    this.hostname = '';
    this.comment = '';

    console.log("clearFields() AddStaticDhcpLeaseComponent = ", JSON.stringify(this.staticDhcp));
  }

  async save() {
    console.log("save() - this.staticDhcpLeaseForm.value = ", this.staticDhcpLeaseForm.value);
    try {
      this.blockUI?.start();

      let staticDhcpLease;
      // EDIT
      if (!this.isViewing && !this.isAdding) {
        staticDhcpLease = {
          id: this.staticDhcpLeaseForm.value.id,
          set_tag: this.staticDhcpLeaseForm.value.name,
          hwaddr: this.staticDhcp?.hwaddr,
          ipv4: this.staticDhcpLeaseForm.value.ipv4Address,
          hostname: this.staticDhcpLeaseForm.value.hostname,
          lease_time: 'infinite',
          comment: this.staticDhcpLeaseForm.value.comment
        }
      } else {  // ADD
        staticDhcpLease = {
          id: this.staticDhcpLeaseForm.value.id,
          set_tag: this.staticDhcpLeaseForm.value.name,
          hwaddr: this.staticDhcpLeaseForm.value.macAddress,
          ipv4: this.staticDhcpLeaseForm.value.ipv4Address,
          hostname: this.staticDhcpLeaseForm.value.hostname,
          lease_time: 'infinite',
          comment: this.staticDhcpLeaseForm.value.comment
        }
      }

      console.log("save() - staticDhcpLease = ", staticDhcpLease);
      // This calls the onClose defined in the parent ie. (onClose)="saveSipExtension($event)"
      this.onSave.emit(staticDhcpLease);
      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.clearFields();
      this.blockUI?.stop();
    }
  }

  enableControlsForView() {
    console.log(`In enableControlsForView()`);
    this.staticDhcpLeaseForm.get('name')?.enable();
    this.staticDhcpLeaseForm.get('macAddress')?.enable();
    this.staticDhcpLeaseForm.get('ipv4Address')?.enable();
    this.staticDhcpLeaseForm.get('hostname')?.enable();
    this.staticDhcpLeaseForm.get('comment')?.enable();
  }

  disableControlsForView() {
    console.log(`In disableControlsForView()`);
    this.staticDhcpLeaseForm.get('name')?.disable();
    this.staticDhcpLeaseForm.get('macAddress')?.disable();
    this.staticDhcpLeaseForm.get('ipv4Address')?.disable();
    this.staticDhcpLeaseForm.get('hostname')?.disable();
    this.staticDhcpLeaseForm.get('comment')?.disable();
  }
}