import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Table } from 'primeng/table';
import { UserModel, Roles } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { LazyLoadEvent } from 'primeng/api';
import { Utils } from 'src/app/util/utils';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  isLoading: boolean = false;
  isReviewer: boolean = false;
  currentUser?: UserModel;
  currentUsername?: string;
  currentOrgId?: string;
  currentRoleId: number = 4;  // Default to Reviewer
  users: UserModel[] = [];
  usersSelected: UserModel[] = [];
  deleteUserModalOpen = false;
  totalRecords = 0;
  take = 50; // needs to be in synch with table
  skip = 0;
  selectAll = false;
  globalFilterFields=['name', 'username', 'email', 'phone', 'address', 'role', 'organization.name'];
  searchInput = '';
  clearing = false;
  lastLazyEvent: any = '';
  roleOptions = Roles;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private breadcrumbService: AppBreadcrumbService,
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.currentUsername = this.currentUser?.username;
      this.currentOrgId = this.currentUser?.organization.id;

      // Convert User role to Account Manager
      const currentRole = this.currentUser?.role === 'User' ? 'AccountManager' : this.currentUser?.role;

      // Save Role id
      this.currentRoleId = Utils.altFind(Roles, function(x : any) {
        return x.value === currentRole;
      })?.id;
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Users' }]);
    this.lastLazyEvent = '';
    this.clearing = false;
  }

  onSelectionChange(value = []) {
    this.selectAll = value.length === this.totalRecords;
    this.usersSelected = [...value];
    console.log(`devicesSelected=${this.usersSelected}`);
  }

  async loadUsers(loadEvent: LazyLoadEvent) {
    try {

      this.isLoading =true;
      this.skip = loadEvent.first || 0;
      this.take = loadEvent.rows || this.take;

      // Force clearing global filter when user press the 'Clear' button
      // Why many searches (1 per page) are issue. (we don't have control over it,  since it's a callback event received)
      // Solution below:
      // When performing a clear filter, do not reissue search, since the table.filterGlobal('', 'contains');
      // will reissue the search will proper search after filters are cleared and proper 1 time search 

      if (this.clearing) {
        loadEvent.globalFilter = '';
        loadEvent.filters = {};
        return;
      }

      // Skip any possible duplicate event
      console.log(`LAST=${this.lastLazyEvent}`);
      if (this.lastLazyEvent === JSON.stringify(loadEvent)) {
        console.log(`skip`);
        return;
      }     

      this.lastLazyEvent = JSON.stringify(loadEvent);
      console.log(`THIS IS THE EVENT: ${JSON.stringify(loadEvent)}`);
      await this.load(JSON.stringify(loadEvent));

    } finally {
      this.isLoading = false;
    }
  }

  async load(loadEvent: any) {
    let globalFilterFieldsQueryParm = '';

    if (this.globalFilterFields) {
      globalFilterFieldsQueryParm = `&globalFilterFields=${JSON.stringify(this.globalFilterFields)}`
    }

    console.log(`globalFilterFieldsQueryParm: ${JSON.stringify(globalFilterFieldsQueryParm)}`);
    this.api.get(`/users?paginationParm=${loadEvent}${globalFilterFieldsQueryParm}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.users = rsp.users;
    });

  }

  // Reloads page with previous 'configuration' 
  async reload() {
      this.api.get(`/users?take=${this.take}&skip=${this.skip}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.users = rsp.users;
    });
  }

  async search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onClose() {
    this.usersSelected = [];
    await this.reload();
  }

  clear(table: Table) {
    console.log(`clear`);
    this.searchInput='';
    this.clearing = true;
    table.clear();
    table.filterGlobal('', 'contains'); 
    this.clearing = false; // reset flag after clearing is done.
  }

  displayableRole(role: string) {
    const displayRole = Utils.altFind(Roles, function(x : any) {
      return x.value === role;
    })?.label || role;
    return displayRole;
  }

  isParentRole(orgId: string, role: string) {
    let parentRole = false;
    const roleId = Utils.altFind(Roles, function(x : any) {
      return x.value === role;
    })?.id;
    if (orgId === this.currentOrgId && roleId < this.currentRoleId) {
      parentRole = true;
    }
    return parentRole;
  }

}
