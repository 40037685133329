<div class="container">
  <h1 *ngIf="isReviewer">Device</h1>
  <h1 *ngIf="isEditing">Edit Device</h1>
  <h1 *ngIf="!isEditing && !isReviewer">Add Device</h1>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="nameField">Name</label>
        <input id="nameField" type="text" formControlName="name" pInputText required />
        <div *ngIf="isEditing && form.get('name')?.errors?.nameValidator">
          Name is invalid.
        </div>
        <div *ngIf="isEditing && form.get('name')?.errors?.maxlength">
          Name must be at most 50 characters long.
        </div>
        <small *ngIf="isEditing" id="shadowName">Reported Value: {{shadowReportedName}}</small>
      </div>
      <div class="p-field p-sm-6">
        <label for="assetTagField">Asset Tag</label>
        <input id="assetTagField" type="text" formControlName="assetTag" pInputText />
        <div *ngIf="isEditing && form.get('assetTag')?.errors?.nameValidator">
          Asset Tag is invalid.
        </div>
        <div *ngIf="isEditing && form.get('assetTag')?.errors?.maxlength">
          Asset Tag must be at most 50 characters long.
        </div>
        <small *ngIf="isEditing" id="shadowAssetTag">Reported Value: {{shadowReportedAssetTag}}</small>
      </div>
      <div class="p-field p-sm-6">
        <label for="serialField">Serial Number</label>
        <input id="serialField" type="text" formControlName="serial" pInputText />
      </div>
      <div class="p-field p-sm-6" formGroupName="deviceType">
        <label for="deviceTypeField">Device Type</label>
        <p-dropdown id="deviceTypeField"
          [options]="deviceTypes"
          formControlName="id"
          placeholder="Select a Device Type"
          optionLabel="name"
          optionValue="id"
          (onChange)="handleDeviceTypeChange($event)"
          appendTo="body">
        </p-dropdown>
      </div>
      <div *ngIf="isVam || isReseller" class="p-field p-sm-6" formGroupName="org">
        <label for="orgField">Organization</label>
        <p-dropdown id="orgField"
          [options]="orgs"
          formControlName="id"
          placeholder="Select an Org"
          optionLabel="name"
          optionValue="id"
          appendTo="body">
        </p-dropdown>
      </div>

    </div>
    </div>
    <div class="card">
      <section>
        <h4>Data Usage</h4>
        <div class="p-fluid p-formgrid p-grid">
          <app-grafana class="p-field p-sm-12"
            [parentGroup]="form"
            [url]="urlCellSafe"
            label="Select Interval"
            formDropDownFieldId="cellDataUsageRangeField"
            formControlDropdownName="cellDataUsageRange"
            [formOptions]="dataUsageRangeOptions"
            [dateFromName]="cellDataUsageFrom"
            (fromValue) = "setCellDataUsageFrom($event)"
            [dateToName]="cellDataUsageTo"
            (toValue) = "setCellDataUsageTo($event)"
            applyClickLabel="Apply"
            [applyClickFunction]="cellDataUsageRangeClick">
          </app-grafana>
          <app-grafana class="p-field p-sm-12"
            [parentGroup]="form"
            [url]="urlSatelliteSafe"
            label="Select Interval"
            formDropDownFieldId="satelliteDataUsageRangeField"
            formControlDropdownName="satelliteDataUsageRange"
            [formOptions]="dataUsageRangeOptions"
            [dateFromName]="satelliteDataUsageFrom"
            (fromValue) = "setSatelliteDataUsageFrom($event)"
            [dateToName]="satelliteDataUsageTo"
            (toValue) = "setSatelliteDataUsageTo($event)"
            applyClickLabel="Apply"
            [applyClickFunction]="satelliteDataUsageRangeClick">
          </app-grafana>
        </div>
      </section>
    </div>
    <div *ngIf="!isReviewer" class="card">
      <section>
        <h4>Device Control</h4>
        <p class="warning-block">These commands will have an impact on your device and may change its configuration.</p>
        <div class="p-field p-sm-12">
          <button type="button" pButton pRipple [disabled]="disableUpdateFirmwareButton"  class="p-button p-mr-2"  label="Update Firmware" (click)="runJob(JOB_TYPE_ENUM.FIRMWARE_UPDATE)"></button>
          <button type="button" pButton pRipple [disabled]="disableResetToDefaultsButton" label="Reset to Defaults"  class="p-button p-mr-2"  (click)="runJob(JOB_TYPE_ENUM.HARD_FACTORY_RESET)"></button>
          <button type="button" pButton pRipple [disabled]="disableForceConfigButton" label="Force Config Push"  class="p-button p-mr-2"  (click)="runJob(JOB_TYPE_ENUM.FACTORY_RESET)"></button>
          <button type="button" pButton pRipple [disabled]="disableRebootButton"  label="Reboot"  class="p-button p-mr-2"  (click)="runJob(JOB_TYPE_ENUM.REBOOT)"></button>
        </div>
        <div>
          <h4>Jobs History</h4>
          <div>
            <p-table
              #jobs
              [value]="jobsRsp"
              styleClass="p-datatable-striped"
              [rows]="10"
              [(selection)]="jobsSelected"
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10, 20, 100]"
              [paginator]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              sortMode="single"
              [sortOrder]="-1"
              sortField="jobNumber">

              <ng-template pTemplate="caption">
                <div class="p-d-flex">
                  <button *ngIf="!isReviewer"
                    pButton
                    pRipple
                    label="Cancel Job"
                    type="button"
                    icon="fas fa-times"
                    class="p-button-danger p-mr-2"
                    [disabled]="!jobsSelected.length"
                    (click)="cancelJobsModalOpen=true">
                  </button>
                </div>
                </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th *ngIf="!isReviewer" style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>
                    Date
                    <p-column type="text" field="createdAt" display="menu"></p-column>
                  </th>
                  <th>
                    Job Number
                    <p-columnFilter type="text" field="jobNumber" display="menu"></p-columnFilter>
                  </th>
                  <th>
                    Job Id
                    <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
                  </th>
                  <th>
                    Job Name
                    <p-columnFilter type="text" field="jobDefinitions.name" 
                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" showButtonBar="false" 
                    display="menu" class="p-ml-auto">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown
                          [options]="JobTypes"
                          optionLabel="label"
                          optionValue="value"
                          (onChange)="filter($event.value)"
                          placeholder="Choose your filter">
                            <ng-template let-option pTemplate="item">
                                <span>{{ option.label }}</span>
                            </ng-template>
                        </p-dropdown> 
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th>
                    Status
                    <p-columnFilter type="text" field="status" 
                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" showButtonBar="false" 
                    display="menu" class="p-ml-auto">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown
                          [options]="JobStatus"
                          optionLabel="label"
                          optionValue="value"
                          (onChange)="filter($event.value)"
                          placeholder="Choose your filter">
                            <ng-template let-option pTemplate="item">
                                <span>{{ option.label }}</span>
                            </ng-template>
                        </p-dropdown> 
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th>
                    Retries
                    <p-columnFilter type="text" field="retries" display="menu"></p-columnFilter>
                  </th>
                  <th>
                    Job Message Id
                    <p-columnFilter type="text" field="messageId" display="menu"></p-columnFilter>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-job>
                <tr [pSelectableRow]="job">
                  <td>
                    <p-tableCheckbox [value]="job" [disabled]="job.status === 'User Cancelled'"></p-tableCheckbox>
                  </td>
                  <td>{{ job.createdAt | date: 'medium' }}</td>
                  <td>{{ job.jobNumber }}</td>
                  <td>{{ job.id }}</td>
                  <td>{{ job.jobDefinitions.name }}</td>
                  <td>{{ job.status }}</td>
                  <td>{{ job.retries }}</td>
                  <td>{{ job.messageId }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </section>
    </div>
    <div class="card">
    <section class="mb-4">
      <h4>Attributes</h4>
      <p class="help-block">Attributes are system defined properties particular to the device. They cannot be changed by users other than super admins.</p>
      <p class="warning-block">These keys need to be unique and may be used by different systems looking for particular keys. For example the billing system would lookup the iridiumImei key.</p>
      <div formArrayName="attributes" *ngFor="let attribute of getAttributesIterator(); let i = index">
        <div class="p-fluid p-formgrid p-grid" [formGroupName]="i">
          <div class="p-field p-sm-6">
            <label>Attribute Key</label>
            <div class="p-inputgroup">
              <button
                *ngIf="isVam && !isReviewer && !isAccountManager"
                type="button"
                pButton pRipple
                icon="fa fa-trash"
                class="p-button-danger"
                (click)="removeFormAttribute(i)">
              </button>
              <input type="text" formControlName="key" pInputText />
            </div>
          </div>
          <div class="p-field p-sm-6">
            <label>Attribute Value</label>
            <input type="text" formControlName="value" pInputText />
          </div>
        </div>
      </div>
      <p-button
        *ngIf="isVam && !isReviewer && !isAccountManager"
        label="Add Attribute"
        icon="fas fa-plus"
        (onClick)="addFormAttribute()">
      </p-button>
    </section>
    </div>

    <div class="card">
    <section class="mb-4">
      <h4>Tags</h4>
      <p class="help">Tags are user defined properties associated with the device. They can be changed by anyone who has access to the device.</p>
      <div formArrayName="tags" *ngFor="let tag of getTagsIterator(); let i = index">
        <div class="p-fluid p-formgrid p-grid" [formGroupName]="i">
          <div class="p-field p-sm-6">
            <label>Tag Name</label>
            <div class="p-inputgroup">
              <button
                *ngIf="!isReviewer"
                type="button"
                pButton
                pRipple
                icon="fa fa-trash"
                class="p-button-danger"
                (click)="removeFormTag(i)">
              </button>
              <input type="text" formControlName="name" pInputText />
            </div>
          </div>
          <div class="p-field p-sm-6">
            <label>Tag Value</label>
            <input type="text" formControlName="value" pInputText />
          </div>
        </div>
      </div>
      <p-button *ngIf="!isReviewer" label="Add Tag" icon="fas fa-plus" (onClick)="addFormTag()"></p-button>
    </section>
    </div>

    <div class="card">
    <section class="mb-4">
      <h4>Reporting</h4>
      <p-table [value]="reports" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
            <tr>
                <th>Last Check-in</th>
                <th>Firmware Version</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-report>
            <tr>
                <td>
                  <span *ngIf="report.lastCheckIn; else notCheckedIn">
                    {{ report.lastCheckIn | date: 'medium' }}
                  </span>
                  <ng-template #notCheckedIn>
                    <span class="customer-badge status-unknown">Not Checked-in</span>
                  </ng-template>
                </td>
                <td>
                  <span *ngIf="!report.firmware; then Unknown else Display"></span>
                  <ng-template #Unknown>
                    <span class="customer-badge status-unknown">Unknown</span>
                  </ng-template>
                  <ng-template #Display>
                    {{report.firmware}}
                  </ng-template>
                  </td>
            </tr>
        </ng-template>
    </p-table>
    </section>
    </div>

    <div class="card">
      <h4>Profile</h4>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-sm-6" formGroupName="deviceConfig">
          <label for="deviceConfigsField">Assigned Device Profile</label>
          <p-dropdown
            id="deviceConfigsField"
            [options]="deviceConfigs"
            formControlName="id"
            placeholder="Select a Device Profile"
            optionLabel="name"
            optionValue="id"
            appendTo="body">
          </p-dropdown>
          <div class="p-grid p-sm-6 p-pt-5">
            <button
              pButton
              label="View Device Profile"
              type="button"
              (click)="gotoDeviceConfig()">
            </button>
          </div>
        </div>
        <div *ngIf="!isIotDevice" class="p-field p-sm-6" formGroupName="voiceConfig">
          <label for="voiceConfigsField">Assigned Voice Profile</label>
          <p-dropdown
            id="voiceConfigsField"
            [options]="voiceConfigs"
            formControlName="id"
            placeholder="Select a Voice Profile"
            optionLabel="name"
            optionValue="id"
            appendTo="body">
          </p-dropdown>
          <div class="p-grid p-sm-6 p-pt-5">
            <button
              pButton
              label="View Voice Profile"
              type="button"
              (click)="gotoVoiceConfig()">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="!isIotDevice">
      <div *ngIf="callLog.length; then table else noTable">  </div>
      <ng-template #noTable>
        <h4>Call Log</h4>
        <p>No Call Records Found</p>
      </ng-template>
      <ng-template #table>
      <h4>Call Log</h4>
      <p-table
        #calls
        [value]="callLog"
        styleClass="p-datatable-striped"
        dataKey="callId"
        [rows]="5"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5, 10, 20, 100]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        sortMode="single"
        [sortOrder]="-1"
        sortField="callStartTime">

        <ng-template pTemplate="header">
          <tr>
            <th>
              Date
              <p-columnFilter type="text" field="callStartTime" display="menu"></p-columnFilter>
            </th>
            <th>
              Source
              <p-columnFilter type="text" field="source" display="menu"></p-columnFilter>
            </th>
            <th>
              Destination
              <p-columnFilter type="text" field="destination" display="menu"></p-columnFilter>
            </th>
            <th>
              Line
              <p-columnFilter type="text" field="line" display="menu"></p-columnFilter>
            </th>
            <th>
              Total Duration
              <p-columnFilter type="text" field="totalDuration" display="menu"></p-columnFilter>
            </th>
            <th>
              Billable Duration
              <p-columnFilter type="text" field="billableDuration" display="menu"></p-columnFilter>
            </th>
            <th>
              Disposition
              <p-columnFilter type="text" field="disposition" display="menu"></p-columnFilter>
            </th>
            <th>
              Call ID
              <p-columnFilter type="text" field="callId" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-call>
          <tr>
            <td>{{ call.callStartTime }}</td>
            <td>{{ call.source }}</td>
            <td>{{ call.destination }}</td>
            <td>{{ call.line }}</td>
            <td>{{ call.totalDuration }}</td>
            <td>{{ call.billableDuration }}</td>
            <td>{{ call.disposition }}</td>
            <td>{{ call.callId }}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
    </div>
    <p>
      <button
        *ngIf="!isReviewer"
        pButton
        label="Save"
        class="p-button-warning p-mr-2"
        icon="fas fa-save"
        type="submit"
        [disabled]="!form.valid || !form.dirty">
      </button>
      <button
        pButton
        label="Cancel"
        class="p-button-warning"
        icon="fas fa-undo"
        type="button"
        (click)="onCancel($event)">
      </button>
    </p>
  </form>
</div>
<app-cancel-jobs [(visible)]="cancelJobsModalOpen" [jobs]="jobsSelected"  (onClose)="onClose()"></app-cancel-jobs>
