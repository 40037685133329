<div class="container">
  <h1 *ngIf="isReviewer">Reseller Org</h1>
  <h1 *ngIf="isEditing">Edit Reseller Org</h1>
  <h1 *ngIf="!isEditing && !isReviewer">Add Reseller Org</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-sm-6">
          <label for="nameField">Name</label>
          <input id="nameField" type="text" formControlName="name" pInputText required />
        </div>
        <div *ngIf="isVam" class="p-field p-sm-6">
          <label for="accountOwnerField">Account Owner</label>
          <input id="accountOwnerField" type="text" formControlName="accountOwner" pInputText />
        </div>
        <div class="p-field p-sm-6" formGroupName="parentOrg">
          <label for="parentOrgField" class="required">Parent Org</label>
          <p-dropdown
            id="parentOrgField"
            [options]="parentOrgs"
            formControlName="id"
            placeholder="Select Org"
            optionLabel="name"
            optionValue="id">
          </p-dropdown>
        </div>
        <div class="p-field p-col-12">
          <label for="adminContactField">Administrative Contact</label>
          <textarea
            id="adminContactField"
            formControlName="adminContact"
            pInputTextarea
            required
            [autoResize]="true"
            placeholder="Administrative Contact
  1234 Main Street.
  Springfield, CA 1234
  +1 (123) 555-1055
  jdean@what.com"
          ></textarea>
        </div>
        <div class="p-field p-col-12">
          <label for="supportContactField">Support Contact</label>
          <textarea
            id="supportContactField"
            formControlName="supportContact"
            [autoResize]="true"
            pInputTextarea
            required
            placeholder="Company Support
  1234 Main Street.
  Springfield, CA 1234
  +1 (123) 555-1055
  support@what.com"
          >
          </textarea>
          <small class="d-block">Support contact information will be displayed to customers they manage.</small>
        </div>
      </div>
    </div>
    <app-notes-list *ngIf="isEditing && isVam" [orgId]="reseller_id" [notes]="notes" (onClose)="onCloseDialog()"></app-notes-list>
    <p>
      <button pButton *ngIf="!isReviewer && !isOps" label="Save" class="p-button-warning p-mr-2" icon="fas fa-save" type="submit" [disabled]="!form.valid || !form.dirty"></button>
      <button pButton label="Cancel" class="p-button-warning p-mr-2" icon="fas fa-undo" type="button" (click)="onCancel($event)"></button>
      <button *ngIf="isEditing && !isReviewer && !isOps" pButton pRipple label="Delete" class="p-button-danger p-mr-2" type="button" icon="fas fa-trash" (click)="deleteResellerModalOpen = true"></button>
    </p>
  </form>
</div>
