import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast, createFailureToDeleteMsg  } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';
import { OrgModel, OrgNotesModel } from 'src/app/models/org.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delete-note',
  templateUrl: './delete-note.component.html',
  styleUrls: ['./delete-note.component.scss']
})
export class DeleteNoteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() notes: OrgNotesModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;

  notesSelected: OrgNotesModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  async delete() {
    try {
      this.blockUI?.start();
      let payload: any[] = [];

      this.notes.forEach(note => {
        payload.push(note.id);
      });

      console.log(`DELETE Notes is ${payload}`);

      const deleteNotes = {
        body: {
          noteIds: payload
        }
      }

      await this.api.request('DELETE', `/org-notes`, deleteNotes );

    } catch (err) {
      console.log(`${JSON.stringify(err)}`);
      const errors = createFailureToDeleteMsg(err, this.notes, 'notes');
      this.messageService.addAll(errors);
    } finally {
      this.notesSelected = [];
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.notesSelected = [];
  }
}
