import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ConfigModel } from 'src/app/models/config.model';
import { DeviceModel } from 'src/app/models/device.model';
import { OrgModel } from 'src/app/models/org.model';
import { VoiceConfigModel } from 'src/app/models/voice-config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';

@Component({
  selector: 'app-device-assign-config',
  templateUrl: './assign-config.component.html',
  styleUrls: ['./assign-config.component.scss']
})
export class DeviceAssignConfigComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() devices: DeviceModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  deviceConfigs: ConfigModel[] = [];
  deviceConfigSelected?: ConfigModel;

  voiceConfigs: VoiceConfigModel[] = [];
  voiceConfigSelected?: VoiceConfigModel;

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const rspConfig = await this.api.get< { "configs": ConfigModel[] } >('/configs/latest');
      this.deviceConfigs = rspConfig.configs;

      const rspVConfig = await this.api.get< { "voiceConfigs": VoiceConfigModel[] } >('/voice-configs/latest');
      this.voiceConfigs = rspVConfig.voiceConfigs;
    } finally {
      this.isLoading = false;
    }
  }

  async assign() {
    if (this.deviceConfigSelected || this.voiceConfigSelected) {
      try {
        this.blockUI?.start();

        await Promise.all(this.devices.map(device =>
          // device.origDeviceConfigId = device.deviceConfigId;

          this.api.put(`/devices/${device.id}`, 
            { 
              assetTag: device.assetTag,
              orgId: device.orgId,
              deviceConfigId:  this.deviceConfigSelected?.id,
              voiceConfigId:  this.voiceConfigSelected?.id,
            })
          )
        );

        for (let device of this.devices) {

          // Retrieve Device Config from server and update Shadows
          if ( this.deviceConfigSelected && this.deviceConfigSelected.id) {
            const configChosen = await this.api.get<ConfigModel>(`/configs/${this.deviceConfigSelected.id}`);
            console.log("configChosen = ", configChosen);
            if (configChosen) {
              const configInfoChosen = configChosen.config;
              if (configInfoChosen) {
                // Update the AWS Things Shadow for Configs
                await this.api.put(`/things/${device?.id}/shadows`, configInfoChosen.shadows);
              }
            }
          }

          if (this.voiceConfigSelected && this.voiceConfigSelected.id) {
            const vConfigChosen = await this.api.get<VoiceConfigModel>(`/voice-configs/${this.voiceConfigSelected.id}`);
            console.log("vConfigChosen = ", vConfigChosen);
            if (vConfigChosen) {
              const vConfigInfoChosen = vConfigChosen.config;
              if (vConfigInfoChosen) {
                // Update the AWS Things Shadow for Voice Configs
                await this.api.put(`/things/${device?.id}/shadows`, vConfigInfoChosen.shadows);
              }
            }
          }
        }

        this.deviceConfigSelected = undefined;
        this.voiceConfigSelected = undefined;

        this.onClose.emit();

        this.visible = false;
        this.visibleChange.emit(false);
      } catch (err) {
        this.messageService.addAll(parseApiErrorsToToast(err));
      } finally {
        this.blockUI?.stop();
      }
    }
  }

  async unAssign() {
    try {
      this.blockUI?.start();

      await Promise.all(this.devices.map(device =>
        this.api.put(`/devices/${device.id}`,
          {
            orgId: device.orgId,
            deviceConfigId:  null,
            voiceConfigId:  null,
          })
        ));

      this.deviceConfigSelected = undefined;
      this.voiceConfigSelected = undefined;

      this.onClose.emit();

      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.deviceConfigSelected = undefined;
    this.voiceConfigSelected = undefined;
  }
}
