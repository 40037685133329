import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function ipv4Validator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      const ipv4RegEx: RegExp = /^(?:(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\.(?!$)|$)){4}$/;
      const ipv4Match = ipv4RegEx.test(control.value);
      res = !ipv4Match ? { 
        ipv4Validator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}

// WORKING SAMPLE
export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  console.log("In forbiddentName");
  return (control: AbstractControl): ValidationErrors | null => {
    console.log("Inside control.value = ", control.value);

    const forbidden = nameRe.test(control.value);
    console.log("Inside forbidden = ", forbidden);

    let res = forbidden ? {forbiddenName: {value: control.value}} : null;

    console.log("In forbiddenNameValidator - res = ", res);

    return res;
  };
}
