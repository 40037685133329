<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="container">
    <span class="navbar-brand">SkyLink</span>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }">
      <ul class="navbar-nav me-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="dashboard">Dashboard</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="my-org">My Org</a>
        </li>
        <li class="nav-item" *ngIf="isVam" routerLinkActive="active">
          <a class="nav-link" routerLink="resellers">Resellers</a>
        </li>
        <li class="nav-item" *ngIf="!isCustomer" routerLinkActive="active">
          <a class="nav-link" routerLink="customers">Customers</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="devices">Devices</a>
        </li>
        <li class="nav-item" *ngIf="isVam" routerLinkActive="active">
          <a class="nav-link" routerLink="device-type">Device Types</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="users">Users</a>
        </li>
        <li class="nav-item" *ngIf="isVam" routerLinkActive="active">
          <a class="nav-link" routerLink="api-keys">Api Keys</a>
        </li>
        <li class="nav-item" *ngIf="isVam" routerLinkActive="active">
          <a class="nav-link" routerLink="configs">Configs</a>
        </li>
      </ul>
      <span class="navbar-text p-mr-2">
        <app-user-login-name></app-user-login-name>
      </span>
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="settings"><i class="fas fa-cog"></i></a>
        </li>
        <li class="nav-item">
          <a type="button" class="nav-link" (click)="logOut()" title="Log Out"><i class="fas fa-sign-out-alt"></i></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
