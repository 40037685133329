import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { FirewallRule, FirewallRulesModel } from 'src/app/models/config.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-delete-ip-address',
  templateUrl: './delete-ip-address.component.html',
  styleUrls: ['./delete-ip-address.component.scss']
})
export class DeleteIpAddressComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() ipaddressToBeRemoved: string | undefined;
  @Input() index: number | undefined;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      console.log(`in DELETE to be removed = ${this.ipaddressToBeRemoved}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
    } finally {
      
      this.onClose.emit(this.index);
      this.ipaddressToBeRemoved = '';
      this.index = 0;
      this.visible = false;
      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }
  async onCloseDialog() {
    this.ipaddressToBeRemoved = '';
    this.index = 0;
    console.log(`onCloseDialog  DONE`);
  }
}
