import { Component, Input, OnInit } from '@angular/core';
import { DeviceModel } from 'src/app/models/device.model';
import { PositionModel } from 'src/app/models/position.model';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/util/utils';

// Declare a google variable that SHOULD be available at run-time.
declare var google: any;

@Component({
  selector: 'app-device-map',
  templateUrl: './device-map.component.html',
  styleUrls: ['./device-map.component.scss']
})
export class DeviceMapComponent implements OnInit {

  @Input() title: string = '';
  @Input() footer: string = '';
  @Input() devices: DeviceModel[] = [];
  @Input() mapHeight: string = '';
  @Input() mapWidth: string = ''

  options: any;
  overlays: any[] = [];

  map: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {

    this.options = {
      //34.84835588440944, -4.8476925793700465
      center: { lat: 23.452112666666665, lng:  	-4.78413766666667 },
      zoom: 2
    }

    // this.overlays = [
    //   new google.maps.Marker({ position: { lat: 25.123242, lng: -80.141482 }, title: 'DIDIER WINS' })
    // ];

    this.mapHeight = this.mapHeight || '420px';
    this.mapWidth = this.mapWidth || '100%'
  }

  ngOnChanges(): void {
    console.log(`ngOnChanges() this.devices: ${JSON.stringify(this.devices)}`);
    this.load(this.devices);
  }

  toMarker(obj: any) {
    const a = {
      title: obj.deviceId,
      position: {
        lat: obj.lat,
        lng: obj.lon
      },
      // TODO: use health indicating circles instead of an image
      icon: '../../../../../../assets/images/wifi-router.svg',
      scaledSize: new google.maps.Size(70, 70)
    };

    return new google.maps.Marker(a);
  }

  getContent(title: string, name: string, org: string, lat: string, lon: string, pTime: string, rTime: string) {
    return `
      <style>
      #iw-container {
        margin-bottom: 10px;
      }
      #iw-container .iw-title {
        font-family: 'Open Sans Condensed', sans-serif;
        font-size: 22px;
        font-weight: 400;
        padding: 10px;
        background-color: #48b5e9;
        color: white;
        margin: 0;
        border-radius: 2px 2px 0 0;
      }
      .iw-subTitle {
        font-size: 16px;
        font-weight: 700;
        padding: 5px 0;
      }
      table {
        border: 1px solid black;
      }
      th {
        border: 1px solid black;
        text-align: left;
        padding: 2px;
        font-weight: bold;
      }
      td {
        border: 1px solid black;
        text-align: left;
        padding: 2px;
        font-weight: normal;
      }
      h6 {
        font-weight: bold;
        color: blue;
      }
      tr:nth-child(even) {background-color: Lightblue;}
    </style>
    <div id="iw-container">
      <div class="iw-title">${title}</div>
        <div id="body" class="body">
            <h6>Device and Position Information</h6>
            <table id="deviceTable">
              <tr>
                <th>Serial Number:</th>
                <td>${title}</td>
              </tr>
              <tr>
                <th>Device Name:</th>
                <td>${name}</td>
              </tr>
              <tr>
                <th>Org Name:</th>
                <td>${org}</td>
              </tr>
              <tr>
                <th>Latitude:</th>
                <td>${lat}</td>
              </tr>
              <tr>
                <th>Longitude:</th>
                <td>${lon}</td>
              </tr>
              <tr>
                <th>Position Time:</th>
                <td>${pTime}</td>
              </tr>
              <tr>
                <th>Reported Time:</th>
                <td>${rTime}</td>
              </tr>
            </table>
        <div>
    </div>
  `
  }

  setMap(event: any) {
    this.map = event.map;
  }

  async load(devices: DeviceModel[]) {

    try {

      if (devices.length == 0) {
        const devicesRsp = await this.api.get<{ "devices": DeviceModel[] }>(`/devices`);
        this.devices = devicesRsp.devices;
      }

      const positions: any[] = await this.api.get<PositionModel[]>(`/position/last-position`);

      // Handle position for the device.
      console.log(`positions=${JSON.stringify(positions)}`);
      this.handlePositions(positions);

      } catch (err) {
      console.error(`Error loading location for device:`, err);
    }

  }

  async handlePositions(positions: PositionModel[]) {

    var bounds = new google.maps.LatLngBounds();
    positions.map(async (position) => {

      try {
        if (position.lat && position.lon) {
          const marker = this.toMarker(position);
          bounds.extend(marker.position);

          const device = Utils.altFind(this.devices, (x: any) => {
            return x.id === position.deviceId
          });

          // Get Data from json and format it accordingly
          // Device Info
          let title = device.serial || device.name || device.id;
          let name = device.name || 'not provided';
          let org = device.org?.name || 'not assigned';

          // Position Info
          let lat: any  = position.lat || 'not provided';
          let lon: any = position.lon || 'not provided';
          let rTime: any = position.reportedTime || 'not provided';
          let pTime: any = position.positionTime || 'not provided';

          // Convert rTime to Date format
          if (rTime && rTime !== 'not provided') {
            rTime = new Date(rTime);
          }
          if (pTime && pTime !== 'not provided') {
            pTime = new Date(pTime);
          }

          const content = this.getContent(title, name, org, lat, lon, pTime, rTime);

          const infoWindow = new google.maps.InfoWindow({
            content: content,
            minWidth: 320
          });

          marker.addListener("click", () => {
            infoWindow.open({
              anchor: marker,
              shouldFocus: false,
            });
          });
          console.log(`MAP: About to push ${JSON.stringify(position)}`);
          this.overlays.push(marker);
        }
      } catch (err) {
        console.log(`EXCEPTION::  ${err} `);
      }
    });
  }
}
