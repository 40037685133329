<div class="container">
  <router-outlet></router-outlet>
  <h1>Customers</h1>
  <div class="card">
  <p-table
    #customersList
    [value]="customers"
    [lazy]="true"
    (onLazyLoad)="loadCustomers($event)"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="globalFilterFields"
    [selection]="customersSelected" 
    (selectionChange)="onSelectionChange($event)" 
    sortMode="single"
    sortField="name"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button *ngIf="!isReviewer && !isOps" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" routerLink="/customers/add"></button>
        <button *ngIf="!isReviewer && !isOps" pButton pRipple label="Delete" icon="fas fa-trash" class="p-button-danger p-mr-2" [disabled]="!customersSelected.length" (click)="deleteCustomerModalOpen=true"></button>
        <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(customersList)"></button>

        <!-- <button pButton pRipple label="Assign to Reseller" class="p-button-warning p-mr-2" icon="far fa-plus-square" (click)="assignModalOpen = true" [disabled]="!customersSelected.length"></button> -->
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" id="globalSearch" [(ngModel)]="searchInput" (input)="search(customersList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="!isReviewer" style="width: 3rem">
        </th>
        <th>Name</th>
        <th *ngIf="isVam">Account Owner</th>
        <th *ngIf="!isCustomer">
          Reseller Org
          <p-columnFilter type="text" field="parentOrg.name" display="menu"></p-columnFilter>
        </th>
        <th>Admin Contact</th>
        <th>Support Contact</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
      <tr>
        <td *ngIf="!isReviewer">
          <p-tableCheckbox [value]="customer"></p-tableCheckbox>
        </td>
        <td><a [routerLink]="['/customers/edit', customer.id]" class="d-block">{{ customer.name }}</a></td>
        <td *ngIf="isVam">{{ customer.accountOwner || 'None'}}</td>
        <td>{{ customer.parentOrg.name }} ({{ customer.parentOrg.type }})</td>
        <td> {{ customer.adminContact }}</td>
        <td> {{ customer.supportContact }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>

<p-dialog header="Assign Customer to Reseller" [(visible)]="assignModalOpen" [style]="{ width: '50vw' }">
  <p>Assigning a customer to a different reseller will change the reseller experience for those customers and their users.</p>
  <p>Would you like to reassign the following customers to the reseller below?</p>
  <ul class="two-column">
    <li *ngFor="let customer of customersSelected">{{ customer.name }}</li>
  </ul>
  <h6>Reseller to assign customers to</h6>
  <p-dropdown [options]="resellers" [(ngModel)]="resellerSelected" placeholder="Select a Reseller" optionLabel="name" appendTo="body"></p-dropdown>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="resellerSelected && assign(resellerSelected, customersSelected)" [disabled]="!resellerSelected" label="Ok" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
<app-delete-customer [(visible)]="deleteCustomerModalOpen" [customers]="customersSelected"  (onClose)="onClose()"></app-delete-customer>

