import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast  } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Logger } from 'aws-amplify';

@Component({
  selector: 'app-user-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class UsersDeleteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() users: UserModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  currentUser?: UserModel;
  isVam: boolean = false;

  usersSelected?: UserModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      console.log(`currentUser:: ${this.currentUser?.username} `);
    }
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      let payload: any[] = [];

      this.users.forEach(user => {
        payload.push(user.id);
      });
      console.log(`delete payload is ${payload}`);

      await this.api.request('DELETE', `/users`, { body: { userIds: payload } });

      this.usersSelected = undefined;
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);

    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.usersSelected = undefined;
    console.log(`onCloseDialog  DONE`);
  }
}
