<p-dialog header="Create Key" [visible]="visible" (visibleChange)="close($event)" [style]="{ width: '80vw' }" (onHide)="(onClose)">
  <section *ngIf="createdKey">
    <h6>Successfully created the key.</h6>
    <p class="warning-block">This is the only place you can view this key. Make sure you save it!</p>
    <pre>{{ createdKey?.key }}</pre>
  </section>
  <form *ngIf="!createdKey" [formGroup]="form" (ngSubmit)="submit()">
    <p>Creates an API Key for the supplied user. This API Key can be used to make requests from a client application.</p>
    <p class="warning-block">
      This will allow access to this user outside of the application flow. This can be a security risk. Please be very careful about creating these.
    </p>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label>User</label>
        <p-dropdown
          [options]="users"
          formControlName="user"
          placeholder="Select a User"
          optionValue="id"
          optionLabel="name"
          appendTo="body"
        ></p-dropdown>
      </div>
      <div class="p-field p-sm-6">
        <label for="purposeField">Purpose</label>
        <input id="purposeField" type="text" formControlName="purpose" pInputText required />
      </div>
    </div>
    <p>
      <button pButton label="Create" class="p-button-warning" icon="fas fa-save" type="submit" [disabled]="!form.valid"></button>
    </p>
  </form>
</p-dialog>
