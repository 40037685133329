import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-countbox',
  templateUrl: './countbox.component.html',
  styleUrls: ['./countbox.component.scss']
})
export class CountboxComponent implements OnInit {

  @Input() title: string = '';
  @Input() content: string = '';
  @Input() footer: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
