<p-dialog header="Convert Customer to Reseller" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

    <p>Do you want to convert the following?</p>
    <ul class="two-column">
      <li *ngFor="let customer of customers">Customer Name: {{ customer.name }}</li>
    </ul>
  
    <ng-template pTemplate="footer">
      <p-button icon="fas fa-sitemap"
                (onClick)="convertToReseller()"
                label="Convert to Reseller"
                styleClass="p-button-danger">
      </p-button>
    </ng-template>
  
  </p-dialog>
  