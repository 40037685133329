import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DhcpReservationModel, MessageHubChannelModel } from "src/app/models/config.model";
import { MyApiKeyModel } from "src/app/models/my-api-key.model";

export interface StaticDhcpLeasesFunc {
  (): DhcpReservationModel[]
}
export function duplicateValidator(allStaticLeases: ()=>DhcpReservationModel[]): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;

    if (control?.value?.length > 0) {
      let isDuplicate = false;
      const staticLeases = allStaticLeases();
      if (staticLeases) {

        // Get field
        const formGroup: any = control.parent?.controls;
        const fieldName = Object.keys(formGroup).find(name => control === formGroup[name] || null);

        for (let i = 0; i < staticLeases.length; i++) {
          if (formGroup.id.value !== staticLeases[i].id) {
            if (fieldName === "macAddress") {
              if (staticLeases[i].hwaddr == control.value) {
                isDuplicate = true;
              }
            }
            if (fieldName === "ipv4Address") {
              if (staticLeases[i].ipv4 == control.value) {
                isDuplicate = true;
              }
            }
          }
        }
      }

      console.log("isDuplicate = ", isDuplicate);
      res = isDuplicate ? { 
        duplicateValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}

// NOTE:  This function is slightly different from above because
//        allMessageChannels are a FormArray. May be able to combine
export function duplicateValidatorForChannel(allMessageChannels: ()=> FormArray): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;

    if (control?.value?.length > 0) {
      let isDuplicate = false;
      const channels = allMessageChannels();
      if (channels) {
        // Get field
        const formGroup: any = control.parent?.controls;
        const fieldName = Object.keys(formGroup).find(name => control === formGroup[name] || null);

        for (let i = 0; i < channels.controls.length; i++) {

          if (formGroup.id?.value !== channels.controls[i].value.id) {
            if (fieldName === "channelId") {
              if (channels.controls[i].value.channelId == control.value) {
                isDuplicate = true;
              }
            }
          }
        }
      }

      console.log("isDuplicate = ", isDuplicate);
      res = isDuplicate ? {
        duplicateValidatorForChannel: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}

export function duplicateValidatorForApiKeys(allApiKeys: ()=>MyApiKeyModel[]): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;

    if (control?.value?.length > 0) {
      let isDuplicate = false;
      const apiKeys = allApiKeys();
      if (apiKeys) {

        // Get field
        const formGroup: any = control.parent?.controls;
        const fieldName = Object.keys(formGroup).find(name => control === formGroup[name] || null);
        for (let i = 0; i < apiKeys.length; i++) {
          if (fieldName === "title") {
            if (apiKeys[i].title == control.value) {
              isDuplicate = true;
            }
          }
        }
      }

      console.log("isDuplicate = ", isDuplicate);
      res = isDuplicate ? {
        duplicateValidatorForApiKeys: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
