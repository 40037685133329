import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function hostnameValidator(validHostnameRegex?: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      if (!validHostnameRegex) {
        validHostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
      }
      console.log("validHostnameRegex = ", validHostnameRegex);
      console.log("control.value = ", control.value);
      const hostnameMatch = validHostnameRegex.test(control.value);
      console.log("hostnameMatch = ", hostnameMatch);
      res = !hostnameMatch ? { 
        hostnameValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
