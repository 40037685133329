<div class="container">
  <h1>My API Keys</h1>
  <p-messages [(value)]="minFirmwareVersionMsg" [enableService]="false" [closable]="false"></p-messages>
  <p class="help-block">
    This is a list of all My API Keys currently present in the system.
  </p>
  <div class="card">
  <p-table
    #keysList
    [value]="apikeys"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['title']"
    sortMode="single"
    sortField="user.name"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"  (click)="createKeyModalOpen = true"></button>
        <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(keysList)"></button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="search(keysList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="!isReviewer" style="width: 3rem"></th>
        <th>Title</th>
        <!-- <th>User</th> -->
        <th>Created</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-apikeys>
      <tr>
        <td *ngIf="!isReviewer" style="width: 3rem">
          <button
            pButton
            pRipple
            icon="pi pi-times"
            class="p-button-sm p-button-rounded p-button-danger p-mr-2"
            (click)="deleteKey(apikeys)">
          </button>
        </td>
        <td>
          {{ apikeys.title || "None Provided" }}
        </td>
        <!-- <td>{{ apikeys.user.name }}</td> -->
        <td>{{ apikeys.date_added | date: 'longDate' }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
<app-my-api-keys-create
  [(visible)]="createKeyModalOpen"
  [allApiKeys]="apikeys"
  (onClose)="load()">
</app-my-api-keys-create>