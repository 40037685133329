<p-dialog
  header="Static DHCP Lease"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '40vw' }"
  (onShow)="onShow()"
  (onHide)="onCloseDialog()">

  <!-- TODO use multiple headers to create view etc.-->
  <!-- <h3 *ngIf="isViewing || isReviewer">Static DHCP Lease</h3>
  <h3 *ngIf="!isViewing && !isAdding">Edit Static DHCP Lease</h3>
  <h3 *ngIf="!isViewing && isAdding && !isReviewer">Add Static DHCP Lease</h3> -->

  <form [formGroup]="staticDhcpLeaseForm">
    <div class="p-fluid ">
      <div class="p-field p-sm-9">
        <label for="macAddressField">MAC Address</label>
        <input id="macAddressField" type="text" formControlName="macAddress" pInputText required/>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('macAddress')?.errors?.required">
          MAC Address is required.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('macAddress')?.errors?.macAddressValidator">
          Mac Address must be in the format:  aa:bb:cc:dd:ee:ff
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('macAddress')?.errors?.duplicateValidator">
          A static DHCP lease has already been created for this MAC address!
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('macAddress').value != macAddress }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(macAddress)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="ipAddressField">IPv4 Address</label>
        <input id="ipAddressField" type="text" formControlName="ipv4Address" pInputText required/>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('ipv4Address')?.errors?.required">
          IPv4 Address is required.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('ipv4Address')?.errors?.ipv4Validator">
          IPv4 Address is invalid.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('ipv4Address')?.errors?.duplicateValidator">
          This IP address has already been assigned to another static DHCP lease.
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('ipv4Address').value != ipv4Address }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(ipv4Address)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="hostnameField">Hostname</label>
        <input id="hostnameField" type="text" formControlName="hostname" pInputText required/>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('hostname')?.errors?.required">
          Hostname is required.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('hostname')?.errors?.hostnameValidator">
          Hostname may only contain numbers, letters, hypens(-) and periods. Hostnames cannot start with a hyphen or period.
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('hostname').value != hostname }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(hostname)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="nameField">Tag</label>
        <input id="nameField" type="text" formControlName="name" pInputText />
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('name')?.errors?.tagValidator">
          Tag may only contain numbers, letters, underscore(_) and dash(-).
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('name')?.errors?.minlength">
          Tag must be at least 5 characters long.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('name')?.errors?.maxlength">
          Tag must be at most 15 characters long.
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('name').value != name }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(name)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="commentField">Comment</label>
        <input id="commentField" type="text" formControlName="comment" pInputText optional/>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('comment')?.errors?.maxlength">
          Comment must be at most 50 characters long.
        </div>
        <div *ngIf="!isViewing && staticDhcpLeaseForm.get('comment')?.errors?.commentValidator">
          Comment may only contain numbers, and the following characters: % & + , . : = _ ( ) # * @ / - / (space)
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('comment').value != comment }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(comment)}}
        </small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button
      *ngIf="!isViewing && !isReviewer"
      pButton
      (click)="save()"
      label="Save"
      class="p-button-warning p-mr-2"
      icon="fas fa-save"
      [disabled]="!staticDhcpLeaseForm.valid || !staticDhcpLeaseForm.dirty">
    </button>
  </ng-template>
</p-dialog>
