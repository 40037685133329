import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function macAddressValidator(macAddrRegEx?: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      if (!macAddrRegEx) {
        macAddrRegEx = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
      }
      console.log("macAddrRegEx = ", macAddrRegEx);
      console.log("control.value = ", control.value);
      const macMatch = macAddrRegEx.test(control.value);
      console.log("macMatch = ", macMatch);
      res = !macMatch ? { 
        macAddressValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
