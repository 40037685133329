<div [formGroup]="parentGroup" class="p-field">
    <label for="thisField">{{label}}</label>
    <p-dropdown id="thisField" [options]="options" optionLabel="name" optionValue="value" [placeholder]="placeholder"
        [formControlName]="formControlName" appendTo="body">
    </p-dropdown>

    <small *ngIf="isViewing"
    [ngClass]="{'item-differ': parentGroup.get(formControlName)?.value != baseValue }"
    id="modeBaseField">
        {{BASE_VALUE}} {{ConfigUtils.displayNotSetIfEmpty(ConfigUtils.displayConversion(options, baseValue))}}
    </small>
</div>
