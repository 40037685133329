import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { DeviceModel } from 'src/app/models/device.model';
import { ApiService } from 'src/app/services/api.service';
import { OrgModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-update-shadow',
  templateUrl: './update-shadow.component.html',
  styleUrls: ['./update-shadow.component.scss']
})
export class UpdateShadowComponent {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() devices: DeviceModel[] = [];
  @Output("submitData") submitData: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  currentUser?: UserModel;
  isVam: boolean = false;
  orgs: OrgModel[] = [];
  orgSelected?: OrgModel;

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
    }
  }

  async confirm() {
    this.submitData.emit();
    this.visible = false;
  }

  async cancel() {
    this.visible = false;
    this.onClose.emit();
  }
}
