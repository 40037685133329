import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function commentValidator(commentRegEx?: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      if (!commentRegEx) {
        commentRegEx = /^[\w%&+,.:=_()#*@\/\-\/\ ]*$/;
      }
      console.log("commentRegEx = ", commentRegEx);
      console.log("control.value = ", control.value);
      const match = commentRegEx.test(control.value);
      console.log("match = ", match);
      res = !match ? { 
        commentValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
