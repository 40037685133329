import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { MessageService } from 'primeng/api';
import { DeviceTypeModel } from 'src/app/models/device-type.model';
import { UserModel } from 'src/app/models/user.model';
import { DeviceModel } from 'src/app/models/device.model';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class DeviceTypeEditComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI | undefined;

  isEditing: boolean = false;
  isReviewer: boolean = false;
  isAccountManager: boolean = false;
  currentUser?: UserModel;
  device_type_id?: string = '';

  devicesToUpdate : DeviceModel[] = [];
  updateShadowModalOpen = false;

  form = this.fb.group({
    name: ["", Validators.required],
    productCode: [""],
    productVersion: [""],
    manufacturer: [""],
    productVariant: [, Validators.required],
    productModel: [""],
    productName: [""],
    platformType: [""],
  });

  productVariantOptions = [
    { label: "Land", value: "land,voice" },
    { label: "Land IoT", value: "land,iot" },
    { label: "Maritime", value: "maritime,voice" },
    { label: "Maritime IoT", value: "maritime,iot" },
    { label: "Aviation", value: "aviation,voice" },
    { label: "Aviation IoT", value: "aviation,iot" },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isAccountManager = (this.currentUser?.role === 'AccountManager' ||
                               this.currentUser?.role === 'User');
    }
  }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.isEditing = false;
      if (params.device_type_id) {
        if (!this.isReviewer) {
          this.isEditing = true;
        }
        this.device_type_id = params.device_type_id;
        this.onLoad(params.device_type_id);
      }

      if (this.isReviewer) {
        this.breadcrumbService.setItems([{ label: 'Device Types', routerLink: '/device-types' }, { label: 'View' }])
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([{ label: 'Device Types', routerLink: '/device-types' }, { label: 'Edit' }])
      } else {
        this.breadcrumbService.setItems([{ label: 'Device Types', routerLink: '/device-types' }, { label: 'Add' }])
      }
    });
  }

  async onLoad(deviceTypeId: string) {
    console.log(`device-type - onload() - device_type_id = ${deviceTypeId}`)
    try {
      this.blockUI?.start();
      if (this.isReviewer) {
        this.setupControlsForReviewer();
      } else if (this.isAccountManager) {
        this.setupControlsForManager();
      }

      this.form.patchValue(await this.api.get<{"deviceTypes": DeviceTypeModel}>(`/device-types/${deviceTypeId}`));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onClose() {
    this.updateShadowModalOpen = false;
  }

  async submitData() {
    try {
      if (this.isEditing) {
        // Update/Insert data into deviceType table
        await this.api.put(`/device-types/${this.device_type_id}`, this.form.value);

        // Update shadows for all devices that have that the device type definition
        let thingsList:any = [];
        if (this.devicesToUpdate) {
          this.devicesToUpdate.forEach (async d => {
            thingsList.push(d.id);
          });
        }

        // split product variants from dropdown menu, so it can be passed as an array
        let variants: string[] = [];

        if (this.form.value.productVariant) {
          variants = this.form.value.productVariant.split(',');
        }

        const boot = {
          product_name_full: this.form.value.name,
          manufacturer: this.form.value.manufacturer,
          product_variant: variants,
          product_model: this.form.value.productModel,
          product_name: this.form.value.productName,
          platform_type: this.form.value.platformType
        };

        if (thingsList.length) {
          console.log("Updating boot shadow - Sending = " + JSON.stringify({ boot: boot, thingsList: thingsList }));
          await this.api.put(`/things/boot`, { shadow: boot, thingsList: thingsList });
        }

      } else {
        // If creating a new device type, no need to check for devices and update shadows, since the type is just being created
        // and is not associated with any device
        await this.api.post('/device-types', this.form.value);
      }

      this.router.navigateByUrl("/device-type");

    } catch (err) {
      console.error(err);
    }


  }

  async onSubmit() {
    try {
      this.blockUI?.start();

      // Get list of devices that might be impacted, only if editing
      if (this.isEditing) {
        this.devicesToUpdate = await this.api.get<DeviceModel[]>(`/devices/type/${this.device_type_id}`);
        console.log("devicesToUpdate = ", this.devicesToUpdate);
      }

      // Open Modal for Confirmation
      if (this.devicesToUpdate?.length > 0) {
        this.updateShadowModalOpen = true;
      }
      else {
        this.submitData();
      }
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: "Error occurred during save"
      });
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }

  setupControlsForManager() {
    this.setupControlsForReviewer();
  }

  setupControlsForReviewer() {
    this.form.get('name')?.disable();
    this.form.get('productCode')?.disable();
    this.form.get('productVersion')?.disable();
    this.form.get('manufacturer')?.disable();
    this.form.get('productModel')?.disable();
    this.form.get('productName')?.disable();
    this.form.get('platformType')?.disable();
    this.form.get('productVariant')?.disable();
  }
}
