import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DhcpReservationModel } from "src/app/models/config.model";

export interface StaticDhcpLeasesFunc {
  (): DhcpReservationModel[]
}
export function duplicateArrayValidator(array: FormArray): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    console.log(`duplicateArrayValidator() - control?.value?.name?.length = `, control?.value?.name?.length);
    console.log(`duplicateArrayValidator() - array?.value.length = `, array?.value.length);

    if (control?.value?.name?.length && array?.value?.length) {
      let isDuplicate = false;

      let idxFound = array.value.findIndex((item: any) => {
        return item.name === control.value.name
      });

      if (idxFound>=0) {
        isDuplicate = true;
      }
      console.log("isDuplicate = ", isDuplicate);
      res = isDuplicate ? { 
        duplicateArrayValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}