import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { FirewallRuleStatusEnum, FirewallRule} from 'src/app/models/config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { ipv4Validator } from '../../../../validators/ipv4Validator';
import { portValidator } from '../../../../validators/portValidator';

@Component({
  selector: 'app-add-advanced-rule',
  templateUrl: './add-advanced-rule.component.html',
  styleUrls: ['./add-advanced-rule.component.scss']
})
export class AddAdvanceRuleComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() isAdding = false;
  @Input() isViewing = false;
  @Input() isReviewer = false;
  @Input() advancedRule?: FirewallRule;
  @Input() advancedRuleBase?: FirewallRule;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  FIREWALL_RULES_STATUS_ENUM = FirewallRuleStatusEnum;

  isLoading = false;
  configUtils = ConfigUtils;

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  action = '';
  source = '';
  ipAddress = '';
  sourcePort = '';
  destinationPort = '';
  protocol = '';
  comment = '';

  // Form

  protocolPlaceHolder = "Select Protocol";
  PROTOCOL_NOT_SET = "NOT SET"
  protocolOptions = [
    { name: "TCP", value: "tcp" },
    { name: "UDP", value: "udp" },
    { name: "TCP,UDP", value: "tcp,udp" }
  ];

  addFirewallAdvancedRuleForm = this.fb.group({
    action: [{ value: "Forward", disabled: true }, Validators.required],
    source: [{ value: "Internet", disabled: true }, Validators.required],
    ipAddress: [{ value: null, disabled: false }, [Validators.required, ipv4Validator()]],
    sourcePort: [{ value: null, disabled: false }, [Validators.required, portValidator()]],
    destinationPort: [{ value: null, disabled: false }, [Validators.required, portValidator()]],
    protocol: [{ value: null, disabled: false }, Validators.required],
    comment: [{ value: null, disabled: false }],
    key: [{ value: null, disabled: false }]
  });

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit() isAdding = ", this.isAdding);
    this.load();
  }

  async load() {
    console.log("loading AddAdvanceRuleComponent");
    try {
      this.isLoading = true;
    } finally {
      this.isLoading = false;
    }
  }

  async onShow() {
    console.log("onShow() AddAdvanceRuleComponent advancedRule= ", JSON.stringify(this.advancedRule));
    console.log("onShow() AddAdvanceRuleComponent advancedRuleBase= ", JSON.stringify(this.advancedRuleBase));
    console.log("onShow() isViewing = ", this.isViewing);
    console.log("onShow() isAdding = ", this.isAdding);

    if (this.isAdding) { // If adding new field, just clear it
      this.clearFields();
    }

    if (this.isViewing || this.isReviewer) {
      this.disableControlsForView();

      // Disable dropdown place holder
      this.protocolPlaceHolder = this.PROTOCOL_NOT_SET;

      // Load SIP Desired
      if (this.advancedRuleBase) {
        this.action = this.advancedRuleBase.action;
        this.source = this.advancedRuleBase?.source;
        this.ipAddress = this.advancedRuleBase.ipAddress;
        this.sourcePort = this.advancedRuleBase?.sourcePort ;
        this.destinationPort =this.advancedRuleBase.destinationPort;
        this.protocol = this.advancedRuleBase.protocol;
        this.comment = this.advancedRuleBase.comment;
      }
    }
    if (this.advancedRule) {
      this.addFirewallAdvancedRuleForm.patchValue({
        action : "",
        source : "",
        ipAddress : this.advancedRule.ipAddress,
        sourcePort : this.advancedRule?.sourcePort,
        destinationPort :this.advancedRule.destinationPort,
        protocol : this.advancedRule.protocol,
        comment : this.advancedRule.comment,
        key: this.advancedRule.key
      });

      // Patch Specific Fields
      if (this.advancedRuleBase?.status != FirewallRuleStatusEnum.PENDING) {
        this.addFirewallAdvancedRuleForm.patchValue({
          action : "Forward",
          source : "Internet",
        });
      }
    }
    console.log("onShow() AddAdvanceRuleComponent = ",  this.addFirewallAdvancedRuleForm.value.ipAddress);
  }

  async onCloseDialog() {
    console.log("onCloseDialog() AddAdvanceRuleComponent advancedRule= ", JSON.stringify(this.advancedRule));
    console.log("onCloseDialog() AddAdvanceRuleComponent advancedRuleBase= ", JSON.stringify(this.advancedRuleBase));
    console.log("onCloseDialog() isViewing = ", this.isViewing);
    console.log("onCloseDialog() isAdding = ", this.isAdding);

    //this.addFirewallAdvancedRuleForm.reset();
    // Only clear if you are editing
     console.log("onCloseDialog() AddAdvanceRuleComponent = ", JSON.stringify(this.advancedRule));
  }

  clearFields() {
    console.log("clearFields() AddAdvanceRuleComponent advancedRule= ", JSON.stringify(this.advancedRule));
    console.log("clearFields() AddAdvanceRuleComponent advancedRuleBase= ", JSON.stringify(this.advancedRuleBase));
    console.log("clearFields() isViewing = ", this.isViewing);
    console.log("clearFields() isAdding = ", this.isAdding);
    this.addFirewallAdvancedRuleForm.reset();
    // Patch the default values when adding
    this.addFirewallAdvancedRuleForm.patchValue({
      action : "Forward",
      source : "Internet",
    });
    this.ipAddress = '';
    this.sourcePort = '';
    this.destinationPort = '';
    this.protocol = '';
    this.comment = '';
    //this.advancedRule = undefined;
  }

  async save() {
    console.log("save() - this.addFirewallAdvancedRuleForm.value = ", this.addFirewallAdvancedRuleForm.value);
    try {
      this.blockUI?.start();

      const advancedRule: FirewallRule = {
        action: "Forward",
        source: "Internet",
        ipAddress: this.addFirewallAdvancedRuleForm.value.ipAddress,
        sourcePort: this.addFirewallAdvancedRuleForm.value.sourcePort,
        destinationPort: this.addFirewallAdvancedRuleForm.value.destinationPort,
        protocol: this.addFirewallAdvancedRuleForm.value.protocol,
        comment: this.addFirewallAdvancedRuleForm.value.comment,
        key: this.addFirewallAdvancedRuleForm.value.ipAddress + this.addFirewallAdvancedRuleForm.value.sourcePort +
            this.addFirewallAdvancedRuleForm.value.destinationPort + this.addFirewallAdvancedRuleForm.value.protocol
      }

      console.log("save() - this.addFirewallAdvancedRuleForm.value = ", advancedRule);
      // This calls the onClose defined in the parent ie. (onClose)="saveSipExtension($event)"
      this.onSave.emit(advancedRule);
      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.clearFields()
      this.blockUI?.stop();
    }
  }

  disableControlsForView() {
    this.addFirewallAdvancedRuleForm.get('action')?.disable();
    this.addFirewallAdvancedRuleForm.get('source')?.disable();
    this.addFirewallAdvancedRuleForm.get('ipAddress')?.disable();
    this.addFirewallAdvancedRuleForm.get('sourcePort')?.disable();
    this.addFirewallAdvancedRuleForm.get('destinationPort')?.disable();
    this.addFirewallAdvancedRuleForm.get('protocol')?.disable();
    this.addFirewallAdvancedRuleForm.get('comment')?.disable();
  }
}
