import { Component, OnInit } from '@angular/core';
import { FirmwareModel } from 'src/app/models/firmware.model';


import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent implements OnInit {

  isLoading = false;

  firmwareVersions: any[] = [];
  //baseDownloadUrl = environment.api.baseUrl + "/firmware/download";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private breadcrumbService: AppBreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Firmware' }]);
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;

      const firmwareVersionsRsp: any = await this.api.get<{ "firmwareVersionsList": any[] }>(`/firmware/versions`);
      if (firmwareVersionsRsp) {
        this.firmwareVersions = firmwareVersionsRsp;
      }

    } finally {
      this.isLoading = false;
    }
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onClose() {
    this.load();
  }

  clear(table: Table) {
    table.clear();
  }

  download(version: string, filename: string) {
    console.log(`all Firmwares found = ${JSON.stringify(this.firmwareVersions)}`);
    // Downloads the streamed blob to local file system
    const url = `/firmware/download/${version}`;
    this.api.download(url).subscribe( (blob: Blob) => { saveAs(blob, filename) });
  }

  downloadMibs(version: string, filename: string) {
    console.log(`Firmwares found = ${JSON.stringify(this.firmwareVersions)}`);
    // Downloads the streamed blob to local file system
    const url = `/firmware/download/mibs/${version}`;
    this.api.download(url).subscribe( (blob: Blob) => { saveAs(blob, filename) });
  }
}
