export enum ProductVariant {
  Land = "land,voice",
  LandIoT = "land,iot",
  Maritime = "maritime,voice",
  MaritimeIoT = "maritime,iot",
  Aviation = "aviation,voice",
  AviationIoT = "aviation,iot"
}
export interface DeviceTypeModel {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  productCode: string;
  productVersion: string;
  productModel: string;
  productType: string;
  platformType: string;
  manufacturer: string;
  productVariantDisplay: string;
  productVariant: ProductVariant.Land | ProductVariant.LandIoT | ProductVariant.Maritime |  ProductVariant.MaritimeIoT | ProductVariant.Aviation | ProductVariant.AviationIoT;
}


