import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { DeviceModel } from 'src/app/models/device.model';
import { OrgModel } from 'src/app/models/org.model';
import { ResellerModel } from 'src/app/models/reseller.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';

@Component({
  selector: 'app-device-assign-reseller',
  templateUrl: './assign-reseller.component.html',
  styleUrls: ['./assign-reseller.component.scss']
})
export class DeviceAssignResellerComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() devices: DeviceModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  resellers: OrgModel[] = [];
  resellerSelected?: OrgModel;

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const rsp = await this.api.get< { "orgs": OrgModel[] } >('/resellers');
      this.resellers = rsp.orgs;
    } finally {
      this.isLoading = false;
    }
  }

  async assign() {
    if (this.resellerSelected) {
      try {
        this.blockUI?.start();
        await Promise.all(this.devices.map(device =>
          this.api.put(`/devices/${device.id}`, { resellerOrgId:  this.resellerSelected?.id })
        ));

        this.resellerSelected = undefined;

        this.onClose.emit();
        this.visible = false;
        this.visibleChange.emit(false);
      } catch (err) {
        this.messageService.addAll(parseApiErrorsToToast(err));
      } finally {
        this.blockUI?.stop();
      }
    }
  }

  async unAssign() {
    try {
      this.blockUI?.start();

      await Promise.all(this.devices.map(async device => {
        await this.api.put(`/devices/${device.id}`, {
          resellerOrgId: null
        });
      }));

      this.onClose.emit();

      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.resellerSelected = undefined;
  }
}
