<p-card>
  <div class="header">{{ title }}</div>
  <div *ngIf="show; then data else noData"></div>
  <ng-template #data>
    <p-chart type="pie" [data]="chartData"></p-chart>
  </ng-template>
  <ng-template #noData>
    <div class="card">
      <p>No Data Reported</p>
    </div>
  </ng-template >
  <div class="footer">{{ footer }}</div>
</p-card>
