import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigModel } from 'src/app/models/config.model';
import { DeviceTypeModel } from 'src/app/models/device-type.model';
import { DeviceModel } from 'src/app/models/device.model';
import { ReportModel } from 'src/app/models/report.model';
import { JobsModel, JobTypesEnum, JobStatus, JobTypes } from 'src/app/models/job.model';
import { UserModel } from 'src/app/models/user.model';
import { VoiceConfigModel } from 'src/app/models/voice-config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { Utils } from 'src/app/util/utils';
import { DeviceCheckInModel } from 'src/app/models/device-check-in.model';
import { nameValidator } from '../../../validators/nameValidator';
import { friendlyNameRegEx, assetTagRegEx } from 'src/app/util/regex-utils';
import { CallLogModel } from 'src/app/models/call-log.model';
import { FetchUtils } from 'src/app/util/fetch-utils';
import { OrgModel } from 'src/app/models/org.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Auth } from 'aws-amplify';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})


export class DeviceEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  currentUser?: UserModel;
  isVam: boolean = false;
  isReseller: boolean = false;
  isAdmin: boolean = false;
  isCustomer: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;
  isAccountManager: boolean = false;

  isIotDevice: boolean = false;

  isEditing: boolean = false;
  device_id?: string;
  device?: DeviceModel;
  deviceTypes: DeviceTypeModel[] = [];
  deviceTypeInfo?: DeviceTypeModel;
  deviceConfigs: ConfigModel[] = [];
  voiceConfigs: VoiceConfigModel[] = [];
  reports: ReportModel[] = [];
  callLog: CallLogModel[] = [];
  jobsRsp: JobsModel[] = [];
  orgs: OrgModel[] = [];

  JobStatus = JobStatus;
  JobTypes = JobTypes;
  jobsSelected: JobsModel[] = [];

  cancelJobsModalOpen = false;

  // Save Original Device Config and Voice Config
  origDeviceConfigId: string = '';
  origVoiceConfigId: string = '';
  origDeviceTypeId: string = '';
  origAssetTag: string = '';
  origDeviceName: string = '';
  origProductVariant: string = '';
  origDeviceTypeName: string = '';
  origDeviceTypeManufacturer: string = '';

  shadowReportedAssetTag: string = '';
  shadowReportedName: string = '';

  shadowAssetTag: string = '';
  shadowProductVariant: string = '';
  shadowDeviceTypeManufacturer: string = '';
  shadowDeviceTypeName: string = '';
  shadowDeviceTypeProductModel: string = '';
  shadowDeviceTypeProductName: string = '';
  shadowDeviceTypePlatformType: string = '';

  shadowReportedRemoteManagementInterval: string = '';

  query: string = '?shadowList=system,boot,remote_management';

  latestFirmware: any;
  currentDeviceFirmware: any;
  disableUpdateFirmwareButton: any = false;
  disableResetToDefaultsButton: any = false;
  disableRebootButton: any = false;
  disableForceConfigButton: any = false;
    
  JOB_TYPE_ENUM =  JobTypesEnum;

  urlCellSafe: any;
  urlSatelliteSafe: any;

  urlCellFrom: string = `&from=now-24h`;
  urlCellTo: string = `&to=now`
  urlSatelliteFrom: string = `&from=now-24h`;
  urlSatelliteTo: string = `&to=now`
  urlGrafanaBaseUrl: string = environment.grafana.baseUrl;

  urlCellIframe: any;
  urlSatelliteIframe: any
  jwtToken: string = "";
  
  // GRAFANA from=now-15m&to=now&theme=light&panelId=2&editPanel=2&tab=query
  dataUsageRangeOptions = [
    { name: "Last 24 hrs",  value: "now-24h"},
    { name: "Last 2 days", value: "now-2d"},
    { name: "Last 7 days", value: "now-7d"},
    { name: "Last 14 days", value: "now-14d"},
    { name: "Last month", value: "now-1M" },
    { name: "Custom", value: "custom" },
  ];

  form = this.fb.group({
    name: [{ value: null, disabled: false },
      [Validators.required, Validators.maxLength(50), nameValidator(friendlyNameRegEx)]],
    assetTag: [{ value: null, disabled: false },
      [Validators.maxLength(50), nameValidator(assetTagRegEx)]],
    serial: [""],
    attributes: this.fb.array([]),
    tags: this.fb.array([]),
    deviceType: this.fb.group({
      id: []
    }),
    org: this.fb.group({
      id: []
    }),
    deviceConfig: this.fb.group({
      id: []
    }),
    voiceConfig: this.fb.group({
      id: []
    }),
    cellDataUsageRange: "",
    satelliteDataUsageRange: "",

  });

  cellDataUsageRange :any;
  cellDataUsageFrom: any;
  satelliteDataUsageFrom: any;
  cellDataUsageTo: any;
  satelliteDataUsageTo: any;
  
  //#region ATTRIBUTES
  buildFormAttributes(device: DeviceModel) {
    const attributes = (this.form.get('attributes') as FormArray);
    device.attributes.forEach(() => {
      const formAttr = this.createFormAttribute();
      if (!this.isVam || this.isReviewer || this.isAccountManager) {
        formAttr.disable()
      }
      attributes.push(formAttr);
    });
  }

  createFormAttribute(): FormGroup {
    return this.fb.group({
      key: [],
      value: []
    });
  }

  addFormAttribute(): void {
    const attributes = this.form.get('attributes') as FormArray;
    attributes.push(this.createFormAttribute());
  }

  removeFormAttribute(index: number): void {
    (this.form.get('attributes') as FormArray).removeAt(index);
  }

  getAttributesIterator(): Iterable<any> {
    return (this.form.get('attributes') as FormArray).controls;
  }
  //#endregion

  //#region TAGS
  buildFormTags(device: DeviceModel) {
    const tags = (this.form.get('tags') as FormArray);
    device.tags.forEach(() => {
      const formTag = this.createFormTag();
      if (this.isReviewer) {
        formTag.disable()
      }
      tags.push(formTag);
    });
  }

  createFormTag(): FormGroup {
    return this.fb.group({
      name: [],
      value: []
    });
  }

  addFormTag(): void {
    const tags = this.form.get('tags') as FormArray;
    tags.push(this.createFormTag());
  }

  removeFormTag(index: number): void {
    (this.form.get('tags') as FormArray).removeAt(index);
  }

  getTagsIterator(): Iterable<any> {
    return (this.form.get('tags') as FormArray).controls;
  }
  //#endregion

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmService: ConfirmationService,
    private fb: FormBuilder,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService,
    public sanitizer: DomSanitizer,
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReseller = this.currentUser?.organization.type === 'Reseller';
      this.isAdmin = this.currentUser?.role === 'Admin';
      this.isCustomer = this.currentUser?.organization.type === 'Customer';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
      this.isAccountManager = (this.currentUser?.role === 'AccountManager' ||
                               this.currentUser?.role === 'User');
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.device_id) {
        if (!this.isReviewer) {
          this.isEditing = true;
        }
        this.device_id = params.device_id;
        this.load(params.device_id);
      }

      if (this.isReviewer) {
        this.breadcrumbService.setItems([{ label: 'Devices', routerLink: '/devices' }, { label: 'View' }])
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([{ label: 'Devices', routerLink: '/devices' }, { label: 'Edit' }])
      } else {
        this.breadcrumbService.setItems([{ label: 'Devices', routerLink: '/devices' }, { label: 'Add' }])
      }

    });

    this.reports = [{
      lastCheckIn: "",
      firmware: "-"
    }];
  }

  private setupJobsButtons() {
    this.setupReboot();
    this.setupResetToDefaults();
    this.setupForceConfig();
    this.setupUpdateFirmware()
  }

  private setupReboot() {
    const scheduledJobs = this.jobsRsp.filter(job =>job.jobDefinitions?.name ===  this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.REBOOT] &&
                                                     job.status == 'Scheduled')
    
    if (scheduledJobs.length) {
      this.disableRebootButton = true;
    } else {
      this.disableRebootButton = false;
    }
  }
  private setupResetToDefaults() {
    const scheduledJobs = this.jobsRsp.filter(job => job.jobDefinitions?.name ===  this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.HARD_FACTORY_RESET] &&
                                                      job.status == 'Scheduled');
    if (scheduledJobs.length) {
      this.disableResetToDefaultsButton = true;
    } else {
      this.disableResetToDefaultsButton = false;
    }
  }
  private setupForceConfig() {
    const scheduledJobs = this.jobsRsp.filter(job => job.jobDefinitions?.name ===  this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.FACTORY_RESET] &&
                                                      job.status == 'Scheduled');
    if (scheduledJobs.length) {
      this.disableForceConfigButton = true;
    } else {
      this.disableForceConfigButton = false;
    }
  }
  private setupUpdateFirmware() {
    const scheduledJobs = this.jobsRsp.filter(job => job.jobDefinitions?.name ===  this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.FIRMWARE_UPDATE] &&
                                                      job.status == 'Scheduled');

    const currentFirmware = this.currentDeviceFirmware? this.currentDeviceFirmware: 1000000; 
   
    if (this.latestFirmware < currentFirmware  || scheduledJobs.length) {
      this.disableUpdateFirmwareButton = true;
    } else {
      this.disableUpdateFirmwareButton = false;
    }
  }
  
  async loadJobs() {
    if (this.device) {
      this.jobsRsp = await FetchUtils.getJobsById(this.api, this.device.id);
      this.setupJobsButtons();
    }
  }

  async load(id: string) {
    try {
      this.blockUI?.start();

      this.jwtToken = await this.getToken();

      if (this.isReviewer) {
        this.setupControlsForReviewer();
      } else if (this.isOps) {
        this.setupControlsForOps();
      } else if (this.isAccountManager) {
        this.setupControlsForManager();
      } else if (this.isEditing) {
        this.setupControlsForEdit();
      }

      let deviceTypeRsp: DeviceTypeModel[] = [];
      let configRsp: ConfigModel[] = [];
      let voiceConfigRsp: VoiceConfigModel[] = [];

      let thingRsp: any;
      let firmwareRsp: any;
      let deviceFirmwareRsp: any;
      let callLogRsp: CallLogModel[] = [];
      let orgsRsp: OrgModel[] = [];
      let jobsRsp: JobsModel[] = [];
      let lastCheckInRsp: DeviceCheckInModel[] = [];

      await Promise.all ([
        FetchUtils.getDeviceTypes(this.api),
        // this.api.get<{"deviceTypes": DeviceTypeModel[]}>('/device-types'),
        FetchUtils.getLatestConfigs(this.api),
        // this.api.get<{"configs": ConfigModel[]}>('/configs/latest'),
        FetchUtils.getLatestVoiceConfigs(this.api),
        // this.api.get<{"voiceConfigs": VoiceConfigModel[]}>('/voice-configs/latest'),
        FetchUtils.getThingsById(this.api, id, this.query),
        // this.api.get(`/things/${id}${this.query}`),
        FetchUtils.getLatestFirmwareVersion(this.api),
        // this.api.get(`/firmware/version`),
        FetchUtils.getLatestFirmwareVersionById(this.api, id),
        // this.api.get(`/firmware/version/${id}`),
        FetchUtils.getDeviceById(this.api, id),
        // this.api.get(`/devices/${id}`),
        FetchUtils.getDeviceCheckInsById(this.api, id),
        // this.api.get<{"checkIns": DeviceCheckInModel[]}>(`/device-check-in/${id}`),
        FetchUtils.getCallLogsById(this.api, id),
        // this.api.get<{"calls": CallLogModel[]}>(`/callLogs/${id}/300`),
        FetchUtils.getOrgs(this.api),
        FetchUtils.getJobsById(this.api, id)
      ]).then((response: any) =>{
        deviceTypeRsp = response[0];
        console.log(`deviceTypeRsp = ${JSON.stringify(deviceTypeRsp)}`);
        configRsp = response[1];
        console.log(`configRsp = ${JSON.stringify(configRsp)}`);
        voiceConfigRsp = response[2];
        console.log(`voiceConfigRsp = ${JSON.stringify(voiceConfigRsp)}`);
        thingRsp = response[3];
        console.log(`thingRsp = ${JSON.stringify(thingRsp)}`);
        firmwareRsp = response[4];
        console.log(`firmwareRsp = ${JSON.stringify(firmwareRsp)}`);
        deviceFirmwareRsp = response[5];
        console.log(`deviceFirmwareRsp = ${JSON.stringify(deviceFirmwareRsp)}`);
        this.device = response[6];
        ///console.log(`this.device = ${JSON.stringify(this.device)}`);
        lastCheckInRsp = response[7];
        console.log(`lastCheckInRsp = ${JSON.stringify(lastCheckInRsp)}`);
        callLogRsp = response[8];
        console.log(`callLogRsp = ${callLogRsp}`);
        orgsRsp = response[9];
        console.log(`orgsRsp = ${orgsRsp}`);
        jobsRsp = response[10];
        console.log(`jobsRsp = ${jobsRsp}`);
      }).catch(failure => console.log(` I failed ${JSON.stringify(failure)}`));

      this.deviceTypes = deviceTypeRsp;
      console.log("deviceTypes = ", this.deviceTypes);

      this.deviceConfigs = configRsp;
      console.log("deviceConfigs = ", this.deviceConfigs);

      this.voiceConfigs = voiceConfigRsp;
      console.log("voiceConfigs = ", this.deviceConfigs);

      this.callLog = callLogRsp;
      console.log(`callLog= ${JSON.stringify(this.callLog)}`);

      if (this.device) {

        this.generateIframeCellUrl();
        this.generateIframeSatelliteUrl();

        if (firmwareRsp) {
          this.latestFirmware = firmwareRsp.version?.S;
        }
        this.orgs = orgsRsp;
        this.jobsRsp = jobsRsp;

        if (deviceFirmwareRsp) {
          this.currentDeviceFirmware = deviceFirmwareRsp.software?.split('-')[0];
        }
        this.setupJobsButtons();

        console.log(`latestFirmware=${this.latestFirmware} device's=${this.currentDeviceFirmware}`)

//        if (this.device) {
          // Map uppercase attributes to lowercase
          this.device.attributes = this.device.attributes.map( a => {

            if (a.Key) {
              a.key = a.Key;
              delete a.Key;
            }

            if (a.Value) {
              a.value = a.Value;
              delete a.Value;
            }

            return a;
          });

          // Map uppercase tags to lowercase
          this.device.tags = this.device.tags.map( t => {

            if (t.Name) {
              t.name = t.Name;
              delete t.Name;
            }

            if (t.Value) {
              t.value = t.Value;
              delete t.Value;
            }

            return t;
          });

          // If ioTDevice, do NOT show Voice Config
          this.isIotDevice  = this.device.deviceType?.productVariant?.indexOf('iot') !== -1;

          // Save the device's configs
          this.origDeviceConfigId = this.device.deviceConfig?.id;
          this.origVoiceConfigId = this.device.voiceConfig?.id;
          this.origDeviceTypeId = this.device.deviceType?.id;
          this.origDeviceName = this.device.name;
          this.origAssetTag = this.device.assetTag;

          // get things from shadows to send only modified data
          const typeId = this.origDeviceTypeId;
          this.deviceTypeInfo =  Utils.altFind(this.deviceTypes, function(x : any) { return x.id === typeId });

          this.origProductVariant = this.deviceTypeInfo?.productVariant || '';
          this.origDeviceTypeName = this.deviceTypeInfo?.name || '';
          this.origDeviceTypeManufacturer = this.deviceTypeInfo?.manufacturer || '';

          // Get values from shadow
          this.shadowReportedAssetTag = thingRsp?.shadows?.system?.state?.reported?.asset_tag;
          this.shadowReportedName = thingRsp?.shadows?.system?.state?.reported?.friendly_name;

          // Remote Management shadow data
          this.shadowReportedRemoteManagementInterval = thingRsp?.shadows?.remote_management?.state?.desired?.remote_management_interval;

          // desired values are used to determine if shadow values needed to be updated or not.
          this.shadowAssetTag = thingRsp?.shadows?.system?.state?.desired?.asset_tag;

          this.shadowProductVariant = thingRsp?.shadows?.boot?.state?.desired?.product_variant;
          this.shadowDeviceTypeManufacturer = thingRsp?.shadows?.boot?.state?.desired?.manufacturer;
          this.shadowDeviceTypeName = thingRsp?.shadows?.boot?.state?.desired?.product_name_full;
          this.shadowDeviceTypeProductModel = thingRsp?.shadows?.boot?.state?.desired?.product_model;
          this.shadowDeviceTypeProductName = thingRsp?.shadows?.boot?.state?.desired?.product_name;
          this.shadowDeviceTypePlatformType = thingRsp?.shadows?.boot?.state?.desired?.platform_type;


          console.log (`shadow reported=${this.shadowReportedAssetTag} name=${this. shadowReportedName} remote_management_interval=${this.shadowReportedRemoteManagementInterval}`);

          // Build up the controls first
          this.buildFormAttributes(this.device);
          this.buildFormTags(this.device);

          this.form.patchValue(this.device);

          // Get Last Check In
          if (lastCheckInRsp) {
            const lastCheckInValue = lastCheckInRsp[0];
            if (lastCheckInValue) {
              this.reports[0].lastCheckIn = lastCheckInValue.createdAt;
            }
          }
          // this.reports[0].health = this.device.health;
          this.reports[0].firmware = this.currentDeviceFirmware;

          this.form.patchValue(this.reports);
//        }
      }
    } catch (error) {
      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }

  async submit() {
    try {
      this.blockUI?.start();

      console.log("devices/edit onSubmit() - deviceId = " + this.device?.id);
      console.log("devices/edit onSubmit() - form = " + JSON.stringify(this.form.value));
      const device = {
        name: this.form.value.name,
        serial: this.form.value.serial,
        assetTag: this.form.value.assetTag,
        tags: this.form.value.tags,
        attributes: this.form.value.attributes,
        deviceConfigId: this.form.value.deviceConfig?.id,
        voiceConfigId: this.form.value.voiceConfig?.id,
        orgId: this.form.value.org?.id,
        deviceTypeId: this.form.value.deviceType?.id
      }

      console.log("devices/edit onSubmit() - Sending = " + JSON.stringify(device));
      // Update the server
      if (this.isEditing) {
        await this.api.put(`/devices/${this.device?.id}`, device);
      } else {
        await this.api.post(`/devices`, device);
      }

      // Since device type is already assigned at this point, just get the value from the list of device types
      const typeId: string = this.origDeviceTypeId;

      // Update system shadow only the values that have changed.
      let system: any = {};
      let boot: any = {
        product_variant: []
      };
      let systemShadowModified: boolean = false;
      let bootShadowModified: boolean = false;

      // If sending something always send platform_type
      //boot.platform_type = DeviceEditComponent.PLATFORM_TYPE;
      boot.platform_licenses = [];
      boot.serial_number = this.device?.serial;

      // For asset and name you are allowed to change values in this page, so compare form value (*new value) with shadow value
      if (this.shadowAssetTag !== device.assetTag) {
        console.log("Updating system asset tag");
        system.asset_tag = device.assetTag;
        systemShadowModified = true;
      }

      // If nothing is in for used data from DB else use what's in the form
      let deviceTypeIdFieldComparator: any;
      if (!device.deviceTypeId) {
        deviceTypeIdFieldComparator = this.origDeviceTypeId;
      } else {
        deviceTypeIdFieldComparator = device.deviceTypeId;
      }
      const modifiedDeviceTypeInfo =  Utils.altFind(this.deviceTypes, function(x : any) { return x.id === deviceTypeIdFieldComparator });

      const modifiedProductVariant = modifiedDeviceTypeInfo?.productVariant || '';
      const modifiedDeviceTypeName = modifiedDeviceTypeInfo?.name || '';
      const modifiedDeviceTypeManufacturer = modifiedDeviceTypeInfo?.manufacturer || '';
      const modifiedDeviceTypeProductModel = modifiedDeviceTypeInfo?.productModel || '';
      const modifiedDeviceTypeProductName = modifiedDeviceTypeInfo?.productName || '';
      const modifiedDeviceTypePlatformType = modifiedDeviceTypeInfo?.platformType || '';

      if (modifiedProductVariant  && this.shadowProductVariant != modifiedProductVariant) {
        const variants = modifiedProductVariant.split(',');
        boot.product_variant = variants;
        bootShadowModified = true;
      }
      if (modifiedDeviceTypeName && this.shadowDeviceTypeName !== modifiedDeviceTypeName) {
        boot.product_name_full = modifiedDeviceTypeName;
        bootShadowModified = true;
      }
      if (modifiedDeviceTypeManufacturer && this.shadowDeviceTypeManufacturer !== modifiedDeviceTypeManufacturer) {
        boot.manufacturer = modifiedDeviceTypeManufacturer;
        bootShadowModified = true;
      }
      if (modifiedDeviceTypeProductModel && this.shadowDeviceTypeProductModel !== modifiedDeviceTypeProductModel) {
        boot.product_model = modifiedDeviceTypeProductModel;
        bootShadowModified = true;
      }
      if (modifiedDeviceTypeProductName && this.shadowDeviceTypeProductName !== modifiedDeviceTypeProductName) {
        boot.product_name = modifiedDeviceTypeProductName;
        bootShadowModified = true;
      }
      if (modifiedDeviceTypePlatformType && this.shadowDeviceTypePlatformType !== modifiedDeviceTypePlatformType) {
        boot.platform_type = modifiedDeviceTypePlatformType;
        bootShadowModified = true;
      }

      // 
      let shadowData: any = {}

      // Send Shadows accordingly
      if (bootShadowModified) {
        console.log("Updating boot shadow - Sending = " + JSON.stringify(boot));
        shadowData.boot = boot;
      }
      if (systemShadowModified) {
        console.log("Updating system shadow - Sending = " + JSON.stringify(system));
        shadowData.system = system;
      }

      // Retrieve Device Config from server and update Shadows
      if ( device.deviceConfigId &&
           device.deviceConfigId !== this.origDeviceConfigId ) {
        const configChosen = await this.api.get<ConfigModel>(`/configs/${device.deviceConfigId}`);
        if (configChosen) {
          const configInfoChosen = configChosen.config;
          if (configInfoChosen) {
            shadowData = configInfoChosen.shadows;
          }
        }
      }

      // Retrieve Voice Config from server
      console.log("voiceConfigId = ", device.voiceConfigId);
      console.log("origVoiceConfigId = ", this.origVoiceConfigId);

      if (device.voiceConfigId &&
          device.voiceConfigId !== this.origVoiceConfigId) {
        const vConfigChosen = await this.api.get<VoiceConfigModel>(`/voice-configs/${device.voiceConfigId}`);
        console.log("vConfigChosen = ", vConfigChosen);
        if (vConfigChosen) {
          const vConfigInfoChosen = vConfigChosen.config;
          if (vConfigInfoChosen) {
            shadowData = vConfigInfoChosen.shadows;
          }
        }
      }

      // Send shadowData in one swoop
      if (Object.keys(shadowData).length !== 0) {
        console.log(`Sending ShadowData = `, shadowData);
        await this.api.put(`/things/${this.device?.id}/shadows`, shadowData);
      }

      this.router.navigate(["/devices"]);
    } catch (error) {
      console.log (error);
      debugger;

      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onClose() {
    this.cancelJobsModalOpen = false;
    this.jobsSelected = [];
    this.loadJobs();
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }

  async gotoDeviceConfig() {
    this.router.navigate(['/devices/config', this.device?.id]);
  }

  async gotoVoiceConfig() {
    this.router.navigate(['/devices/vconfig', this.device?.id]);
  }

  async runJob (jobType: any) {

    let message: string = "Are you sure you want to perform this action?";
    console.log(jobType);

    if (jobType === this.JOB_TYPE_ENUM.FIRMWARE_UPDATE) {
      jobType = this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.FIRMWARE_UPDATE];
      message = "This command will force the device to update the firmare next time it checks in. Are you sure?";
    } else if (jobType === this.JOB_TYPE_ENUM.REBOOT) {
      jobType = this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.REBOOT];
      message = "This command will force the device to reboot next time it checks in. Are you sure?";
    } else if (jobType === this.JOB_TYPE_ENUM.FACTORY_RESET) {
      jobType = this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.FACTORY_RESET];
      message = "You are about to schedule a force push of a new configuration to the device next time it checks in. Are you sure?";
    } else if (jobType === this.JOB_TYPE_ENUM.HARD_FACTORY_RESET) {
      jobType = this.JOB_TYPE_ENUM[this.JOB_TYPE_ENUM.HARD_FACTORY_RESET];
      message = "You are about to schedule a hardware factory reset the next time it checks in. Are you sure?";
    } else {
      jobType = 'TEST';
      message = "You are about to schedule a noop test the next time it checks in. Are you sure?";
    }

    this.confirmService.confirm({
      message: message,
      acceptButtonStyleClass: "p-button-warning",
      accept: async () => {
        try {
          if (this.device) {
            await this.api.post(`/jobs/${this.device.id}/${jobType}`, {});
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: "Job created sucessfully."
            });
            this.loadJobs();
          }
        } catch (err) {
          this.messageService.addAll(parseApiErrorsToToast(err));
        }
      }
    });
  }

  async setupControlsForEdit() {
    console.log("setupControls() - isVam = ", this.isVam);
    if (!this.isVam) {
      this.form.get('serial')?.disable();
      this.form.get('deviceType')?.disable();
    } else {
      // If deviceConfig and voiceConfig configured, don't allow user to change deviceType
      if (this.form.get('deviceConfig')?.value.id || this.form.get('voiceConfig')?.value.id) {
        this.form.get('deviceType')?.disable();
      }
    }
  }

  async setupControlsForReviewer() {
    this.form.get('name')?.disable();
    this.form.get('assetTag')?.disable();
    this.form.get('serial')?.disable();
    this.form.get('attributes')?.disable();
    this.form.get('tags')?.disable();
    this.form.get('deviceType')?.disable();
    this.form.get('deviceConfig')?.disable();
    this.form.get('voiceConfig')?.disable();
  }

  async setupControlsForOps() {
    this.form.get('deviceConfig')?.disable();
    this.form.get('voiceConfig')?.disable();
  }

  async setupControlsForManager() {
    this.form.get('serial')?.disable();
    this.form.get('deviceType')?.disable();
    this.form.get('attributes')?.disable();
  }

  // Display/Hide the Voice Configs dropdown (if IOT)
  async handleDeviceTypeChange(event: any) {
    console.log("In handleDeviceTypeChange() - event.value", event.value);
    const chosenId = event?.value;

    const chosenDeviceType = (Utils.altFind(this.deviceTypes, function(x : any) {
      return x.id === chosenId;
    }))

    // Set isIotDevice
    this.isIotDevice = chosenDeviceType?.productVariant === 'data';
  }

  async getToken() {
    let session = await Auth.currentSession()

    if (!session.isValid()) {
      return "";
    } else {
      return session.getIdToken().getJwtToken();
    }
  }

  generateIframeCellUrl() {
    this.urlCellIframe =  `${this.urlGrafanaBaseUrl}${this.urlCellFrom}${this.urlCellTo}&var-title=Cellular&var-type=cell&var-device_id=${this.device_id}&var-org_id=${this.currentUser?.organization.id}`;
    this.urlCellSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlCellIframe);
    console.log(`CELL URL=${this.urlCellIframe}`);
    return this.urlCellSafe;
  }

  generateIframeSatelliteUrl() {
    this.urlSatelliteIframe =  `${this.urlGrafanaBaseUrl}${this.urlSatelliteFrom}${this.urlSatelliteTo}&var-title=Satellite&var-type=sat&var-device_id=${this.device_id}&var-org_id=${this.currentUser?.organization.id}`;
    this.urlSatelliteSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.urlSatelliteIframe);
    console.log(`SATELLITE URL=${this.urlSatelliteIframe}`);
    return this.urlSatelliteSafe;
  }
 
  setCellDataUsageFrom(value: string) {
    this.cellDataUsageFrom = value
  }
  setCellDataUsageTo(value: string) {
    this.cellDataUsageTo = value
  }
  setSatelliteDataUsageFrom(value: string) {
    this.satelliteDataUsageFrom = value
  }
  setSatelliteDataUsageTo(value: string) {
    this.satelliteDataUsageTo = value
  }
  
  satelliteDataUsageRangeClick  = async () => { 
    const satelliteTimeRange = this.form.get('satelliteDataUsageRange')?.value || this.dataUsageRangeOptions[0].value;
  
    // If not custom use pamr as defined in drop down until now
    if (satelliteTimeRange !== 'custom') {
      this.satelliteDataUsageFrom = null;
      this.satelliteDataUsageTo = null;
      this.urlSatelliteFrom = `&from=${satelliteTimeRange}`;
      this.urlSatelliteTo = '&to=now';
    } else { // if custom, then convert time entered into epoch in to from fields.
      this.urlSatelliteFrom = `&from=${new Date(this.satelliteDataUsageFrom).getTime()?.toString()}`;
      this.urlSatelliteTo = `&to=${new Date(this.satelliteDataUsageTo).getTime()?.toString()}`;
    }

    console.log(`CLICK urlSatelliteFrom= ${this.urlSatelliteFrom}`);
    console.log(`CLICK urlSatelliteTo= ${this.urlSatelliteTo}`);
    this.generateIframeSatelliteUrl();
  }

  cellDataUsageRangeClick = async () => {
    const cellTimeRange = this.form.get('cellDataUsageRange')?.value || this.dataUsageRangeOptions[0].value;
     
    // If not custom use pamr as defined in drop down until now
    if (cellTimeRange !== 'custom') {
      this.cellDataUsageFrom = null;
      this.cellDataUsageTo = null;
      this.urlCellFrom = `&from=${cellTimeRange}`;
      this.urlCellTo = '&to=now';
    } else { // if custom, then convert time entered into epoch in to from fields.
      this.urlCellFrom = `&from=${new Date(this.cellDataUsageFrom).getTime()?.toString()}`;
      this.urlCellTo = `&to=${new Date(this.cellDataUsageTo).getTime()?.toString()}`;
    }

    console.log(`CLICK urlCellFrom= ${this.urlCellFrom}`);
    console.log(`CLICK urlCellTo= ${this.urlCellTo}`);
    this.generateIframeCellUrl();
  }

}
