<div class="container">
    <h1>Firmware</h1>
    <p class="help-block">Check and Download all available firmware versions for your SkyLink device.</p>
    <div class="card">
    <p-table
      #firmwareVersionsList
      [value]="firmwareVersions"
      styleClass="p-datatable-striped"
      dataKey="id"
      [rows]="50"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[50, 100, 200]"
      [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['version.S', 'release_date.S']"
      sortMode="single"
      [loading]="isLoading"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="search(firmwareVersionsList, $event)" placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Version</th>
          <th>Release Date</th>
          <th>SHA256 Sum</th>
          <th>Release Notes</th>
          <th>Download</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-firmwareVersion>
        <tr>
          <td>
            {{ firmwareVersion.version.S }}
          </td>
          <td>
            {{ firmwareVersion.release_date.S }}
          </td>
          <td>
            {{ firmwareVersion.sha256sum.S }}
          </td>
          <td-release>
            <ul>
              <li *ngFor="let note of firmwareVersion.release_notes.L">
                {{ note.S }}
              </li>
            </ul>
          </td-release>
          <td>
            <div class="buttons">
              <button
                type="button"
                pButton
                pRipple
                label="{{firmwareVersion.update_file_short.S}}"
                class="p-button-link"
                (click)="download(firmwareVersion.version.S , firmwareVersion.update_file_short.S)">
              </button>
              <button *ngIf="firmwareVersion.mibs_file_short && firmwareVersion.snmp_mib_s3_path"
                type="button"
                pButton
                pRipple
                label="{{firmwareVersion.mibs_file_short.S}}"
                class="p-button-link"
                (click)="downloadMibs(firmwareVersion.version.S , firmwareVersion.mibs_file_short.S)">
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    </div>
  </div>

