<p-dialog header="Add Note" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">
  <form [formGroup]="addNoteForm">
    <div class="p-field p-col-12">
      <!-- <label for="noteTextField">Enter </label> -->
      <textarea
        style="width:100%; height:100%;"
        id="noteTextField"
        formControlName="noteText"
        pInputTextarea
        [rows]="5"
        [cols]="60"
        placeholder="Enter notes">
      </textarea>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="save()"
      label="Save"
      class="p-button-warning p-mr-2"
      icon="fas fa-save"
      type="button"
      [disabled]="!addNoteForm.valid || !addNoteForm.dirty">
    </button>
  </ng-template>

</p-dialog>
