import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Message } from "primeng/api";
import { environment } from 'src/environments/environment';
import { Observable }  from 'rxjs';
export interface HttpOptions {
  params?: HttpParams | {
    [param: string]: string | string[];
  };
}

export interface HttpRequestOptions extends HttpOptions {
  body?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  private constructUrl(urlFragment: string) {
    return `${environment.api.baseUrl}${urlFragment}`;
  }

  get<TResponse>(url: string, options?: HttpOptions): Promise<TResponse> {
    return this.http.get<TResponse>(this.constructUrl(url), options).toPromise();
  }

  post<TResponse>(url: string, body: any, options?: HttpOptions): Promise<TResponse> {
    return this.http.post<TResponse>(this.constructUrl(url), body, options).toPromise();
  }

  put(url: string, body: any, options?: HttpOptions): Promise<unknown> {
    return this.http.put(this.constructUrl(url), body, options).toPromise();
  }

  delete(url: string, options?: HttpOptions): Promise<unknown> {
    return this.http.delete(this.constructUrl(url), options).toPromise();
  }

  request(method: "GET" | "PUT" | "POST" | "DELETE", url: string, options?: HttpRequestOptions) {
    return this.http.request(method, this.constructUrl(url), options).toPromise();
  }

  getImage(url: string, options?: HttpOptions) {
    return this.http.get(this.constructUrl(url), {responseType: 'arraybuffer'}).toPromise();
  }

  download(url: string, options?: HttpOptions): Observable<Blob> {
    return this.http.get(this.constructUrl(url), {
      responseType: 'blob'
    });
  }

}

/**
 * Converts http response of errors into just plain string array of errors
 * @param response the error response
 * @returns {string[]} of errors
 */
export function parseApiErrors(response: HttpErrorResponse): string[] {
  var errors = [];
  if (response.error) {
    if (response.error.errors) {
      for (var key in response.error.errors) {
        for (var i = 0; i < response.error.errors[key].length; i++) {
          errors.push(response.error.errors[key][i]);
        }
      }
    } else {
      errors.push(response.error.detail);
    }
  }
  return errors;
}

export function createFailureToDeleteMsg(err: any, list: any[], entityName: string): Message[] {
  console.log(`${JSON.stringify(err)}`);
  let errors: Message[] = [];

  if (err.status === 400) {
    err.error.failures.forEach((failure: any) => {
      let i = 1;
      let msg = '';
      let last = failure.error.length;

      failure.error.forEach((error: any) => {
        if (i === 1) {
          msg = `${error.count} ${error.type}(s)`;
        } else if (i === last) {
          msg += ` and ${error.count} ${error.type}(s)`;
        } else {
          msg += `, ${error.count} ${error.type}(s)`;
        }
        i++;
      });

      const entity: any = list.find(x => x.id === failure.id);
      let detail = `${entity.name}: There are ${msg} assigned to this ${entityName}.`;
      errors.push({
        severity: 'warn',
        summary: `Cannot delete ${entityName}:`,
        detail: detail,
        sticky: true
      });
    });//{severity:'warn', summary: 'Warn', detail: 'Message Content'}
  } else if (err.status === 500) {
    errors.push({
      severity: 'error',
      summary: `Cannot delete ${entityName}:`,
      detail: err.error.msg,
      // sticky: true
    });
  }
  return errors;
}




/**
 * Parses the error response into a format that can be sent to the toast mechanism
 * @param response the error response from the server
 * @returns {Message[]} list of errors in the format that can be sent to
 */
export function parseApiErrorsToToast(response: HttpErrorResponse | any): Message[] {
  let errors: Message[] = [];
  if (response.error) {
    if (response.error.msg) {
      errors.push({
        severity: 'error',
        summary: 'Failed',
        detail: response.error.msg
      });
    } else {
      if (response.error.errors) {
        for (var key in response.error.errors) {
          for (var i = 0; i < response.error.errors[key].length; i++) {
            errors.push({
              severity: 'error',
              summary: 'Failed',
              detail: response.error.errors[key][i]
            });
          }
        }
      } else {
        errors.push({
          severity: 'error',
          summary: response.error.title,
          detail: response.error.detail
        });
      }
    }
  }
  return errors;
}
