import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { MessageHubChannelModel } from 'src/app/models/config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { duplicateValidator, duplicateValidatorForChannel } from '../../../../validators/duplicateValidator';
import { nameValidator } from '../../../../validators/nameValidator';
import { duplicateArrayValidator } from 'src/app/pages/protected/validators/duplicateArrayValidator';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-message-hub-channel',
  templateUrl: './add-message-hub-channel.component.html',
  styleUrls: ['./add-message-hub-channel.component.scss']
})
export class AddMessageHubChannelComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() isAdding = false;
  @Input() isViewing = false;
  @Input() isReviewer = false;
  @Input() msgHubChannel?: any;
  @Input() msgHubChannelBase?: any;
  @Input() allMsgHubChannels: any;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;

  deleteChannelEndpointModalOpen: boolean = false;
  selectedChannelEndpoint: string = '';
  selectedChannelEndpointIdx: number = 0;

  TTL_DEFAULT=604800;
  TTL_REGEX="^[0-9]*$"

  EXCLUDE_PRIVATE_IPS_REGEX = '(?!(?:10\\.)|(?:172\\.(?:1[6-9])|(?:2[0-9])|(?:3[0-1]))|(?:192\\.168\\.))';
  IP_REGEX = `(?:${this.EXCLUDE_PRIVATE_IPS_REGEX}(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]))`;
  HOSTNAME_REGEX = '(?:[A-Z0-9.-]+\\.[A-Z]{2,})';
  EMAIL_ADDRESS_REGEX = `(?:[A-Z0-9._%+-]+@(?:${this.HOSTNAME_REGEX}|(?:\\[${this.IP_REGEX}\\])))`;
  EMAIL_ENDPOINT_REGEX = `(?:mailto:${this.EMAIL_ADDRESS_REGEX}(?:,\\s?${this.EMAIL_ADDRESS_REGEX})*)`;
  SERVICE_ENDPOINT_REGEX = `(?:(?:https?|tcp):\\/\\/(?:${this.IP_REGEX}|${this.HOSTNAME_REGEX})(?::\\d+)?(?:\\/.*)?)`;
  ENDPOINT_VALIDATION = new RegExp(`^${this.EMAIL_ENDPOINT_REGEX}|${this.SERVICE_ENDPOINT_REGEX}$`, 'i');

  // ENDPOINT_REGEX="^(mailto:|(http(s?)|tcp):\\/\\/)(([-a-zA-Z0-9@:%.,_\\+~#=]{2,256}\\.[a-z]{2,6})|((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\\.(?!$)|$)){3})([-a-zA-Z0-9@:%_\\+.,~#?&\\/\\/=]*)(:[0-9]*)?$"

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  channelId: any = null;
  channelName: string = '';
  ttl: any = '';
  channelEndpoints = [];

  // Form
  msgHubChannelForm = this.fb.group({
    id: [{ value: null, disabled: false }],
    channelId: [{ value: null, disabled: false },  [Validators.required,
      duplicateValidatorForChannel(() => this.allMsgHubChannels )]],
    channelName: [{ value: null, disabled: false },
      [Validators.minLength(5), Validators.maxLength(20), nameValidator()]],
    ttl: [{ value: this.TTL_DEFAULT, disabled: false },
      [Validators.required, Validators.pattern(this.TTL_REGEX)]],
    endpoints: this.fb.array([]),
    endpoint: [{ value: null, disabled: false },
      [Validators.pattern(this.ENDPOINT_VALIDATION)]],
  });

  get endpoints() {
    return this.msgHubChannelForm.get("endpoints") as FormArray;
  }

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit() isAdding = ", this.isAdding);
    this.load();
  }

  async load() {
    console.log("Loading AddMessageHubChannelComponent");
    try {
      this.isLoading = true;
    } finally {
      this.isLoading = false;
    }
  }

  async onShow() {
    console.log("onShow() AddMessageHubChannelComponent msgHubChannel = ", this.msgHubChannel);
    console.log("onShow() AddMessageHubChannelComponent msgHubChannelBase = ", this.msgHubChannelBase);
    console.log("onShow() - allMsgHubChannels = ", this.allMsgHubChannels);
    console.log("onShow() isViewing = ", this.isViewing);
    console.log("onShow() isAdding = ", this.isAdding);
    console.log("onShow() isReviewer = ", this.isReviewer);


    if (this.isAdding) { // If adding new field, just clear it
      this.clearFields();

      this.msgHubChannelForm.controls.ttl.setValue(this.TTL_DEFAULT)
    }

    // Editing
    if (!this.isViewing && !this.isAdding) {
      this.msgHubChannelForm.get('channelId')?.disable();
    }

    if (this.isViewing || this.isReviewer) {
      this.disableControlsForView();

      // Load 
      // if (this.msgHubChannelBase) {
      //   this.channelId = this.msgHubChannelBase.channelId;
      //   this.channelName = this.msgHubChannelBase.name;
      //   this.ttl = this.msgHubChannelBase.ttl;
      //   this.channelEndpoints = this.msgHubChannelBase.endpoints ;
      // }
    }

    if (this.msgHubChannel) {
      for (const e of this.msgHubChannel.value.endpoints) {
        this.endpoints.push(new FormControl({name: e}));
      }
      this.msgHubChannelForm.patchValue({
        id: this.msgHubChannel.value.id,
        channelId: this.msgHubChannel.value.channelId,
        channelName: this.msgHubChannel.value.channelName,
        ttl: this.msgHubChannel.value.ttl,
      });
    }
  }

  async onCloseDialog() {

    this.msgHubChannelForm.reset();
    this.endpoints.clear();
    this.enableControlsForView();
    // Only clear if you are editing
     console.log("onCloseDialog() AddMessageHubChannelComponent = ", this.msgHubChannel);
  }

  clearFields() {
    console.log("clearFields() AddMessageHubChannelComponent msgHubChannel= ", this.msgHubChannel);
    console.log("clearFields() AddMessageHubChannelComponent msgHubChannelBase= ", this.msgHubChannelBase);
    console.log("clearFields() isViewing = ", this.isViewing);
    console.log("clearFields() isAdding = ", this.isAdding);

    this.msgHubChannelForm.reset();

    this.channelId = '';
    this.channelName = '';
    this.ttl = '';
    this.channelEndpoints = [];

    console.log("clearFields() AddMessageHubChannelComponent = ", this.msgHubChannel);
  }

  onAddEndpoints()  {
    const endpoint = this.msgHubChannelForm.get('endpoint');
    if (endpoint) {
      const fc = new FormControl({name: endpoint.value} , [Validators.required, duplicateArrayValidator(this.endpoints)])
      this.endpoints.push(fc);
      endpoint?.reset();

      // IS THIS THE BEST WAY?
      this.endpoints.markAsDirty();
    }
  }

  async onDeleteEndpoint(endpoint: string, index: number) {
    console.log(`onDeleteEndpoint()`);
    this.selectedChannelEndpoint = endpoint;
    this.selectedChannelEndpointIdx = index;
    this.deleteChannelEndpointModalOpen = true;
  }

  async onChannelEndpointDeleted(idx: number) {
    this.deleteChannelEndpointModalOpen = false;

    if (idx > -1) {
      this.endpoints.removeAt(idx);
      this.endpoints.markAsDirty();
    }
  }

  async save() {
    console.log("save() - this.msgHubChannelForm.value = ", this.msgHubChannelForm.value);
    console.log("save() - this.endpoints.value = ", this.endpoints.value);
    console.log(`save() - this.msgHubChannel = `, this.msgHubChannel);
    try {
      this.blockUI?.start();

      let messageChannel;
      // EDIT
      if (!this.isViewing && !this.isAdding) {
        messageChannel = {
          id: this.msgHubChannel.value.id,
          channelId: this.msgHubChannel.value.channelId,
          channelName: this.msgHubChannelForm.value.channelName,
          ttl: this.msgHubChannelForm.value.ttl,
          endpoints: this.endpoints.value.map((e:any) => e.name),
        }
      } else {  
        // ADD
        messageChannel = {
          channelId: this.msgHubChannelForm.value.channelId,
          channelName: this.msgHubChannelForm.value.channelName,
          ttl: this.msgHubChannelForm.value.ttl,
          endpoints: this.endpoints.value.map((e:any) => e.name),
        }
      }

      console.log("save() - messageChannel = ", messageChannel);

      this.onSave.emit(messageChannel);
      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.clearFields();
      this.blockUI?.stop();
    }
  }

  enableControlsForView() {
    console.log(`In enableControlsForView()`);
    this.msgHubChannelForm.get('channelId')?.enable();
    this.msgHubChannelForm.get('channelName')?.enable();
    this.msgHubChannelForm.get('ttl')?.enable();
    this.msgHubChannelForm.get('endpoints')?.enable();
    this.msgHubChannelForm.get('endpoint')?.enable();
  }

  disableControlsForView() {
    console.log(`In disableControlsForView()`);
    this.msgHubChannelForm.get('channelId')?.disable();
    this.msgHubChannelForm.get('channelName')?.disable();
    this.msgHubChannelForm.get('ttl')?.disable();
    this.msgHubChannelForm.get('endpoints')?.disable();
    this.msgHubChannelForm.get('endpoint')?.disable();
  }
}