import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomerModel } from 'src/app/models/customer.model';
import { ResellerModel } from 'src/app/models/reseller.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { OrgModel } from 'src/app/models/org.model';
import { Location } from '@angular/common';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class FirmwareEditComponent implements OnInit {
  isEditing: boolean = false;
  firmwareId?: string;
  firmware?: any;

  form = this.fb.group({
    version: '',
    versionName: '',
    releaseDate: '',
    fileUrl: '',
    releaseNotes: '',
    algorithm: '',
    checkSum: '',
  });
  algorithms = [
    { label: 'SHA256', value: 'SHA256' }
  ];

  @BlockUI()
  blockUI: NgBlockUI | undefined;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService) { }

    ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.isEditing = false;
        if (params.firmware_id) {
          this.isEditing = true;
          this.firmwareId = params.firmware_id;
        }

        if (this.isEditing) {
          this.breadcrumbService.setItems([{ label: 'Firmware', routerLink: '/firmware' }, { label: 'Edit' }])
        } else {
          this.breadcrumbService.setItems([{ label: 'Firmware', routerLink: '/firmware' }, { label: 'Add' }])
        }
        this.load(this.firmwareId);
      });
    }

    async load(id?: string) {
      try {
        this.blockUI?.start();

        if (id) {
          this.firmware = await this.api.get(`/firmware/${id}`)
          this.form.patchValue(this.firmware);
        }
        if (this.isEditing) {
          // this.users.push(this.user);
          // console.log(`loading this user= ${this.users[0].name}`);
        }
      } finally {
        this.blockUI?.stop();
      }
    }

    async onSubmit() {
      try {
        this.blockUI?.start();

        const firmware = {
          version: this.form.value.version,
          versionName: this.form.value.versionName,
          releaseDate: this.form.value.releaseDate,
          fileUrl: this.form.value.fileUrl,
          releaseNotes: this.form.value.releaseNotes,
          algorithm: this.form.value.algorithm,
          checkSum: this.form.value.checkSum
        };

        if (this.isEditing) {
          await this.api.put(`/firmware/${this.firmwareId}`, firmware);
        } else {
          await this.api.post('/firmware', firmware);
        }

        this.router.navigate(["/firmware"]);

      } catch (error) {
        this.messageService.addAll(parseApiErrorsToToast(error));
      } finally {
        this.blockUI?.stop();
      }
    }

    async onCancel(event: Event) {
      console.log("onCancel - this.location = ", this.location);
      this.location.back();
    }

    async onClose() {
      this.router.navigate(["/firmware"]);
    }
}
