import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DeviceModel, /*DeviceLastContact*/ } from 'src/app/models/device.model';
import { OrgModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';
import { DeviceSignalModel } from 'src/app/models/device-signal.model';
import { ApiService } from 'src/app/services/api.service';

import { AppBreadcrumbService } from '../../app.breadcrumb.service';

const colorGood = "#14C7B4";
const colorWarn = "#FCC700";
const colorBad = "#F0142F";
const colorPrimary = "#0058FF";
const colorUnknown = "#848d96";

const colorExcellent = "#0058FF";
const colorFair = "#FCC700"
const colorPoor = "#F0142F"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // Count Box Data

  // All Orgs
  totalDeviceCount: number = 0;

  // VAM and Reseller Only
  unassignedDeviceCount: number = 0;
  assignedDeviceCount: number = 0;
  customerDeviceCount: number = 0;
  resellerDeviceCount: number = 0;

  userCount: number = 0;
  customerCount: number = 0;
  resellerCount: number = 0;
  customerUserCount: number = 0;
  resellerUserCount: number = 0;

  isVam: boolean = false;
  isReseller: boolean = false;
  isCustomer: boolean = false;

  // Devices Chart
  deviceCountChartData: any;
  // deviceCountOptionsData: any;

  // Devices by Health
  // devicesByLastContactedChartData: any;
  // devicesRecent: number = 0;
  // devicesDelayed: number = 0;
  // devicesUnreachable: number = 0;
  // devicesNever: number = 0;

  // dataUsageByInterfaceChartData: any;

  // Data Usage
  dataUsageByDeviceTypeChartData: any;
  dataUsageByDeviceTypeOptions: any;

  // Signal Related Stuff
  totalDevicesWithCellStatus: any[] = [];
  totalDevicesWithSatStatus: any[] = [];
  signalStrengthCellularData: any;
  signalStrengthSatelliteData: any;

  cellExcellentCount: number = 0;
  cellGoodCount: number = 0;
  cellFairCount: number = 0;
  cellVeryPoorSignalCount: number = 0;

  satExcellentCount: number = 0;
  satGoodCount: number = 0;
  satFairCount: number = 0;
  satVeryPoorSignalCount: number = 0;

  totalDevicesWIthCellStatus: number = 0;

  // Devices
  devices: DeviceModel[] = []

  @BlockUI()
  blockUI: NgBlockUI | undefined;

  constructor(private breadcrumbService: AppBreadcrumbService, private api: ApiService) { }

  async load(id?: string) {
    try {
      this.blockUI?.start();

      let currentUserRsp: UserModel | undefined;
      let usersRsp: UserModel[] = [];
      let devicesRsp: DeviceModel[] = [];
      let customerOrgRsp: OrgModel[] = [];
      let resellerOrgRsp: OrgModel[] = [];
      let signalStrengthRsp: DeviceSignalModel[] = [];

      await Promise.all ([
        this.api.get<UserModel>('/users/current'),
        this.api.get<{"users": UserModel[]}>('/users'),
        this.api.get<{"devices": DeviceModel[]}>('/devices'),
        this.api.get<{"orgs": OrgModel[]}>('/customers'),
        this.api.get<{"orgs": OrgModel[]}>('/resellers'),
        this.api.get(`/dashboard/signal-strength`)
      ]).then( response => {
        currentUserRsp = response[0];
        usersRsp = response[1].users;
        devicesRsp = response[2].devices;
        customerOrgRsp = response[3].orgs;
        resellerOrgRsp = response[4].orgs;
        signalStrengthRsp = response[5] as DeviceSignalModel[];
      });
      
      const thisOrgId = currentUserRsp?.organization?.id;

      usersRsp.forEach(u => {
        if (u.organization.id == thisOrgId) {
          this.userCount++;
        } else {
          this.customerUserCount++;
        }
      });

      devicesRsp.map(d => {
        this.devices.push(d);
        this.totalDeviceCount++;

        if (d.orgId && d.orgId != thisOrgId) {
          this.assignedDeviceCount++;

          if (d.org.type == 'Reseller') {
            this.resellerDeviceCount++;
          } else {
            this.customerDeviceCount++;
          }
        }
      });

      signalStrengthRsp.map ((entry: DeviceSignalModel) => {
        // Only handle devices that have a cellularSignal bar
        if (entry.cellularSignal?.bars) {
          const index = this.totalDevicesWithCellStatus.findIndex((item: any)=> item.deviceId === entry.deviceId)
          if (index === -1) {
            // Add if not found
            this.totalDevicesWithCellStatus.push( {deviceId : entry.deviceId, cellValue: entry.cellularSignal?.bars, count : 1});
          } else {
            // Update counters
            this.totalDevicesWithCellStatus[index].count++;
            this.totalDevicesWithCellStatus[index].cellValue += entry.cellularSignal?.bars ;
          }
        }
        if (entry.satelliteSignal?.bars) {
          const index = this.totalDevicesWithSatStatus.findIndex((item: any)=> item.deviceId === entry.deviceId)
          if (index === -1) {
            // Add if not found
            this.totalDevicesWithSatStatus.push( {deviceId : entry.deviceId, satValue: entry.satelliteSignal?.bars, count : 1});
          } else {
            // Update counters
            this.totalDevicesWithSatStatus[index].count++;
            this.totalDevicesWithSatStatus[index].satValue += entry.cellularSignal?.bars ;
          }
        }
      });

      let cellSignalSummary = this.calculateCellularStrength(this.totalDevicesWithCellStatus);
      let satSignalSummary =  this.calculateSatelliteStrength(this.totalDevicesWithSatStatus);


      console.log(`SUMMARY ${JSON.stringify(cellSignalSummary)} ${JSON.stringify(satSignalSummary)}` );

      this.signalStrengthCellularData = {
        labels: ['Excellent', 'Good', 'Fair to Poor', 'Very Poor'],
        datasets: [
          {
            data: [this.cellExcellentCount, this.cellGoodCount, this.cellFairCount, this.cellVeryPoorSignalCount],
            backgroundColor: [
              colorExcellent,
              colorGood,
              colorFair,
              colorPoor
            ],
            hoverBackgroundColor: [
              colorExcellent,
              colorGood,
              colorFair,
              colorPoor
            ]
          }]
      };

      this.signalStrengthSatelliteData = {
        labels: ['Excellent', 'Good', 'Fair to Poor', 'Very Poor'],
        datasets: [
          {
            data: [this.satExcellentCount, this.satGoodCount, this.satFairCount, this.satVeryPoorSignalCount],
            backgroundColor: [
              colorExcellent,
              colorGood,
              colorFair,
              colorPoor
            ],
            hoverBackgroundColor: [
              colorExcellent,
              colorGood,
              colorFair,
              colorPoor
            ]
          }]
      };


      this.resellerCount = resellerOrgRsp.length;
      this.customerCount = customerOrgRsp.length;

      // Make sure we don't count the current user's org in Reseller and Customer org counts.
      if (currentUserRsp?.organization?.type == "Reseller") {
        this.resellerCount -= 1;
      } else if (currentUserRsp?.organization?.type == "Customer") {
        this.customerCount -= 1;
      }

    } finally {
      this.blockUI?.stop();
    }
  }

  calculateSatelliteStrength(totalDevicesWithStatus: any[]) {

    totalDevicesWithStatus.map ((device, index) => {
      if (device.satValue / device.count >= 4.5) {
        device.signalStrength = 'EXCELLENT'
        totalDevicesWithStatus[index] = device;
        this.satExcellentCount++;
      } else if (device.satValue / device.count < 4.5 &&  device.satValue / device.count >= 3) {
        device.signalStrength = 'GOOD'
        this.satGoodCount++;
        totalDevicesWithStatus[index] = device;
      } else if (device.satValue / device.count < 3 &&  device.satValue / device.count > 1) {
        device.signalStrength = 'FAIR'
        this.satFairCount++;
        totalDevicesWithStatus[index] = device;
      } else {
        device.signalStrength = 'VERY_POOR'
        this.satVeryPoorSignalCount++;
        totalDevicesWithStatus[index] = device;
      }

    });

    return { excellent : this.satExcellentCount, good : this.satGoodCount, fair : this.satFairCount, noSignal: this.satVeryPoorSignalCount }
  }

  calculateCellularStrength(totalCellDevicesWithStatus: any[]) {

    totalCellDevicesWithStatus.map ((device, index) => {
      if (device.cellValue / device.count >= 4.5) {
        device.signalStrength = 'EXCELLENT'
        totalCellDevicesWithStatus[index] = device;
        this.cellExcellentCount++;
      } else if (device.cellValue / device.count < 4.5 &&  device.cellValue / device.count >= 3) {
        device.signalStrength = 'GOOD'
        this.cellGoodCount++;
        totalCellDevicesWithStatus[index] = device;
      } else if (device.cellValue / device.count < 3 &&  device.cellValue / device.count >= 1) {
        device.signalStrength = 'FAIR'
        this.cellFairCount++;
        totalCellDevicesWithStatus[index] = device;
      } else {
        device.signalStrength = 'VERY_POOR'
        this.cellVeryPoorSignalCount++;
        totalCellDevicesWithStatus[index] = device;
      }

    });

    return { excellent : this.cellExcellentCount, good : this.cellGoodCount, fair : this.cellFairCount, noSignal: this.cellVeryPoorSignalCount }
  }

  ngOnInit(): void {

    this.breadcrumbService.setItems([{ label: 'Dashboard' }]);

    const userStr: any = localStorage.getItem('currentUser');
    const currentUser: UserModel = JSON.parse(userStr);

    this.isVam = currentUser.organization.type === "VAM";
    this.isReseller = currentUser.organization.type === "Reseller";
    this.isCustomer = currentUser.organization.type === "Customer";

    this.load();

  }


}
