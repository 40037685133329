export enum JobTypesEnum {
  HARD_FACTORY_RESET ,
  FACTORY_RESET,
  REBOOT,
  FIRMWARE_UPDATE,
  TEST
}

export const JobTypes = [
  { id: 0, label: 'HARD_FACTORY_RESET', value: 'HARD_FACTORY_RESET' },
  { id: 1, label: 'FACTORY_RESET', value: 'FACTORY_RESET' },
  { id: 2, label: 'REBOOT', value: 'REBOOT' },
  { id: 3, label: 'FIRMWARE_UPDATE', value: 'FIRMWARE_UPDATE' },
  { id: 4, label: 'TEST', value: 'TEST' },
];

export enum JobStatusEnum {
  Scheduled = 'Scheduled',
  OK = 'Ok',
  Error = 'Error',
  Expired = 'Expired',
  UserCancelled = 'User Cancelled',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Queued = 'Queued'
}

export const JobStatus = [
    { id: 0, label: 'Ok', value: 'Ok' },
    { id: 1, label: 'Error', value: 'Error' },
    { id: 2, label: 'Expired', value: 'Expired' },
    { id: 3, label: 'User Cancelled', value: 'User Cancelled' },
    { id: 4, label: 'Pending', value: 'Pending' },
    { id: 5, label: 'Queued', value: 'Queued' }
  ];


export interface JobDefinition {
  id?: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  config: string;
}

export interface JobsModel {
  id: string;

  createdAt: Date;
  updatedAt: Date;

  messageId: string;
  retries: number;
  status: string;
  reason: string;
  jobNumber: number;
  jobDefinitionId: string;
  deviceId: string;
  orgId: string;
  jobDefinitions: JobDefinition;
}