import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { VoiceConfigModel, SipExtension, PotsExtension } from 'src/app/models/voice-config.model';
import { Table } from 'primeng/table';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import {
  ConfigUtils,
  BASE_VALUE,
  DEFAULT_SIP_EXTENSIONS,
  DEFAULT_POTS_EXTENSIONS }
from 'src/app/util/config-utils';
import { DeviceModel } from 'src/app/models/device.model';
import { nameValidator } from '../../../validators/nameValidator';
import { UserModel } from 'src/app/models/user.model';

const diff = require('deep-diff');

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class VConfigEditComponent implements OnInit {

  currentUser?: UserModel;
  isLoading: boolean = false;
  isEditing: boolean = false;
  isViewing: boolean = false;
  isReviewer: boolean = false;

  vconfig_id?: string;
  vConfig?: VoiceConfigModel;
  vConfig_groupId: string = '';
  sipExtensions: SipExtension[] = [];
  potsExtensions: PotsExtension[] = [];
  sipExtensionsReported: SipExtension[] = [];
  potsExtensionsReported: PotsExtension[] = [];

  editSipExtensionModalOpen = false;
  editPotsExtensionModalOpen = false;

  updateShadowModalOpen = false;
  deleteVConfigModalOpen = false;

  vConfigs: VoiceConfigModel[] = [];

  devicesToUpdate: DeviceModel[] = [];

  configHistoryModalOpen = false;
  voiceConfigs: VoiceConfigModel[] = [];

  sipExtensionSelected?: SipExtension;
  potsExtensionSelected?: PotsExtension;
  sipExtensionBaseSelected?: SipExtension;
  potsExtensionBaseSelected?: PotsExtension;
  shadows: any;
  index: any = 1;

  // Interpolation
  configUtils = ConfigUtils;
  baseValue = BASE_VALUE;
  regionBase = '';

  // Work-around
  extensionChanged: boolean = false;


  form = this.fb.group({
    name: [{ value: null, disabled: false },
      [Validators.required, Validators.minLength(5), Validators.maxLength(20), nameValidator()]],
    version: [{ value: null, disabled: true }],
    region: [{ value: null, disabled: false }],
  });

  outboundLines = {
    "line_1": "Line 1",
    "line_2": "Line 2",
    "any": "Any Available"
  }

  outboundLinesArr = [
    { name: "Line 1", value: "line_1"},
    { name: "Line 2", value: "line_2"},
    { name: "Any Available", value: "any"}
  ]

  regions = [
    { name: "USA / Canada", value: "us", isDefault: true },
    { name: "Argentina", value: "ar" },
    { name: "Australia", value: "au" },
    { name: "Austria", value: "at" },
    { name: "Belgium", value: "be" },
    { name: "Brazil", value: "br" },
    { name: "Bulgaria", value: "bg" },
    { name: "Chile", value: "cl" },
    { name: "China", value: "cn" },
    { name: "Czech", value: "cz" },
    { name: "Denmark", value: "dk" },
    { name: "Finland", value: "fi" },
    { name: "France", value: "fr" },
    { name: "Germany", value: "de" },
    { name: "Greece", value: "gr" },
    { name: "Hungary", value: "hu" },
    { name: "India", value: "in" },
    { name: "Israel", value: "il" },
    { name: "Italy", value: "it" },
    { name: "Japan", value: "jp" },
    { name: "Macao", value: "mo" },
    { name: "Malaysia", value: "my" },
    { name: "Mexico", value: "mx" },
    { name: "Netherlands", value: "nl" },
    { name: "New Zealand", value: "nz" },
    { name: "Norway", value: "no" },
    { name: "Philippines", value: "ph" },
    { name: "Poland", value: "pl" },
    { name: "Portugal", value: "pt" },
    { name: "Russia", value: "ru" },
    { name: "Singapore", value: "sg" },
    { name: "South Africa", value: "za" },
    { name: "Spain", value: "es" },
    { name: "Sweden", value: "se"},
    { name: "Switzerland", value: "ch" },
    { name: "Taiwan", value: "tw" },
    { name: "Thailand", value: "th" },
    { name: "UAE", value: "ae" },
    { name: "UK / Ireland", value: "uk" }
  ];

  @BlockUI()
  blockUI: NgBlockUI | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isReviewer = this.currentUser?.role === 'Reviewer';
    }
  }

  ngOnInit(): void {
    console.log("ngOnInit()");

    this.route.params.subscribe(params => {
      this.isEditing = false;
      console.log("params = ", params);
      console.log("params.config_id = ", params.config_id);

      if (params.index) {
        this.index = params.index;
      }
      console.log("index = ", this.index);

      if (params.device_id) {
        this.isViewing = true;
        this.onLoadByDevice(params.device_id);
      } else if (params.config_id) {
        if (!this.isReviewer) {
          this.isEditing = true;
        }
        this.vconfig_id = params.config_id;
        this.onLoad(this.vconfig_id);
      } else {
        this.onLoad();
      }

      if (this.isReviewer) {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'Voice Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'View Voice Profile' }
        ]);
      } else if (this.isViewing) {
        this.breadcrumbService.setItems([
          { label: 'Devices', routerLink: '/devices' },
          { label: 'Edit Device', routerLink: 'devices'},
          { label: 'View Voice Profile' }
        ]);
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'Voice Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'Edit' }
        ]);
      } else {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'Voice Profiles', routerLink: ['/configs', { 'index' : 1 }] },
          { label: 'Add' }
        ]);
      }
    });
  }

  async onLoadByDevice(device_id: string) {
    try {
      this.blockUI?.start();

      if (this.isViewing) {
        this.disableControlsForView();
      }

      // Get things
      const thingRsp: any = await this.api.get(`/things/${device_id}`);
      console.log("Config Edit: thingRsp = ", thingRsp);

      this.shadows = thingRsp.shadows;

      if (this.shadows) {

        this.form.patchValue({
          name: this.shadows.voice_meta.state.desired.name,
          version: this.shadows.voice_meta.state.desired.version,
          region: this.shadows.pbx.state.reported.tonezone
        })

        // Display SipExtensions and/or PotsExtensions
        const { sipData, potsData } = await this.parseAndCompareShadows(this.shadows);
        if (sipData) {
          this.sipExtensions = sipData;
        }
        if (potsData) {
          this.potsExtensions = potsData;
        }

        // Set the Base Value
        this.regionBase = this.shadows.pbx.state.desired.tonezone;
      }
    } finally {
      this.blockUI?.stop();
    }
  }

  async onLoad(vconfig_id?: string) {
    console.log("load - vconfig_id = ", vconfig_id);
    try {
      this.blockUI?.start();
      if (vconfig_id) {

        if (this.isReviewer) {
          this.disableControlsForView();
        }

        this.vConfig = await this.api.get<VoiceConfigModel>(`/voice-configs/${vconfig_id}`);
        this.vConfig_groupId = this.vConfig?.groupId;

        if (this.vConfig?.config) {
          const pbx: any = this.vConfig.config.shadows?.pbx;
          this.sipExtensions = pbx.sip_extensions;
          this.potsExtensions = pbx.dahdi_extensions;

          this.vConfigs.push(this.vConfig);

          const newVC = {
            name: this.vConfig.name,
            version: this.vConfig.version,
            region: pbx.tonezone
          }
          this.form.patchValue(newVC);
        }
      } else {
        // Load SIP Extensions and POTS Extensions
        this.sipExtensions = DEFAULT_SIP_EXTENSIONS;
        this.potsExtensions = DEFAULT_POTS_EXTENSIONS;
        this.form.patchValue({
          region: "us"
        })

      }
    } finally {
      this.blockUI?.stop();
    }
  }

  checkAndMarkDifference(desired: any, reported: any) {
    console.log("In checkAndMarkDifference() - desired = ", desired);
    console.log("In checkAndMarkDifference() - reported = ", reported);

    let changes = diff(desired, reported);
    if (changes) {
      // if they don't match, status = "modified"
      desired['status'] = 'Modified';
    } else {
      // if they match, status = "synched"
      desired['status'] = 'Synched';
    }
    console.log("Exiting checkAndMarkDifference() - desired = ", desired);
    return desired;
  }

  compareShadowData(pbxDesired: any, pbxReported: any) {
    let diffedData: any = [];

    if (pbxDesired?.length > 0 && pbxReported?.length > 0) {
      for (let i=0; i<pbxDesired.length; i++) {
        let found: boolean = false;
        for (let j=0; j<pbxReported.length && !found; j++) {
          if (pbxDesired[i].ext === pbxReported[j].ext) {
            found=true;
            const desiredSipRes = this.checkAndMarkDifference(pbxDesired[i], pbxReported[j]);
            diffedData.push(desiredSipRes);

            // Remove found from the array
            pbxReported.splice(j, 1);
            --j;
          }
        }
        // Only found in Desired side -> mark it as 'pending'
        if (!found) {
          const desired = pbxDesired[i];
          desired['status'] = 'Pending';
          diffedData.push(desired);
        }
      }

      // Only found is Reported side -> mark it as 'device-only'
      if (pbxReported?.length > 0) {
        const devOnlyRes = pbxReported.map((pbxReported: any) => ({ ...pbxReported, ['status']: 'Device-Only'}));
        diffedData = [...diffedData, ...devOnlyRes];
      }

    } else if (pbxDesired?.length > 0 && pbxReported?.length <= 0) {
      // status = "pending"
      const pendingRes = pbxDesired.map((pbxDesired: any) => ({ ...pbxDesired, ['status']: 'Pending'}));
      diffedData = [...pendingRes];
    } else if (pbxDesired?.length <= 0 && pbxReported?.length > 0) {
      // status = "device-only"
      const devOnlyRes = pbxReported.map((pbxReported: any) => ({ ...pbxReported, ['status']: 'Device-Only'}));
      diffedData = [...devOnlyRes];
    }

    return diffedData;
  }

  async parseAndCompareShadows(shadows: any) {
    console.log("Entering parseShadows(): shadows = ", shadows);
    let sipData: any = [];
    let potsData: any = [];
    if (shadows) {
      if (Object.keys(shadows).length === 0
            && shadows.constructor === Object) {
        console.log("In parseShadows(): No shadows");
      } else {
        // Compare SIP Shadow data
        let desiredSipExt = [...shadows.pbx?.state.desired?.sip_extensions];
        let reportedSipExt = [...shadows.pbx?.state.reported?.sip_extensions];
        sipData = this.compareShadowData(desiredSipExt, reportedSipExt);

        // Compare Dahdi Shadow data
        let desiredPotsExt = [...shadows.pbx?.state.desired?.dahdi_extensions];
        let reportedPotsExt = [...shadows.pbx?.state.reported?.dahdi_extensions];
        potsData = this.compareShadowData(desiredPotsExt, reportedPotsExt);
      }
    }
    console.log("Exiting parseShadows(): sipData = ", sipData);
    console.log("Exiting parseShadows(): potsData = ", potsData);
    return {sipData, potsData};
  }

  getSipExtensionReported(extension: string) {
    let sipExtRpt = null;
    const rptSipExtensions = this.shadows?.pbx?.state.reported?.sip_extensions;
    for (let rptSip of rptSipExtensions) {
      if (rptSip.ext === extension) {
        sipExtRpt = rptSip;
        break
      }
    }
    return sipExtRpt;
  }

  getPotsExtensionReported(extension: string) {
    let potsExtRpt;
    const rptPotsExtensions = this.shadows?.pbx?.state.reported?.dahdi_extensions;
    for (let rptPots of rptPotsExtensions) {
      if (rptPots.ext === extension) {
        potsExtRpt = rptPots;
        break
      }
    }
    return potsExtRpt;
  }

  async showSipDialog(sipExtension: any) {
    if (this.isViewing) {

      if (sipExtension.status === 'Pending') {
        this.sipExtensionSelected = undefined;
        this.sipExtensionBaseSelected = sipExtension;

      } else if (sipExtension.status === 'Device-Only') {
        this.sipExtensionSelected = sipExtension;
        this.sipExtensionBaseSelected = undefined;

      } else if (sipExtension.status === 'Modified') {

        const ext = sipExtension.ext;
        const sipExtReported = this.getSipExtensionReported(ext);

        // Passing reported
        this.sipExtensionSelected = sipExtReported;

        // Passing desired
        this.sipExtensionBaseSelected = sipExtension;

      } else if (sipExtension.status === 'Synched') {

        // Cheating a bit; No need to get the reported since they match
        this.sipExtensionSelected = sipExtension;
        this.sipExtensionBaseSelected = sipExtension;

      } else {
        // Log error
        console.log(`Error: Unknown status = [ ${sipExtension.status}`);
      }

    } else {
      this.sipExtensionSelected = sipExtension;
    }
    this.editSipExtensionModalOpen = true;
  }

  async showPotsDialog(potsExtension: any) {
    if (this.isViewing) {
      if (potsExtension.status === 'Pending') {
        this.potsExtensionSelected = undefined;
        this.potsExtensionBaseSelected = potsExtension;

      } else if (potsExtension.status === 'Device-Only') {
        this.potsExtensionSelected = potsExtension;
        this.potsExtensionBaseSelected = undefined;

      } else if (potsExtension.status === 'Modified') {

        const ext = potsExtension.ext;
        const potsExtReported = this.getPotsExtensionReported(ext);

        // Passing reported
        this.potsExtensionSelected = potsExtReported;

        // Passing desired
        this.potsExtensionBaseSelected = potsExtension;

      } else if (potsExtension.status === 'Synched') {

        // Cheating a bit; No need to get the reported since they match
        this.potsExtensionSelected = potsExtension;
        this.potsExtensionBaseSelected = potsExtension;

      } else {
        // Log error
        console.log(`Error: Unknown status = [ ${potsExtension.status}`);
      }

    } else {
      this.potsExtensionSelected = potsExtension;
    }
    this.editPotsExtensionModalOpen = true;
  }

  async saveSipExtension(sipExtension: SipExtension) {
    let matched = false;

    // Enable Save button
    this.extensionChanged = true;

    // Figure out if this SIP Extension already exists
    // Therefore, it would be an EDIT
    for(let se of this.sipExtensions) {
      if (se.ext == sipExtension.ext) {
        matched = true;
        // Update object
        se.description = sipExtension.description;
        se.inbound.line_1 = sipExtension.inbound.line_1;
        se.inbound.line_2 = sipExtension.inbound.line_2;
        se.outbound = sipExtension.outbound;
        se.username = sipExtension.username;
        se.password = sipExtension.password;
        break;
      }
    }
    // If not matched, it's a brand new SIP extension
    if (!matched) {
      this.sipExtensions.push(sipExtension);
    }
  }

  async savePotsExtension(potsExtension: PotsExtension) {
    let matched = false;

    // Enable Save button
    this.extensionChanged = true;

    // Figure out if this POTS Extension already exists
    // Therefore, it would be an EDIT
    for(let pe of this.potsExtensions) {
      if (pe.ext == potsExtension.ext) {
        matched = true;
        // Update object
        pe.description = potsExtension.description;
        pe.inbound.line_1 = potsExtension.inbound.line_1;
        pe.inbound.line_2 = potsExtension.inbound.line_2;
        pe.outbound = potsExtension.outbound;
        break;
      }
    }
    // If not matched, it's a brand new POTS extension
    if (!matched) {
      this.potsExtensions.push(potsExtension);
    }
  }

  async onSubmit() {
    try {
      this.blockUI?.start();
      console.log("onSubmit() - this.vConfig_groupId = ", this.vConfig_groupId);

      if (this.vConfig_groupId) {
        // Retrieve list of devices associated with this config
        this.devicesToUpdate = await this.api.get<DeviceModel[]>(`/devices/voice-config/group/${this.vConfig_groupId}`);
        if (this.devicesToUpdate?.length > 0) {
          // Display dialog
          this.updateShadowModalOpen = true;
        } else {
          this.submitData();
        }
      } else {
        this.submitData();
      }


    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: "Error occurred during save"
      });
    } finally {
      this.blockUI?.stop();
    }
  }

  async submitData() {
    try {
      this.blockUI?.start();
      console.log("submitData() - this.form.value = ", this.form.value);

      let vConfig: any = {
        name: this.form.value.name,
        config: {
          shadows: {
            voice_meta: {
              name: this.form.value.name,
              version: this.form.value.version
            },
            pbx: {
              dahdi_extensions: this.potsExtensions,
              sip_extensions: this.sipExtensions,
              tonezone: this.form.value.region
            }
          }
        }
      }

      let updatedVoiceConfig: VoiceConfigModel;
      if (this.vconfig_id) {
        // It creates a new one with a new version
        vConfig.groupId = this.vConfig_groupId;

        updatedVoiceConfig = await this.api.post<VoiceConfigModel>(`/voice-configs/`, vConfig);
      } else {
        console.log("Sending vConfig = ", vConfig);
        updatedVoiceConfig = await this.api.post<VoiceConfigModel>(`/voice-configs`, vConfig);
        this.vconfig_id = updatedVoiceConfig.id;
      }

      // Sync the Voice Config changes with ALL devices
      for (let device of this.devicesToUpdate) {

        // Update the voiceConfigId
        const deviceUpdate = {
          name: device.name,
          assetTag: device.assetTag,
          tags: device.tags,
          attributes: device.attributes,
          deviceConfigId: device.deviceConfigId,
          voiceConfigId: updatedVoiceConfig.id,
          orgId: device.orgId
        }

        // Update the device with the new Voice Config
        await this.api.put(`/devices/${device.id}`, deviceUpdate);

        // Update AWS Things        
        await this.api.put(`/things/${device.id}/shadows`,  updatedVoiceConfig.config.shadows);
      }

      this.router.navigate(['/configs', { 'index' : 1 }]);

    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: "Error occurred during save"
      });
    } finally {
      this.blockUI?.stop();
    }
  }

  //#region table handling
  clear(table: Table) {
    table.clear();
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onCancel(event: Event) {
    this.router.navigate(['/configs', { 'index' : 1 }]);
  }

  back() {
    this.router.navigate(['/configs', { 'index' : 1 }]);
  }

  async onClose() {
    this.updateShadowModalOpen = false;
    this.deleteVConfigModalOpen = false;
    this.router.navigate(["/configs", { 'index' : 1 }]);
  }

  async displayHistoryDlg() {
    const rspConfig = await this.api.get< { "voiceConfigs": VoiceConfigModel[] } >(`/voice-configs/group/${this.vConfig_groupId}`);
    this.voiceConfigs = rspConfig?.voiceConfigs;

    // Display History Modal
    this.configHistoryModalOpen = true;
  }

  disableControlsForView() {
    this.form.get('name')?.disable();
    this.form.get('region')?.disable();
  }
}
