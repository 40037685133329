import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";

import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

var moment = require('moment');

@Component({
  selector: "app-grafana",
  templateUrl: "./grafana.component.html",
  styleUrls: ["./grafana.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GrafanaComponent,
      multi: true
    }
  ]
})
export class GrafanaComponent {

  @Input() parentGroup?: FormGroup;
  @Input() url: string = '';
  @Input() label = "";
  @Input() formDropDownFieldId = "";
  @Input() formControlDropdownName?: any;
  @Input() formOptions?: any;
  @Input() formControlFromName?: any;
  @Input() dateFromName = "";
  @Input() formControlToName?: any;
  @Input() dateToName = "";
  @Input() applyClickFunction: any;
  @Input() applyClickLabel = "";

  @Output() fromValue: EventEmitter<any> = new EventEmitter();
  @Output() toValue: EventEmitter<any> = new EventEmitter();

  timezone = moment(new Date()).format('Z');

  applyClickAction() {
    this.fromValue.emit(this.dateFromName);
    this.toValue.emit(this.dateToName);
    this.applyClickFunction();
  }

  constructor(public sanitizer: DomSanitizer) {
  }

  // getSrc() {
  //   console.log(`********************* getSrc() **************************`)
  //   let safeUrl: any;
  //   if (this.url) {
  //     safeUrl = this.url //this.sanitizer.sanitize(SecurityContext.RESOURCE_URL,this.url);
  //   } else {
  //     safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
  //   }
  //   console.log(`getSrc() - safeUrl = ${safeUrl}`)
  //   return safeUrl;
  // }

  checkIfDisabled() {
    if (this.parentGroup?.get(this.formControlDropdownName)?.value !== 'custom') {
      return false;
    } else if (!this.dateFromName || !this.dateToName) {
      return true;
    } else {
      return false;
    }
  }

}
