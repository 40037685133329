import { Utils } from '../util/utils';

  export const BASE_VALUE = "Profile Value: ";
  export const DEFAULT_SIP_EXTENSIONS = [
    {
      "description": "",
      "ext": "510",
      "inbound": {
        "line_1": true,
        "line_2": true
      },
      "outbound" : "any",
      "password": "510",
      "username": "510"
    },
    {
      "description": "",
      "ext": "511",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "511",
      "username": "511"
    },
    {
      "description": "",
      "ext": "512",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "512",
      "username": "512"
    },
    {
      "description": "",
      "ext": "513",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "513",
      "username": "513"
    },
    {
      "description": "",
      "ext": "514",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "514",
      "username": "514"
    },
    {
      "description": "",
      "ext": "515",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "515",
      "username": "515"
    },
    {
      "description": "",
      "ext": "516",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "516",
      "username": "516"
    },
    {
      "description": "",
      "ext": "517",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "517",
      "username": "517"
    },
    {
      "description": "",
      "ext": "518",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "518",
      "username": "518"
    },
    {
      "description": "",
      "ext": "519",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound" : "any",
      "password": "519",
      "username": "519"
    },
    {
      "description": "",
      "ext": "520",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "520",
      "username": "520"
    },
    {
      "description": "",
      "ext": "521",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "521",
      "username": "521"
    },
    {
      "description": "",
      "ext": "522",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "522",
      "username": "522"
    },
    {
      "description": "",
      "ext": "523",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "523",
      "username": "523"
    },
    {
      "description": "",
      "ext": "524",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "524",
      "username": "524"
    },
    {
      "description": "",
      "ext": "525",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "525",
      "username": "525"
    },
    {
      "description": "",
      "ext": "526",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "526",
      "username": "526"
    },
    {
      "description": "",
      "ext": "527",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "527",
      "username": "527"
    },
    {
      "description": "",
      "ext": "528",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "528",
      "username": "528"
    },
    {
      "description": "",
      "ext": "529",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound" : "any",
      "password": "529",
      "username": "529"
    }
  ];

  export const DEFAULT_POTS_EXTENSIONS = [
    {
      "description": "POTS #1",
      "ext": "500",
      "inbound": {
        "line_1": true,
        "line_2": false
      },
      "outbound": "line_1",
      "username": "1"
    },
    {
      "description": "POTS #2",
      "ext": "501",
      "inbound": {
        "line_1": false,
        "line_2": true
      },
      "outbound": "line_2",
      "username": "2"
    }
  ];

export class ConfigUtils {

  static displayConversion(options: any, val: any) {
    const routing = Utils.altFind(options, function(x : any) {
      return x.value === val;
    });
    return routing?.name;
  }

  static displayEnabledDisabled(val: boolean) {
    if (val)
      return "ENABLED";
    else
      return "DISABLED";
  }

  static displayNotSetIfEmpty(val: any) {
    if (val)
      return val;
    else
      return "NOT SET";
  }

  // NOT USED!!
  static clearDeviceShadows(configName: string) {
    const config: any = {
      name: configName,
      config: {
        name: configName,
        shadows: {
          device_meta: {
            name: "",
            version: "",
          },
          ui: {
            max_daily_usage: "",
            device_password: "",
          },
          dhcp: {
            dns_server: [],
            enabled: "",
            lease_time: "",
            local_domain: "",
            local_ipv4: "",
            local_ipv4_prefix: "",
            range_end: "",
            range_start: ""
          },
          hostapd: {
            band_5ghz: "",
            channel: "",
            country: "",
            enabled: "",
            ssid: "",
            wpa_passphrase: ""
          },
          net_mgr: {},
          platform: {
            cellular_enabled: "",
            iridium_sfx_data_enabled: "",
            iridium_sfx_enabled: "",
            serial_enabled: "",
            wifi_enabled: ""
          },
          boot: {
            product_name_full: "",
            product_model: "",
            product_name: "",
            platform_type: "",
            manufacturer: "",
            serial_number: "",
            platform_licenses: [],
            product_variant: []
          },
          sims: {
            sims:[]
          },
          snmp: {
            community_ro: "snmp_ro",  // hardcoded
            community_rw: "snmp_rw",  // hardcoded
            enabled: false
          },
          remote_management:  {
            call_history_enabled: false,
            call_history_interval: "",
            jobs_host: "",
            jobs_port: 4445,
            position_reports_enabled: false,
            position_reports_interval: "",
            remote_management_enabled: false,
            remote_management_interval: "",
            shadow_interval: "",
            shadow_version_host: "",
            shadow_version_port: 4444,
            status_report_interval: "",
            status_reports_enabled: false,
            update_check_url: "",
 
            sos_report_interval: ""
          },
          system: {
            asset_tag: ""
          },
          firewall_dnat: {
            dnat: []
          }
        }
      }
    }
    return config;
  }

  static clearVoiceShadows(configName: string) {
    const vConfig: any = {
      name: configName,
      config: {
        name: configName,
        shadows: {
          voice_meta: {
            name: "",
            version: ""
          },
          pbx: {
            dahdi_extensions: {
              dahdi_extension_groups: []
            },
            sip_extensions: {
              sip_extension_groups: []
            },
            region: ""
          }
        }
      }
    }
    return vConfig;
  }

  static createMessageHubData(stdTopicId: number, msgHubChannel: any) {

    // Set up Management Hub shadow data
    let msgChannels =  msgHubChannel.controls.map((ch: any) => ch.value)
    console.log(`msgChannels = `, msgChannels)

    let shadowChannelMgmt: any = {
      standard_topic_id: stdTopicId || 215,  // Default to prod
      channels: []
    }
    let dbChannelData = []
    for (let i=0; i<msgChannels.length; i++ ) {
      let shadowChannel:any = {
        channel_id: msgChannels[i].channelId,
        name: msgChannels[i].channelName,
        ttl: msgChannels[i].ttl
      }
      let dbChannel: any = {
        channelId: msgChannels[i].channelId,
        name: msgChannels[i].channelName,
        ttl: msgChannels[i].ttl,
        endpoints: msgChannels[i].endpoints
      }
      shadowChannelMgmt.channels.push(shadowChannel)
      dbChannelData.push(dbChannel)
    }
    console.log(`shadowChannelMgmt = `, shadowChannelMgmt)
    console.log(`dbChannelData = `, shadowChannelMgmt)
    return { shadowChannelMgmt, dbChannelData}
  }

}
