import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { ApiService } from 'src/app/services/api.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private hasTriggered = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    onAuthUIStateChange((authState, authData) => {

      if (authState == AuthState.SignedIn) {

        // Go get current/user and store
        this.api.get<UserModel>('/users/current')
          .then((user) => {
            console.log(`In onAuthUIStateChange() - user = ${JSON.stringify(user)}`);
            if (user) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));

              this.hasTriggered = true;
              this.zone.run(() => {
                this.router.navigate(['dashboard']);
              });
            }
          })
          .catch((err) => {
            console.log(`ERROR: ${err}`);
          });

      }
    });
  }
}
