import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { OrgModel } from 'src/app/models/org.model';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { TreeNode } from 'primeng/api';
import { UserModel } from 'src/app/models/user.model';
import { AppTopBarComponent } from '../../app.topbar.component';

@Component({
  providers: [AppTopBarComponent],
  selector: 'app-my-org',
  templateUrl: './my-org.component.html',
  styleUrls: ['./my-org.component.scss']
})
export class MyOrgComponent implements OnInit {
  isEditing: boolean = false;
  orgId?: string;

  isLoading: boolean = false;
  parentOrg?: OrgModel;
  myOrg?: OrgModel;
  orgTree: TreeNode[] = [];
  selectedOrg?: TreeNode;

  currentUser?: UserModel;
  
  isVam: boolean = false;
  isReseller: boolean = false;
  isAdmin: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;
  isAccountManager: boolean = false;

  form = this.fb.group({
    name: [''],
    accountOwner: [''],
    adminContact: [''],
    supportContact: [''],
    logo: [{ value: null, disabled: true }],
    parentName: '',
    parentAdminContact: '',
    parentSupportContact: '',
    // displayName: [''],
    // headerColor: [''],
    // bodyHeaderColor: [''],
    // bodyContentColor: [''],
    // footerColor: [''],
    // bodyColor: [''],
  });

  constructor(
    private topBarComp: AppTopBarComponent,
    private fb: FormBuilder,
    private api: ApiService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReseller = this.currentUser?.organization.type === 'Reseller';
      this.isAdmin = this.currentUser?.role === 'Admin';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
      this.isAccountManager = (this.currentUser?.role === "AccountManager" ||
                               this.currentUser?.role === "User");
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'My Org' }]);

    this.form.get('logo')?.valueChanges.subscribe(selectedValue => {
      console.log('logo changed')
      console.log("logo = ", selectedValue);
      console.log("this.form = ", this.form);
    })

    this.form.valueChanges.subscribe(selectedValue => {
      console.log('form value changed')
      console.log(selectedValue)
    })

    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      this.myOrg = await this.api.get<OrgModel>('/orgs/current');

      if (this.isReviewer || this.isOps || this.isAccountManager) {
        this.disableControls();

        // Hides Save button
      }

      if (this.myOrg) {
        this.orgId = this.myOrg.id;
        this.form.patchValue(this.myOrg);
      }

      if (this.myOrg.type !== "VAM") {

        this.parentOrg = await this.api.get<OrgModel>('/orgs/parent');
        if (this.parentOrg) {
          this.form.patchValue({
            parentName: this.parentOrg.name,
            parentAdminContact: this.parentOrg.adminContact,
            parentSupportContact: this.parentOrg.supportContact
          });
        }
      }

      const orgResponse = await this.api.get<{ orgs: [OrgModel] }>('/orgs');
      if (orgResponse) {

        // Extend TreeNode type with parentId property
        type TreeNodeish = TreeNode & { parentId?: string }

        let stack: TreeNodeish[] = [];
        const memo: { [key: string]: TreeNodeish } = {};

        // Add parent
        if (this.parentOrg) {
          orgResponse.orgs.push(this.parentOrg);
        }

        // Memo-ize the orgs by id and add to the stack list
        orgResponse.orgs.forEach(o => {
          const currentNode = { label: `${o.name} (${o.type})`, children: [], parentId: o.parentOrg?.id };
          if (o.id === this.parentOrg?.id) {
            currentNode.label = o.name;
          }
          memo[o.id] = currentNode;
          stack.push(currentNode);
        });

        let root = {};

        stack.forEach(n => {
          if (n.parentId == null || memo[n.parentId] == null) {
            // this the root:
            root = n;
          } else {
            const match = memo[n.parentId];

            if (typeof (match.children) == 'undefined') {
              match.children = [];
            }
            match.children.push(n);
          }
        });

        this.orgTree = [root];
      }

    } finally {
      this.isLoading = false;
    }
  }

  async onSubmit() {
    try {
      console.log("my-org onSubmit() - form = " + JSON.stringify(this.form.value));

      const org = {
        id: this.orgId,
        name: this.form.value.name,
        accountOwner: this.form.value.accountOwner,
        adminContact: this.form.value.adminContact,
        supportContact: this.form.value.supportContact,
        displayName: this.form.value.displayName,
        // logo: this.form.value.logo,
        // headerColor: this.form.value.headerColor,
        // bodyHeaderColor: this.form.value.bodyHeaderColor,
        // bodyContentColor: this.form.value.bodyContentColor,
        // footerColor: this.form.value.footerColor,
        // bodyColor: this.form.value.bodyColor,
        parentId: this.myOrg?.parentId,
        type: this.myOrg?.type
      }

      console.log(`my-org onSubmit() - org = ${JSON.stringify(org)}`);
      await this.api.put(`/orgs/${this.orgId}`, org);

      const logo = this.form.get('logo');
      console.log(`my-org onSubmit() - logo = ${logo}`);
      if (logo) {
        this.submitImage();
      }

    } catch (error) {
      console.debug(`ERROR: ${JSON.stringify(error)}`);
    }
    this.form.reset();
    this.load();

  }

  async submitImage() {
    // Create form data to post logo
    const formData = new FormData();
    const file = this.form.get('logo')?.value;
    console.log("file = ", file);

    if (file) {
      // Store form name as "file" with file data
      formData.append("upload", file, file.name);
      await this.api.post(`/orgs/${this.orgId}/uploads`, formData);

      // Reload the AppTopBarComponent to reload the image
      this.topBarComp.reloadComponent();
    }
    return;
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }

  disableControls() {
    this.form.get('name')?.disable();
    this.form.get('accountOwner')?.disable();
    this.form.get('adminContact')?.disable();
    this.form.get('supportContact')?.disable();
  }
}
