import { CallLogModel } from "../models/call-log.model";
import { ConfigModel } from "../models/config.model";
import { DeviceCheckInModel } from "../models/device-check-in.model";
import { DeviceTypeModel } from "../models/device-type.model";
import { FirmwareModel } from "../models/firmware.model";
import { JobsModel } from "../models/job.model";
import { OrgModel } from "../models/org.model";
import { VoiceConfigModel } from "../models/voice-config.model";
import { ApiService } from "../services/api.service";
import { environment } from 'src/environments/environment';

export class FetchUtils {

  static async getDeviceTypes(api: ApiService) {
    let deviceTypes: DeviceTypeModel[] = [];
    try {
      const res = await api.get<{"deviceTypes": DeviceTypeModel[]}>('/device-types');
      deviceTypes = res.deviceTypes;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return deviceTypes;
  }

  static async getLatestConfigs(api: ApiService) {
    let configs: ConfigModel[] = [];
    try {
      const res = await api.get<{"configs": ConfigModel[]}>('/configs/latest');
      configs = res.configs;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return configs;
  }

  static async getLatestVoiceConfigs(api: ApiService) {
    let voiceConfigs: VoiceConfigModel[] = [];
    try {
      const res = await api.get<{"voiceConfigs": VoiceConfigModel[]}>('/voice-configs/latest');
      voiceConfigs = res.voiceConfigs;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return voiceConfigs;
  }

  static async getThingsById(api: ApiService, id: string, query: string, ) {
    let things: any = [];
    try {
      things = await api.get(`/things/${id}${query}`);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return things;
  }

  static async getLatestFirmwareVersion(api: ApiService) {
    let lastestFirmwareVersion;
    try {
      lastestFirmwareVersion = await api.get(`/firmware/version`);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return lastestFirmwareVersion;
  }

  static async getLatestFirmwareVersionById(api: ApiService, id: string) {
    let lastestFirmwareVersion;
    try {
      lastestFirmwareVersion = await api.get(`/firmware/version/${id}`);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return lastestFirmwareVersion;
  }

  static async getDeviceById(api: ApiService, id: string) {
    let device;
    try {
      device = await api.get(`/devices/${id}`);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return device;
  }

  static async getDeviceCheckInsById(api: ApiService, id: string) {
    let deviceCheckIns: DeviceCheckInModel[] = [];
    try {
      const res = await api.get<{"checkIns": DeviceCheckInModel[]}>(`/device-check-in/${id}`);
      deviceCheckIns = res.checkIns;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return deviceCheckIns;
  }

  static async getCallLogsById(api: ApiService, id: string) {
    let callLogs: CallLogModel[] = [];
    try {
      const limit = environment.callLogs.limit || 300;
      // const res = await api.get<{"calls": CallLogModel[]}>(`/callLogs/${id}/300`);
      const res = await api.get<{"calls": CallLogModel[]}>(`/callLogs/fromDB/${id}/${limit}`);
      callLogs = res.calls;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return callLogs;
  }

  static async getJobsById(api: ApiService, id: string) {
    let jobs: JobsModel[] = [];
    try {
      const res = await api.get<{"jobs": JobsModel[]}>(`/jobs/${id}`);
      jobs = res.jobs;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return jobs;
  }

  static async getOrgs(api: ApiService) {
    let orgs: OrgModel[] = [];
    try {
      const res = await api.get<{"orgs": OrgModel[]}>(`/orgs`);
      orgs = res.orgs;
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    return orgs;
  }
}