import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { DeviceModel } from 'src/app/models/device.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { OrgModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-device-assign-org',
  templateUrl: './assign-org.component.html',
  styleUrls: ['./assign-org.component.scss']
})
export class DeviceAssignOrgComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() devices: DeviceModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  currentUser?: UserModel;
  isVam: boolean = false;
  orgs: OrgModel[] = [];
  orgSelected?: OrgModel;

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
    }
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const rsp = await this.api.get<{ "orgs": OrgModel[] }>('/orgs');
      this.orgs = rsp.orgs;
    } finally {
      this.isLoading = false;
    }
  }

  async assign() {
    if (this.orgSelected) {
      try {
        this.blockUI?.start();

        await Promise.all(this.devices.map(async device => {
          await this.api.put(`/devices/${device.id}`, {
            orgId: this.orgSelected?.id
          });
        }));

        this.orgSelected = undefined;

        this.onClose.emit();

        this.visible = false;
        this.visibleChange.emit(false);
      } catch (err) {
        this.messageService.addAll(parseApiErrorsToToast(err));
      } finally {
        this.blockUI?.stop();
      }
    }
  }

  async unAssign() {
    try {
      this.blockUI?.start();

      await Promise.all(this.devices.map(async device => {
        await this.api.put(`/devices/${device.id}`, {
          orgId: null
        });
      }));

      this.onClose.emit();

      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.orgSelected = undefined;
  }
}
