import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Observable } from 'rxjs';

/**
 * Implements auth guard based on this
 * @see https://github.com/mlabieniec/AngularMaterialPWA/blob/master/partTwo/src/app/auth/unauth.guard.ts
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => {
        this._router.navigate(['login']);
        return false;
      });
  }
}
