import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { OrgNotesModel } from 'src/app/models/org.model';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {
  @Input() orgId: string = '';
  @Input() notes: OrgNotesModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  notesSelected?: OrgNotesModel[] = [];
  addNoteModalOpen = false;
  deleteNoteModalOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

  async onCloseDialog() {
    console.log(`onCloseDialog()`);
    this.notesSelected = [];
    this.onClose.emit();
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }
}
