<div class="container">
  <h1 *ngIf="isViewing || isReviewer">Device Profile</h1>
  <h1 *ngIf="!isViewing && isEditing">Edit Device Profile</h1>
  <h1 *ngIf="!isViewing && !isEditing && !isReviewer">Add Device Profile</h1>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="nameField">Profile Name</label>
        <input id="nameField" type="text" formControlName="name" pInputText required/>
        <div *ngIf="!isViewing && form.get('name')?.errors?.required">
          Profile Name is required.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.nameValidator">
          Profile Name is invalid.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.minlength">
          Profile Name must be at least 5 characters long.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.maxlength">
          Profile Name must be at most 25 characters long.
        </div>
      </div>
      <div *ngIf="isViewing || isEditing || isReviewer" class="p-field p-sm-6">
        <label for="versionField">Version</label>
        <input id="versionField" type="text" formControlName="version" pInputText />
        <a *ngIf="!isViewing" (click)="displayHistoryDlg()">History</a>
      </div>
    </div>
    <p-tabView>
      <p-tabPanel header="General Settings">
        <!-- Satellite -->
        <div class="card">
          <h4>Satellite</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-6">
              <div class="p-grid">
                <div class="p-col">
                  <p for="satelliteEnabledField">Satellite Enabled</p>
                  <p-inputSwitch id="satelliteEnabledField" formControlName="satelliteEnabled" (onChange)="handleSatChange($event)"></p-inputSwitch>
                </div>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('satelliteEnabled').value != satelliteEnabledBase }"
                id="satelliteEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(satelliteEnabledBase)}}
              </small>
            </div>

            <div class="p-field p-sm-6">
              <div class="p-grid">
                <div class="p-col">
                  <p for="dataEnabledField">Data Enabled</p>
                  <p-inputSwitch id="dataEnabledField" formControlName="dataEnabled"></p-inputSwitch>
                </div>
              </div>
              <small
              *ngIf="isViewing"
              [ngClass]="{'item-differ': form.get('dataEnabled').value != dataEnabledBase }"
              id="dataEnabledBaseField">
                {{baseValue}} {{configUtils.displayEnabledDisabled(dataEnabledBase)}}
            </small>
            </div>
          </div>
        </div>

        <!-- WiFi and Cellular -->
        <div class="p-grid" [style]="{'margin-bottom': '2rem'}">
          <div class="p-col-6">
            <div class="card" [style]="{'height': '100%'}">
            <div class="p-d-flex">
              <h4>Wi-Fi</h4>
              <div class="p-input-icon-left p-ml-auto">
                <div class="p-d-flex p-flex-column">
                  <p-inputSwitch id="wifiEnabledField" formControlName="wifiEnabled" (onChange)="handleWifiChange($event)"></p-inputSwitch>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('wifiEnabled').value != wifiEnabledBase }"
                    id="wifiEnabledBaseField">
                      {{baseValue}} {{configUtils.displayEnabledDisabled(wifiEnabledBase)}}
                  </small>
                </div>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-sm-12">
                <label for="ssidField">SSID</label>
                <input id="ssidField" type="text" formControlName="ssid" pInputText/>
                <div *ngIf="!isViewing && form.get('ssid')?.errors?.minlength">
                  SSID must be at least 2 characters long.
                </div>
                <div *ngIf="!isViewing && form.get('ssid')?.errors?.maxlength">
                  SSID must be at most 31 characters long.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('ssid')?.value != ssidBase }"
                  id="ssidBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(ssidBase)}}
                </small>
              </div>
              <div class="p-field p-sm-12">
                <label for="modeField">Mode</label>
                <p-dropdown
                  id="modeField"
                  [options]="modeOptions"
                  formControlName="mode"
                  placeholder="Select Mode"
                  optionLabel="name"
                  optionValue="value"
                  (onChange)="handleModeChange($event)"
                  appendTo="body">
                </p-dropdown>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('mode').value != modeBase }"
                  id="modeBaseField">
                    {{baseValue}} {{configUtils.displayConversion(modeOptions, modeBase)}}
                </small>
              </div>
              <div class="p-field p-sm-12" *ngIf="mode5Ghz">
                <label for="countryField">Country</label>
                <p-dropdown
                  id="countryField"
                  [options]="countryOptions"
                  formControlName="country"
                  placeholder="Select Country"
                  optionLabel="name"
                  optionValue="value"
                  (onChange)="handleModeChange($event)"
                  appendTo="body">
                </p-dropdown>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('country').value != countryBase}"
                  id="countryBaseField">
                    {{baseValue}} {{configUtils.displayConversion(countryOptions, countryBase)}}
                </small>
              </div>
              <!-- Convert to dropdown -->
              <div class="p-field p-sm-12">
                <label for="channelField">Channel</label>
                <p-dropdown
                  id="channelField"
                  [options]="channelOptions"
                  formControlName="channel"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body">
                </p-dropdown>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('channel').value != channelBase }"
                  id="channelBaseField">
                    {{baseValue}} {{configUtils.displayConversion(channelOptions, channelBase)}}
                </small>
              </div>
              <!-- Convert to dropdown -->
              <div class="p-field p-sm-12">
                <label for="securityField">Security</label>
                <p-dropdown
                  id="securityField"
                  [options]="securityOptions"
                  formControlName="security"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body">
                </p-dropdown>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('security').value != securityBase }"
                  id="securityBaseField">
                    {{baseValue}} {{configUtils.displayConversion(securityOptions, securityBase)}}
                </small>
              </div>
              <div class="p-field p-sm-12">
                <label for="wifiPasswordField">Password</label>
                <input id="wifiPasswordField" type="text" formControlName="wifiPassword" pInputText />
                <div *ngIf="!isViewing && form.get('wifiPassword')?.errors?.minlength">
                  Name must be at least 8 characters long.
                </div>
                <div *ngIf="!isViewing && form.get('wifiPassword')?.errors?.maxlength">
                  Name must be at most 63 characters long.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('wifiPassword')?.value != wifiPasswordBase }"
                  id="passwordBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(wifiPasswordBase)}}
                </small>
              </div>
            </div>
            </div>
          </div>

          <div class="p-col-6">
            <div class="card" [style]="{'height': '100%'}">
            <div class="p-d-flex">
              <h4>Cellular</h4>
              <div class="p-input-icon-left p-ml-auto">
                <div class="p-d-flex p-flex-column">
                  <p-inputSwitch id="cellularEnabledField" formControlName="cellularEnabled" (onChange)="handleCellChange($event)"></p-inputSwitch>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('cellularEnabled').value != cellularEnabledBase }"
                    id="cellularEnabledBaseField">
                      {{baseValue}} {{configUtils.displayEnabledDisabled(cellularEnabledBase)}}
                  </small>
                </div>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-sm-12">
                <label for="apnField">APN</label>
                <input id="apnField" type="text" formControlName="apn" pInputText />
                <div *ngIf="!isViewing && form.get('apn')?.errors?.maxlength">
                  Name must be at most 50 characters long.
                </div>
                <div *ngIf="!isViewing && form.get('apn')?.errors?.nameValidator">
                  APN is invalid.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('apn').value != apnBase }"
                  id="apnBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(apnBase)}}
                </small>
              </div>
              <div class="p-field p-sm-12">
                <label for="usernameField">Username</label>
                <input id="usernameField" type="text" formControlName="cellularUsername" pInputText />
                <div *ngIf="!isViewing && form.get('cellularUsername')?.errors?.maxlength">
                  Username must be at most 50 characters long.
                </div>
                <div *ngIf="!isViewing && form.get('cellularUsername')?.errors?.nameValidator">
                  Username is invalid.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('cellularUsername').value != cellularUsernameBase }"
                  id="usernameBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(cellularUsernameBase)}}
                </small>
              </div>
              <div class="p-field p-sm-12">
                <label for="cellularPasswordField">Password</label>
                <input id="cellularPasswordField" type="text" formControlName="cellularPassword" pInputText />
                <div *ngIf="!isViewing && form.get('cellularPassword')?.errors?.maxlength">
                  Password must be at most 50 characters long.
                </div>
                <div *ngIf="!isViewing && form.get('cellularPassword')?.errors?.nameValidator">
                  Password is invalid.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('cellularPassword').value != cellularPasswordBase }"
                  id="passwordBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(cellularPasswordBase)}}
                </small>
              </div>
            </div>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Connection Manager">
        <div class="card">
          <h4>Internet Data Routing</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-12">
              <p class="help-block">
                Select how to prioritize data routing over the satellite and cellular
                connections.
              </p>
              <p-messages [(value)]="wiredMessages" [enableService]="false" [closable]="false"></p-messages>
              <div class="p-grid">
                <div class="p-field p-sm-6">
                  <label for="dataRoutingField">Data Routing</label>
                  <p-dropdown
                    id="dataRoutingField"
                    [options]="selectedDataRoutingOptions"
                    formControlName="dataRouting"
                    optionLabel="name"
                    optionValue="value"
                    appendTo="body">
                  </p-dropdown>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('dataRouting').value != dataRoutingBase}"
                    id="dataRoutingBaseField">
                      {{baseValue}} {{configUtils.displayConversion(dataRoutingOptionsWired, dataRoutingBase)}}
                  </small>
                </div>
                <div class="p-field p-sm-6">
                  <div class="p-grid">
                    <div class="p-col">
                      <p>Wired Connections</p>
                      <p-inputSwitch
                        id="dataRoutingWiredField"
                        onLabel="Y"
                        offLabel="N"
                        formControlName="dataRoutingWired"
                        (onChange)="handleDataRoutingWiredChange($event)">
                      </p-inputSwitch>    
                    </div>
                  </div>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('dataRoutingWired').value != dataRoutingWiredBase }"
                    id="dataRoutingWiredBaseField">
                      {{baseValue}} {{configUtils.displayEnabledDisabled(dataRoutingWiredBase)}}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <h4>Firewall Profile</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-12">
              <p class="help-block">
                SkyLink by default blocks most outside (Internet) network traffic,
                and needs to be told what traffic it should allow. Firewall Profiles
                are sets of predefined rules that allow common network traffic. These
                profiles are an easy way to setup your SkyLink. Select a profile to learn
                more about what traffic it allows. The profile won't be applied until
                you click Save.
              </p>
              <div class="p-grid">
                <div class="p-sm-6">
                  <p-dropdown
                    id="firewallProfileField"
                    [options]="firewallProfileOptions"
                    formControlName="firewallProfile"
                    optionLabel="name"
                    (onChange)="handleFirewallChange($event)"
                    appendTo="body">
                  </p-dropdown>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('firewallProfile')?.value?.value != firewallProfileBase }"
                    id="firewallProfileBaseField">
                      {{baseValue}} {{configUtils.displayConversion(firewallProfileOptions, firewallProfileBase)}}
                  </small>
                </div>
                <div class="p-mt-2">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-question"
                    class="p-button-rounded p-button-info"
                    (click)="this.profileDescriptionsModalOpen = true;">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="p-field p-sm-12">
            <div>
              <h5>Description</h5>
              <div [innerHtml]="description"></div>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Network">
        <div class="card">
          <h4>LAN</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-6">
              <label for="ipAddressField">IP Address</label>
              <input id="ipAddressField" type="text" formControlName="ipAddress" pInputText required/>
              <div *ngIf="!isViewing && form.get('ipAddress')?.errors?.ipv4Validator">
                IP Address is invalid.
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('ipAddress')?.value != ipAddressBase }"
                id="ipAddressBaseField">
                  {{baseValue}} {{configUtils.displayNotSetIfEmpty(ipAddressBase)}}
              </small>
            </div>
            <div class="p-field p-sm-6">
              <label for="networkMaskField">Network Mask</label>
              <p-dropdown
                id="networkMaskField"
                [options]="networkMaskOptions"
                formControlName="networkMask"
                placeholder="Select Network Mask"
                optionLabel="name"
                optionValue="value"
                required
                appendTo="body">
                <ng-template let-item pTemplate="item">
                  {{item.name }} (/{{item.value}})
                </ng-template>
                <ng-template let-item pTemplate="selectedItem">
                  {{item.name }} (/{{item.value}})
                </ng-template>
              </p-dropdown>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('networkMask').value != networkMaskBase }"
                id="ipAddressBaseField">
                  {{baseValue}} {{configUtils.displayConversion(networkMaskOptions, networkMaskBase)}} /({{networkMaskBase}})
              </small>
            </div>
            <div class="p-field p-sm-6">
              <label for="primaryDnsField">Primary DNS</label>
              <input id="primaryDnsField" type="text" formControlName="primaryDns" pInputText />
              <div *ngIf="!isViewing && form.get('primaryDns')?.errors?.ipv4Validator">
                Primary DNS is invalid.
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('primaryDns')?.value != primaryDnsBase }"
                id="primaryDnsBaseField">
                  {{baseValue}} {{configUtils.displayNotSetIfEmpty(primaryDnsBase)}}
              </small>
            </div>
            <div class="p-field p-sm-6">
              <label for="secondaryDnsField">Secondary DNS</label>
              <input id="secondaryDnsField" type="text" formControlName="secondaryDns" pInputText />
              <div *ngIf="!isViewing && form.get('secondaryDns')?.errors?.ipv4Validator">
                Secondary DNS is invalid.
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('secondaryDns').value != secondaryDnsBase }"
                id="secondaryDnsBaseField">
                  {{baseValue}} {{configUtils.displayNotSetIfEmpty(secondaryDnsBase)}}
              </small>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="p-col-12">
            <div class="p-d-flex">
              <h4>DHCP Server</h4>
              <div class="p-input-icon-left p-ml-auto">
                <div class="p-d-flex p-flex-column">
                  <p-inputSwitch id="dhcpEnabledField" formControlName="dhcpEnabled" (onChange)="handleDhcpChange($event)"></p-inputSwitch>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('dhcpEnabled').value != dhcpEnabledBase }"
                    id="dhcpEnabledBaseField">
                      {{baseValue}} {{configUtils.displayEnabledDisabled(dhcpEnabledBase)}}
                  </small>
                </div>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-sm-6">
                <label for="dhcpStartField">DHCP Start Range</label>
                <input id="dhcpStartField" type="text" formControlName="dhcpStart" pInputText />
                <div *ngIf="!isViewing && form.get('dhcpStart')?.errors?.ipv4Validator">
                  DHCP Start Range is invalid.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('dhcpStart').value != dhcpStartBase }"
                  id="dhcpStartBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(dhcpStartBase)}}
                </small>
              </div>
              <div class="p-field p-sm-6">
                <label for="dhcpEndField">DHCP End Range</label>
                <input id="dhcpEndField" type="text" formControlName="dhcpEnd" pInputText />
                <div *ngIf="!isViewing && form.get('dhcpEnd')?.errors?.ipv4Validator">
                  DHCP End Range is invalid.
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('dhcpEnd').value != dhcpEndBase }"
                  id="dhcpEndBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(dhcpEndBase)}}
                </small>
              </div>
              <div class="p-field p-sm-6">
                <label for="leaseField">Lease Validity</label>
                <p-dropdown
                  id="leaseField"
                  [options]="leaseOptions"
                  formControlName="leaseOption"
                  placeholder="Select Lease Validity"
                  optionLabel="name" optionValue="value"
                  appendTo="body">
                </p-dropdown>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': form.get('leaseOption').value != leaseOptionBase }"
                  id="leaseOptionBaseField">
                    {{baseValue}} {{configUtils.displayConversion(leaseOptions, leaseOptionBase)}}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <h4>Static DHCP Leases</h4>
          <div>
            <div class="p-field p-sm-12">
              <p-table
                #dhcpList
                [value]="staticDhcpLeases"
                styleClass="p-datatable-striped"
                dataKey="id"
                [rows]="5"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[5, 10, 20]"
                [paginator]="true"
                [(selection)]="staticDhcpLeasesSelected"
                sortMode="single"
                sortField="name"
                [loading]="isLoading"
                [lazy]="true"
                (onLazyLoad)="loadUserData($event)"
                >
              <ng-template pTemplate="header">
                <tr>
                  <th *ngIf="!isViewing && !isReviewer" style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>
                    MAC Address
                    <p-column type="text" field="macAddress" display="menu"></p-column>
                  </th>
                  <th *ngIf="isViewing">
                    Status
                    <p-columnFilter type="text" field="status" display="menu"></p-columnFilter>
                  </th>
                  <th>
                    IPv4 Address
                    <p-column type="text" field="ipv4Address" display="menu"></p-column>
                  </th>
                  <th>
                    Hostname
                    <p-column type="text" field="hostname" display="menu"></p-column>
                  </th>
                  <th>
                    Tag
                    <p-column type="text" field="name" display="menu"></p-column>
                  </th>
                  <th>
                    Comment
                    <p-column type="text" field="comment" display="menu"></p-column>
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-staticDhcp>
                  <tr>
                    <td *ngIf="!isViewing && !isReviewer">
                      <p-tableCheckbox [value]= "staticDhcp"></p-tableCheckbox>
                    </td>
                    <td>{{ staticDhcp.hwaddr }}</td>
                    <td *ngIf="isViewing">
                      <span>
                        <span *ngIf="staticDhcp.status=='Device-Only'" class="customer-badge status-renewal">{{ staticDhcp.status }}</span>
                        <span *ngIf="staticDhcp.status=='Pending'" class="customer-badge status-proposal">{{ staticDhcp.status }}</span>
                        <span *ngIf="staticDhcp.status=='Synched'" class="customer-badge status-qualified">{{ staticDhcp.status }}</span>
                        <span *ngIf="staticDhcp.status=='Modified'" class="customer-badge status-new">{{ staticDhcp.status }}</span>
                      </span>
                    </td>
                    <td>{{ staticDhcp.ipv4 }}</td>
                    <td>{{ staticDhcp.hostname }}</td>
                    <td>{{ staticDhcp.set_tag }}</td>
                    <td>{{ staticDhcp.comment }}</td>
                    <td *ngIf="!isViewing && !isReviewer">
                      <button
                        pButton
                        pRipple
                        label="Edit"
                        type="button"
                        icon="fas fa-edit"
                        class="p-button"
                        (click)="onEditStaticDhcpLease(staticDhcp)">
                      </button>
                    </td>
                    <td *ngIf="isViewing">
                      <button
                        pButton
                        pRipple
                        label="View"
                        type="button"
                        icon="fas fa-edit"
                        class="p-button"
                        (click)="onEditStaticDhcpLease(staticDhcp)">
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
                <p style="float: right;">
                  <button
                    *ngIf="!isViewing && !isReviewer"
                    pButton
                    label="Add"
                    type="button"
                    icon="fas fa-plus"
                    class="p-button-success p-mr-2"
                    [isViewing]="false"
                    (click)="onAddStaticDhcpLease()">
                  </button>
                  <button
                    *ngIf="!isViewing && !isReviewer"
                    pButton
                    pRipple
                    label="Delete"
                    type="button"
                    icon="fas fa-trash"
                    class="p-button-danger p-mr-2"
                    (click)="onDeleteStaticDhcpLease(staticDhcpLeasesSelected)"
                    [disabled]="!staticDhcpLeasesSelected.length">
                  </button>
                </p>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || (!form.dirty && !dhcpLeasesChanged)">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Firewall">
        <p-tabView>
          <p-tabPanel header="Whitelist">
            <div class="card">
              <h4>Whitelist</h4>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-sm-12">
                  <p class="help-block">
                    Allow traffic for other applications/hosts by adding IP address
                    or host name below. All traffic (incoming and outgoing) from that
                    host will be allowed.
                  </p>
                </div>
                <div class="card warning-rules-block p-d-flex p-ai-center vertical-container p-sm-12">
                  <div class="p-mr-2" style="width:40px">
                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                  </div>
                  <div>
                    <p>
                      The whitelist currently only works with Firewall 
                      Profile 7 (Whitelist). In order for these settings to work, you
                      need to select Profile 7 in the <b>Port Forwarding</b> TAB.
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="p-grid" [style]="{'margin-bottom': '2rem'}">
              <div class="p-col-6 ">
                <div class="card" [style]="{'height': '100%'}">

                  <h4>Host Names</h4>
                  <div class="p-fluid p-formgrid p-grid" formArrayName="whitelistHostnames">
                    <div class="p-field p-sm-8" *ngFor="let wLHostname of whitelistHostnames.controls; let i = index">
                      <div class="p-inputgroup whitelist-inputgroup">
                        <input type="text" pInputText disabled placeholder="{{ wLHostname.value.name }}">
                        <button *ngIf="!isViewing"
                          type="button"
                          pButton
                          pRipple
                          icon="pi pi-trash"
                          styleClass="p-button-warn"
                          (click)="onDeleteWhitelistHostname(wLHostname.value.name, i)">
                        </button>
                        <div class="whitelist-badge" *ngIf="isViewing">
                          <span>
                            <!-- Modify to ENUM HERE ....-->
                            <span *ngIf="wLHostname.value.status=='Device-Only'" class="customer-badge status-renewal">{{ wLHostname.value.status }}</span>
                            <span *ngIf="wLHostname.value.status=='Pending'" class="customer-badge status-proposal">{{ wLHostname.value.status }}</span>
                            <span *ngIf="wLHostname.value.status=='Synched'" class="customer-badge status-qualified">{{ wLHostname.value.status }}</span>
                            <span *ngIf="wLHostname.value.status=='Modified'" class="customer-badge status-new">{{ wLHostname.value.status }}</span>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div *ngIf="!isViewing && wLHostname.errors?.duplicateArrayValidator" class="item-differ">
                          This line is duplicated
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div *ngIf="!isViewing" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-sm-7">
                      <input id="whitelistHostnameField" type="text" formControlName="whitelistHostname" placeholder="Host name" pInputText />
                    </div>
                    <div class="p-field p-sm-5">
                      <button
                        *ngIf="!isViewing && !isReviewer"
                        pButton
                        label="Add Host name"
                        type="button"
                        icon="fas fa-plus"
                        class="p-button-success p-mr-2"
                        [isViewing]="false"
                        (click)="onAddWhitelistHostname()"
                        [disabled]="!this.form.get('whitelistHostname').valid || !this.form.get('whitelistHostname').dirty">
                      </button>
                    </div>
                  </div>

                </div>
              </div>
    
              <div class="p-col-6">
                <div class="card" [style]="{'height': '100%'}">

                    <h4>IP Addresses</h4>
                    <div class="p-fluid p-formgrid p-grid" formArrayName="whitelistIpAddresses">
                      <div class="p-field p-sm-8" *ngFor="let wLIpAddress of whitelistIpAddresses.controls; let i = index">
                        <div class="p-inputgroup whitelist-inputgroup">
                          <input type="text" pInputText disabled placeholder="{{ wLIpAddress.value.name }}">
                          <button
                            *ngIf="!isViewing"
                            type="button"
                            pButton
                            pRipple
                            icon="pi pi-trash"
                            styleClass="p-button-warn"
                            (click)="onDeleteWhitelistIpAddress(wLIpAddress.value.name, i)">
                          </button>
                          <div class="whitelist-badge" *ngIf="isViewing">
                            <span>
                              <span *ngIf="wLIpAddress.value.status=='Device-Only'" class="customer-badge status-renewal">{{ wLIpAddress.value.status }}</span>
                              <span *ngIf="wLIpAddress.value.status=='Pending'" class="customer-badge status-proposal">{{ wLIpAddress.value.status }}</span>
                              <span *ngIf="wLIpAddress.value.status=='Synched'" class="customer-badge status-qualified">{{ wLIpAddress.value.status }}</span>
                              <span *ngIf="wLIpAddress.value.status=='Modified'" class="customer-badge status-new">{{ wLIpAddress.value.status }}</span>
                            </span>
                          </div>
                        </div>
                        <div>
                          <div *ngIf="!isViewing && wLIpAddress.errors?.duplicateArrayValidator" class="item-differ">
                            This line is duplicated
                          </div>
                        </div>
                      </div>
                    </div>
        
                    <div *ngIf="!isViewing" class="p-fluid p-formgrid p-grid">
                      <div class="p-field p-sm-7">
                        <input id="whitelistIpAddressField" type="text" formControlName="whitelistIpAddress" placeholder="IP Address" pInputText />
                      </div>
                      <div class="p-field p-sm-5">
                        <button
                          *ngIf="!isViewing && !isReviewer"
                          pButton
                          label="Add IP Address"
                          type="button"
                          icon="fas fa-plus"
                          class="p-button-success p-mr-2"
                          [isViewing]="false"
                          (click)="onAddWhitelistIpAddress()"
                          [disabled]="!this.form.get('whitelistIpAddress').valid || !this.form.get('whitelistIpAddress').dirty">
                        </button>
                      </div>
                    </div>

                </div>
              </div>
            </div>
            <p>
              <button
                *ngIf="!isViewing && !isReviewer"
                pButton
                label="Save"
                class="p-button-warning p-mr-2"
                icon="fas fa-save"
                type="submit"
                [disabled]="!form.valid || !form.dirty">
              </button>
              <button
                *ngIf="isEditing && !isReviewer"
                pButton
                pRipple
                label="Delete"
                type="button"
                icon="fas fa-trash"
                class="p-button-danger p-mr-2"
                (click)="deleteConfigModalOpen=true">
              </button>
              <button
                pButton
                label="Cancel"
                class="p-button-warning"
                icon="fas fa-undo"
                type="button"
                (click)="onCancel($event)">
              </button>
            </p>
          </p-tabPanel>

          <p-tabPanel header="Port Forwarding">
            <div class="card">
              <h4>Advanced Rules</h4>
              <div>
                <div class="p-field p-sm-12">
                  <p class="help-block">
                    You can define your own set of Firewall rules. Be careful with creating these rules as they could open your
                    network to malicious actors or cause you to lose connectivity. We recommend contacting our support team for
                    assistance with defining the rules.
                  </p>
                  <div class="card warning-rules-block">
                    <p>
                        <b>Note:</b> We only support Port Forwarding.
                    </p>
                  </div>
                  <p-table
                    #rulesList
                    [value]="mergedRulesToDisplayBase"
                    styleClass="p-datatable-striped"
                    dataKey="key"
                    [rows]="5"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[5, 10, 20]"
                    [paginator]="true"
                    [(selection)]="rulesSelected"
                    sortMode="single"
                    sortField="action"
                    [lazy]="true"
                    [loading]="isLoading"
                    (onLazyLoad)="loadUserData($event)"
                    >
                  <ng-template pTemplate="header">
                    <tr>
                      <th *ngIf="!isViewing && !isReviewer" style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </th>
                      <th class="hide">
                        <p-column type="text" field="key" display="menu"></p-column>
                      </th>
                      <th>
                        Type
                        <p-column type="text" field="action" display="menu"></p-column>
                      </th>
                      <th>
                        Source
                        <p-column type="text" field="source" display="menu"></p-column>
                      </th>
                      <th>
                        Destination
                        <p-column type="text" field="destination" display="menu"></p-column>
                      </th>
                      <th>
                        Source Port
                        <p-column type="text" field="sourcePort" display="menu"></p-column>
                      </th>
                      <th>
                        Dest Port
                        <p-column type="text" field="destPort" display="menu"></p-column>
                      </th>
                      <th>
                        Protocol(s)
                        <p-column type="text" field="protocols" display="menu"></p-column>
                      </th>
                      <th>
                        Comments
                        <p-column type="text" field="comments" display="menu"></p-column>
                      </th>
                      <th *ngIf="isViewing">
                        <p-column type="text" field="status" display="menu"></p-column>
                        Status
                      </th>
                      <th>
                        Actions
                      </th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rule>
                      <tr>
                        <td *ngIf="!isViewing && !isReviewer">
                          <p-tableCheckbox [value]= "rule"></p-tableCheckbox>
                        </td>
                        <td class="hide">{{ rule.key }}</td>
                        <td>Forward</td>
                        <td>Internet</td>
                        <td>{{ rule.ipAddress }}</td>
                        <td>{{ rule.sourcePort }}</td>
                        <td>{{ rule.destinationPort }}</td>
                        <td>{{ rule.protocol }}</td>
                        <td>{{ rule.comment }}</td>
                        <td *ngIf="isViewing">
                          <span>
                            <!-- Modify to ENUM HERE ....-->
                            <span *ngIf="rule.status=='Device-Only'" class="customer-badge status-renewal">{{ rule.status }}</span>
                            <span *ngIf="rule.status=='Pending'" class="customer-badge status-proposal">{{ rule.status }}</span>
                            <span *ngIf="rule.status=='Synched'" class="customer-badge status-qualified">{{ rule.status }}</span>
                            <span *ngIf="rule.status=='Modified'" class="customer-badge status-new">{{ rule.status }}</span>
                          </span>
                        </td>
                        <td *ngIf="!isViewing && !isReviewer">
                          <button
                            pButton
                            pRipple
                            label="Edit"
                            type="button"
                            icon="fas fa-edit"
                            class="p-button"
                            (click)="onEditRule(rule)">
                          </button>
                        </td>
                        <td *ngIf="isViewing">
                          <button
                            pButton
                            pRipple
                            label="View"
                            type="button"
                            icon="fas fa-edit"
                            class="p-button"
                            (click)="onEditRule(rule)">
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <p style="float: right;">
                    <button
                      *ngIf="!isViewing && !isReviewer"
                      pButton
                      label="Add"
                      type="button"
                      icon="fas fa-plus"
                      class="p-button-success p-mr-2"
                      [isViewing]="false"
                      (click)="onAddRule()">
                    </button>
                    <button
                      *ngIf="!isViewing && !isReviewer"
                      pButton
                      pRipple
                      label="Delete"
                      type="button"
                      icon="fas fa-trash"
                      class="p-button-danger p-mr-2"
                      (click)="onDeleteRule(rulesSelected)"
                      [disabled]="!rulesSelected.length">
                    </button>
                  </p>
              </div>
            </div>
            </div>
            <p>
              <button
                *ngIf="!isViewing && !isReviewer"
                pButton
                label="Save"
                class="p-button-warning p-mr-2"
                icon="fas fa-save"
                type="submit"
                [disabled]="!form.valid || !form.dirty">
              </button>
              <button
                *ngIf="!isViewing && !isReviewer"
                pButton
                pRipple
                label="Delete"
                type="button"
                icon="fas fa-trash"
                class="p-button-danger p-mr-2"
                (click)="deleteConfigModalOpen=true">
              </button>
              <button
                pButton
                label="Cancel"
                class="p-button-warning"
                icon="fas fa-undo"
                type="button"
                (click)="onCancel($event)"></button>
            </p>
          </p-tabPanel>
        </p-tabView>
      </p-tabPanel>
      <p-tabPanel header="Remote Management">
        <div class="card">
          <h4>Remote Management</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">  
              <p>
                Position Reports Enabled:
              </p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('positionReportsEnabled').value != shadowPositionReportsEnabledBase }"
                id="shadowPositionReportsEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shadowPositionReportsEnabledBase)}}
              </small>
            </div>
            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <div class="p-col">
                  <p-inputSwitch id="positionReportsEnabledField" formControlName="positionReportsEnabled" 
                                (onChange)="handlePositionReportsChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Position Reports Interval:
                  </p>
                  <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': (form.get('positionReportsInterval').value != 'custom' && form.get('positionReportsInterval').value != shadowPositionReportsIntervalBase) || 
                  (form.get('positionReportsInterval').value == 'custom' && (form.get('positionReportsCustomInterval').value + form.get('positionReportsCustomUnits').value) != shadowPositionReportsIntervalBase)}" 
                  id="shadowPositionReportsIntervalBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowPositionReportsIntervalBase)}}
                </small>
                <small *ngIf="!isViewing">
                  <br/>
                </small>
                </div>
              </div>
              <div *ngIf="form.get('positionReportsInterval')?.value == 'custom'" class="p-field  p-d-inline-flex p-flex-row-reverse"> 
                  <input id="positionReportsCustomIntervalField" type="text" formControlName="positionReportsCustomInterval" placeholder="Interval" pInputText/>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="displayNotSetIfEmpty"
                    [options]="intervalOptions"
                    formControlName="positionReportsInterval"
                    optionLabel="name"
                    optionValue="value"
                    (onChange)="handlePositionReportsIntervalChange($event)"
                    appendTo="body"
                    [placeholder]="pleaseSelectPlaceHolder">

                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>

              <div *ngIf="form.get('positionReportsInterval')?.value == 'custom'" class="p-field  p-d-flex p-flex-column"> 
                  <div class="p-col p-d-flex p-flex-column">
                    <p-dropdown
                          id="positionReportsCustomUnits"
                          [options]="reportingCustomUnitsOptions"
                          formControlName="positionReportsCustomUnits"
                          optionLabel="name"
                          optionValue="value"

                          appendTo="body"
                          [placeholder]="pleaseSelectPlaceHolder">
                      </p-dropdown>
                  </div>
              </div>

            </div>
            
            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <p>
                  Remote Management Enabled:
                </p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('remoteManagementEnabled').value != shadowRemoteManagementEnabledBase }"
                id="shadowRemoteManagementEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shadowRemoteManagementEnabledBase)}}
              </small>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-inputSwitch id="remoteManagementEnabledField" formControlName="remoteManagementEnabled" 
                                (onChange)="handleRemoteManagementChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Remote Management Interval:
                  </p>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': (form.get('remoteManagementInterval').value != 'custom' && form.get('remoteManagementInterval').value != shadowRemoteManagementIntervalBase) || 
                    (form.get('remoteManagementInterval').value == 'custom' && (form.get('remoteManagementCustomInterval').value + form.get('remoteManagementCustomUnits').value) != shadowRemoteManagementIntervalBase)}" 
                    id="shadowRemoteManagementIntervalBaseField">
                      {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowRemoteManagementIntervalBase)}}
                  </small>
                  <small *ngIf="!isViewing">
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('remoteManagementInterval')?.value == 'custom'" class="p-field  p-d-inline-flex p-flex-row-reverse"> 
                  <input id="remoteManagementCustomIntervalField" type="text" formControlName="remoteManagementCustomInterval" placeholder="Interval" pInputText/>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="remoteManagementInterval"
                    [options]="intervalOptions"
                    formControlName="remoteManagementInterval"
                    optionLabel="name"
                    optionValue="value"
                    (onChange)="handleRemoteManagementIntervalChange($event)"
                    appendTo="body"
                    [placeholder]="pleaseSelectPlaceHolder">
                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('remoteManagementInterval')?.value == 'custom'" class="p-field  p-d-flex p-flex-column"> 
                <div class="p-col p-d-flex p-flex-column">
                  <p-dropdown
                        id="remoteManagementCustomUnits"
                        [options]="reportingCustomUnitsOptions"
                        formControlName="remoteManagementCustomUnits"
                        optionLabel="name"
                        optionValue="value"

                        appendTo="body"
                        [placeholder]="pleaseSelectPlaceHolder">
                    </p-dropdown>
                </div>
              </div>

            </div>

            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <p>
                  Status Reports Enabled:
                </p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('statusReportsEnabled').value != shadowStatusReportsEnabledBase }"
                id="shadowStatusReportsEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shadowStatusReportsEnabledBase)}}
              </small>
            </div>


            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <div class="p-col">
                  <p-inputSwitch id="statusReportsEnabledField" formControlName="statusReportsEnabled" 
                                (onChange)="handleStatusReportsChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Status Reports Interval:
                  </p>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': (form.get('statusReportsInterval').value != 'custom' && form.get('statusReportsInterval').value != shadowStatusReportsIntervalBase) || 
                    (form.get('statusReportsInterval').value == 'custom' && 
                    (form.get('statusReportsCustomInterval').value + form.get('statusReportsCustomUnits').value) != shadowStatusReportsIntervalBase)}"    
                    
                    id="shadowStatusReportsIntervalBaseField">
                      {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowStatusReportsIntervalBase)}}
                  </small>
                  <small *ngIf="!isViewing">
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('statusReportsInterval')?.value == 'custom'" 
                  class="p-field  p-d-inline-flex p-flex-row-reverse"> 
                  <input id="statusReportsIntervalField"      
                        type="text" formControlName="statusReportsCustomInterval" placeholder="Interval" pInputText/>
              </div>
            </div>
      
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="statusReportsInterval"
                    [options]="intervalOptions"
                    formControlName="statusReportsInterval"
                    optionLabel="name"
                    optionValue="value"
                    (onChange)="handleStatusReportsIntervalChange($event)"
                    appendTo="body"
                    [placeholder]="pleaseSelectPlaceHolder">
                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('statusReportsInterval')?.value == 'custom'" class="p-field  p-d-flex p-flex-column"> 
                <div class="p-col p-d-flex p-flex-column">
                  <p-dropdown
                        id="statusReportsCustomUnits"
                        [options]="reportingCustomUnitsOptions"
                        formControlName="statusReportsCustomUnits"
                        optionLabel="name"
                        optionValue="value"

                        appendTo="body"
                        [placeholder]="pleaseSelectPlaceHolder">
                    </p-dropdown>
                </div>
              </div>
            </div>

            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <p>
                  Call History Enabled:
                </p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('callHistoryEnabled').value != shadowCallHistoryEnabledBase }" id="shadowCallHistoryEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shadowCallHistoryEnabledBase)}}
              </small>
            </div>

            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <div class="p-col">
                  <p-inputSwitch id="callHistoryEnabledField" formControlName="callHistoryEnabled" 
                                (onChange)="handleCallHistoryChange($event)" (onLoad)="handleCallHistoryChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Call History Interval:
                  </p>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': (form.get('callHistoryInterval').value != 'custom' && form.get('callHistoryInterval').value != shadowCallHistoryIntervalBase) || 
                                               (form.get('callHistoryInterval').value == 'custom' && (form.get('callHistoryCustomInterval').value + form.get('callHistoryCustomUnits').value) != shadowCallHistoryIntervalBase)}"         id="shadowCallHistoryIntervalBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowCallHistoryIntervalBase)}}
                  </small>
                  <small *ngIf="!isViewing">
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('callHistoryInterval')?.value == 'custom'" 
                  class="p-field  p-d-inline-flex p-flex-row-reverse"> 
                  <input id="callHistoryIntervalField"      
                        type="text" formControlName="callHistoryCustomInterval" placeholder="Interval" pInputText/>
              </div>
            </div>
            
            
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="callHistoryInterval"
                    [options]="intervalOptions"
                    formControlName="callHistoryInterval"
                    optionLabel="name"
                    optionValue="value"
                    (onChange)="handleCallHistoryIntervalChange($event)"
                    appendTo="body"
                    [placeholder]="pleaseSelectPlaceHolder">
                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('callHistoryInterval')?.value == 'custom'" class="p-field  p-d-flex p-flex-column"> 
                <div class="p-col p-d-flex p-flex-column">
                  <p-dropdown
                        id="callHistoryCustomUnits"
                        [options]="reportingCustomUnitsOptions"
                        formControlName="callHistoryCustomUnits"
                        optionLabel="name"
                        optionValue="value"
                        appendTo="body"
                        [placeholder]="pleaseSelectPlaceHolder">
                    </p-dropdown>
                </div>
              </div>             
            </div>

            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <p>
                  Usage Upload Enabled:
                </p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('usageUploadEnabled').value != shadowUsageUploadEnabledBase }" id="shadowUsageUploadEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shadowUsageUploadEnabledBase)}}
              </small>
            </div>

            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <div class="p-col">
                  <p-inputSwitch id="usageUploadEnabledField" formControlName="usageUploadEnabled" 
                                (onChange)="handleUsageUploadChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
            </div>
      
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Usage Upload Interval:
                  </p>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': (form.get('usageUploadInterval').value != 'custom' && form.get('usageUploadInterval').value != shadowUsageUploadIntervalBase) || 
                                                (form.get('usageUploadInterval').value == 'custom' && (form.get('UsageUploadCustomInterval').value + form.get('usageUploadCustomUnits').value) != shadowUsageUploadIntervalBase)}"         id="shadowUsageUploadIntervalBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowUsageUploadIntervalBase)}}
                  </small>
                  <small *ngIf="!isViewing">
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('usageUploadInterval')?.value == 'custom'" 
                  class="p-field  p-d-inline-flex p-flex-row-reverse"> 
                  <input id="usageUploadIntervalField"      
                        type="text" formControlName="usageUploadCustomInterval" placeholder="Interval" pInputText/>
              </div>
            </div>

            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="usageUploadInterval"
                    [options]="dataUsageIntervalOptions"
                    formControlName="usageUploadInterval"
                    optionLabel="name"
                    optionValue="value"
                    (onChange)="handleUsageUploadIntervalChange($event)"
                    appendTo="body"
                    [placeholder]="pleaseSelectPlaceHolder">
                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>
              <div *ngIf="form.get('usageUploadInterval')?.value == 'custom'" class="p-field  p-d-flex p-flex-column"> 
                <div class="p-col p-d-flex p-flex-column">
                  <p-dropdown
                        id="usageUploadCustomUnits"
                        [options]="dataUsageCustomUnitsOptions"
                        formControlName="usageUploadCustomUnits"
                        optionLabel="name"
                        optionValue="value"
                        appendTo="body"
                        [placeholder]="pleaseSelectPlaceHolder">
                    </p-dropdown>
                </div>
              </div>
            </div>

            <div class="p-field p-sm-3">
            </div>
            <div class="p-field p-sm-3">
            </div>
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p>
                    Config Sync Interval:
                  </p>
                  <small
                    *ngIf="isViewing"
                    [ngClass]="{'item-differ': form.get('shadowUpdateInterval').value != shadowUpdateIntervalBase }"
                    id="shadowUpdateIntervalBaseField">
                      {{baseValue}} {{configUtils.displayNotSetIfEmpty(shadowUpdateIntervalBase)}}
                  </small>
                  <small *ngIf="!isViewing">
                    <br/>
                  </small>
                </div>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-sm-3">
              <div class="p-formgroup-inline ">
                <div class="p-col">
                  <p-dropdown
                    id="shadowUpdateInterval"
                    [options]="configSyncIntervalOptions"
                    formControlName="shadowUpdateInterval"
                    optionLabel="name"
                    (onChange)="handleShadowIntervalChange($event)"
                    appendTo="body"
                    optionValue="value"
                    [placeholder]="pleaseSelectPlaceHolder">
                  </p-dropdown>
                  <small>
                    <br/>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Serial to IP">
        <div class="card">
          <h4>Serial</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-4">
                <div class="p-col">
                  <p>Enabled</p>
                  <p-inputSwitch id="serialPortField" onLabel="Y" offLabel="N" formControlName="serialPort">
                  </p-inputSwitch>
                </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('serialPort').value != serialPortBase }"
                id="serialPortBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(serialPortBase)}}
              </small>
            </div>
            <div class="p-field p-sm-3">
              <app-form-dropdown
                [parentGroup]="form"
                formControlName="serialBaudRate"
                [isViewing]="isViewing"
                label="Serial Baud Rate"
                [options]="serialBaudRateOptions"
                [baseValue]="serialBaudRateBase"
                ngDefaultControl
                >
              </app-form-dropdown>
            </div>
            <div class="p-field p-sm-1">
            </div>
            <div class="p-field p-sm-3">
              <app-form-dropdown
                [parentGroup]="form"
                formControlName="flowControl"
                [isViewing]="isViewing"
                label="Flow Control"
                [options]="flowControlOptions"
                [baseValue]="flowControlBase"
                ngDefaultControl
                >
              </app-form-dropdown>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="SNMP">
        <div class="card">
          <h4>SNMP</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <p>SNMP Enabled:</p>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('snmpEnabled').value != snmpEnabledBase }"
                id="snmpEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(snmpEnabledBase)}}
              </small>
            </div>
            <div class="p-field p-sm-3">
              <div class="p-formgroup-inline">
                <div class="p-col">
                  <p-inputSwitch id="snmpEnabledField" formControlName="snmpEnabled"></p-inputSwitch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Accessories">
        <div class="card">
          <h4>Accessories</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-12">
              <p class="help-block">
                Some optional accessories for SkyLink have configuration settings. This is where
                you can manage those settings. Remember, these accessories are optional, so unless
                you have one attached, any changes to these settings won't have any effect.
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <h4>Battery Pack</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-6">
              <div class="p-formgroup-inline p-ai-center">
                <label for="shutdownTimeEnabledField">Shutdown Timer Enabled:</label>
                <div class="p-col">
                  <p-inputSwitch id="shutdownTimeEnabledField" formControlName="shutdownTimeEnabled" (onChange)="handleShutdownChange($event)"></p-inputSwitch>
                </div>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('shutdownTimeEnabled').value != shutdownTimeEnabledBase }"
                id="shutdownTimeEnabledBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(shutdownTimeEnabledBase)}}
              </small>
            </div>
            <div class="p-field p-sm-6">
              <div class="p-fluid p-grid">
                <div class="p-field p-col">
                  <p-inputNumber
                    formControlName="shutdownDays"
                    mode="decimal"
                    [showButtons]="true"
                    suffix=" days"
                    [min]="0"
                    [max]="30">
                  </p-inputNumber>
                </div>
                <div class="p-field p-col">
                  <p-inputNumber
                    formControlName="shutdownHrs"
                    mode="decimal"
                    [showButtons]="true"
                    suffix=" hrs"
                    [min]="0"
                    [max]="getShutdownHrsMax()">
                  </p-inputNumber>
                </div>
                <div class="p-field p-col">
                  <p-inputNumber
                    formControlName="shutdownMins"
                    mode="decimal"
                    [showButtons]="true"
                    suffix=" mins"
                    [min]="0"
                    [max]="getShutdownMinsMax()">
                  </p-inputNumber>
                </div>
                <div class="p-field p-col">
                  <p-inputNumber
                    formControlName="shutdownSecs"
                    mode="decimal"
                    [showButtons]="true"
                    suffix=" secs"
                    [min]="0"
                    [max]="getShutdownSecsMax()">
                  </p-inputNumber>
                </div>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': batteryShutdownTime != batteryShutdownTimeBase }"
                id="shutdownTimeEnabledBaseField">
                  {{baseValue}} {{configUtils.displayNotSetIfEmpty(splitShutdownTime(batteryShutdownTimeBase))}}
              </small>
            </div>
          </div>
        </div>
        <div class="card">
          <h4>Emergency Switch/Button</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-6">
              <div class="p-formgroup-inline p-ai-center">
                <label for="emergencyReportEnabledField">Emergency Reporting Enabled:</label>
                <div class="p-col">
                  <p-inputSwitch
                    id="emergencyReportEnabledField"
                    formControlName="emergencyReportEnabled"
                    (onChange)="handleEmergencyReportChange($event)">
                  </p-inputSwitch>
                </div>
              </div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('emergencyReportEnabled').value != emergencyReportEnabledBase }"
                id="emergencyEnableBaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(emergencyReportEnabledBase)}}
              </small>
            </div>
            <div class="p-field p-sm-6">
              <div class="p-fluid">
                <div class="p-field">
                <div class="p-formgroup-inline p-ai-center">
                  <label for="emergencyReportIntervalField">Emergency Report Interval:</label>
                  <div class="p-col">
                    <p-dropdown
                      id="emergencyReportIntervalField"
                      [options]="emergencyReportIntervalOptions"
                      formControlName="emergencyReportInterval"
                      optionLabel="name"
                      optionValue="value"
                      (onChange)="handleEmergencyReportIntervalChange($event)"
                      appendTo="body">
                    </p-dropdown>
                  </div>
                </div>
                <small
                  *ngIf="isViewing"
                  [ngClass]="{'item-differ': emergencyReportInterval != emergencyReportIntervalBase }"
                  id="modeBaseField">
                    {{baseValue}} {{configUtils.displayNotSetIfEmpty(emergencyReportIntervalBase)}}
                </small>
                </div>
                <div
                  *ngIf="form.get('emergencyReportInterval')?.value == 'custom'"
                  class="p-fluid p-formgrid p-grid">
                    <div class="p-col">
                      <p-inputNumber
                        formControlName="reportingCustomInterval"
                        mode="decimal"
                        [showButtons]="true"
                        [min]="1"
                        [max]="60">
                      </p-inputNumber>
                    </div>
                    <div class="p-col">
                      <p-dropdown
                        id="reportingCustomUnitsField"
                        [options]="reportingCustomUnitsOptions"
                        formControlName="reportingCustomUnits"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Units"
                        appendTo="body">
                      </p-dropdown>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)"></button>
        </p>
      </p-tabPanel>
      <p-tabPanel header="Plugins">
        <div class="card">
          <h4>Plugins</h4>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-12">
              <p class="help-block">
                The following third party plugins are available. Use the toggle to enable/disable
                each plugin. If a plugin has additional settings, a gear icon will be visible.
                Click this icon to edit settings for that plugin.
              </p>
            </div>
            <div class="card warning-rules-block p-d-flex p-ai-center vertical-container p-sm-12">
              <div class="p-mr-2" style="width:30px">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
              </div>
              <div>
                <p>
                  Some plugins may result in high data usage.
                </p>
              </div>
            </div>
            <div class="p-field p-sm-12">
              <p-table
                #pluginsList
                [value]="plugins"
                styleClass="p-datatable-striped"
                dataKey="id"
                [rows]="5"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[5, 10, 20]"
                [paginator]="true"
                sortMode="single"
                sortField="name"
                [lazy]="true"
                >
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Enabled</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-plugin>
                  <tr>
                    <td>
                      <div>{{plugin.name}}</div>
                      <div>
                        <small
                          *ngIf="isViewing"
                          [ngClass]="{'item-differ': form.get('videosoftEnabled').value != videosoftEnabledBase }"
                          id="videosoftEnabledBaseField">
                          {{baseValue}} {{configUtils.displayEnabledDisabled(videosoftEnabledBase)}}
                      </small>
                      </div>
                    </td>
                    <td>
                      <p-inputSwitch formControlName="videosoftEnabled"></p-inputSwitch>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)">
          </button>
        </p>
      </p-tabPanel>
      <p-tabPanel *ngIf="messageHubEnabled && (isAdmin || isOps)" header="Messaging Hub">
        <div class="card">
          <h4>Channel Management</h4>
          <div>
            <div class="p-field p-sm-12">
              <p-messages [(value)]="minFirmwareVersionMsg" [enableService]="false" [closable]="false"></p-messages>
              <p class="help-block">
                SkyLink Portal allows you to define your own set of Channels and associate them
                with endpoints. Messages sent from a SkyLink on a specific channel will be delivered
                to ALL the configured endpoints for that specific channel. 
              </p>
              <div class="card warning-rules-block">
                <p>
                    <b>Note:</b> ALL endpoints configured will receive the message sent from the SkyLink.
                </p>
              </div>
              <p-table
                #channelList
                [value]="messageHubChannels.controls"
                styleClass="p-datatable-striped"
                dataKey="value.id"
                [rows]="5"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[5, 10, 20]"
                [paginator]="true"
                [(selection)]="msgChannelsSelected"
                sortMode="single"
                sortField="action"
                [lazy]="true"
                [loading]="isLoading"
                (onLazyLoad)="loadUserData($event)"
                >
              <ng-template pTemplate="header">
                <tr>
                  <th *ngIf="!isViewing && !isReviewer" style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="hide">
                    <p-column type="text" field="key" display="menu"></p-column>
                  </th>
                  <th>
                    Channel ID
                    <p-column type="text" field="action" display="menu"></p-column>
                  </th>
                  <th>
                    Name
                    <p-column type="text" field="source" display="menu"></p-column>
                  </th>
                  <th>
                    Endpoints
                    <p-column type="text" field="destination" display="menu"></p-column>
                  </th>
                  <th>
                    TTL
                    <p-column type="text" field="sourcePort" display="menu"></p-column>
                  </th>
                  <th *ngIf="isViewing">
                    <p-column type="text" field="status" display="menu"></p-column>
                    Status
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-msgChannel>
                  <tr>
                    <td *ngIf="!isViewing && !isReviewer">
                      <p-tableCheckbox [value]= "msgChannel"></p-tableCheckbox>
                    </td>
                    <td class="hide">{{ msgChannel.value.id }}</td>
                    <td>{{ msgChannel.value.channelId }}</td>
                    <td>{{ msgChannel.value.channelName }}</td>
                    <td>
                      <ng-container *ngFor="let endpoint of msgChannel.value.endpoints" >
                        <span class="p-d-block">{{ endpoint }}</span>
                      </ng-container>
                    </td>
                    <td>{{ msgChannel.value.ttl }}</td>
                    <td *ngIf="isViewing">
                      <span>
                        <!-- Modify to ENUM HERE ....-->
                        <span *ngIf="msgChannel.value.status=='Device-Only'" class="customer-badge status-renewal">{{ msgChannel.value.status }}</span>
                        <span *ngIf="msgChannel.value.status=='Pending'" class="customer-badge status-proposal">{{ msgChannel.value.status }}</span>
                        <span *ngIf="msgChannel.value.status=='Synched'" class="customer-badge status-qualified">{{ msgChannel.value.status }}</span>
                        <span *ngIf="msgChannel.value.status=='Modified'" class="customer-badge status-new">{{ msgChannel.value.status }}</span>
                      </span>
                    </td>
                    <td *ngIf="!isViewing && !isReviewer">
                      <button
                        pButton
                        pRipple
                        label="Edit"
                        type="button"
                        icon="fas fa-edit"
                        class="p-button"
                        (click)="onEditMessageChannel(msgChannel)">
                      </button>
                    </td>
                    <td *ngIf="isViewing">
                      <button
                        pButton
                        pRipple
                        label="View"
                        type="button"
                        icon="fas fa-edit"
                        class="p-button"
                        (click)="onEditMessageChannel(msgChannel)">
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <p style="float: right;">
                <button
                  *ngIf="!isViewing && !isReviewer"
                  pButton
                  label="Add"
                  type="button"
                  icon="fas fa-plus"
                  class="p-button-success p-mr-2"
                  [isViewing]="false"
                  (click)="onAddMessageChannel()">
                </button>
                <button
                  *ngIf="!isViewing && !isReviewer"
                  pButton
                  pRipple
                  label="Delete"
                  type="button"
                  icon="fas fa-trash"
                  class="p-button-danger p-mr-2"
                  (click)="onDeleteMessageChannel(msgChannelsSelected)"
                  [disabled]="!msgChannelsSelected.length">
                </button>
              </p>
            </div>
          </div>
        </div>
        <p>
          <button
            *ngIf="!isViewing && !isReviewer"
            pButton
            label="Save"
            class="p-button-warning p-mr-2"
            icon="fas fa-save"
            type="submit"
            [disabled]="!form.valid || !form.dirty">
          </button>
          <button
            *ngIf="isEditing && !isReviewer"
            pButton
            pRipple
            label="Delete"
            type="button"
            icon="fas fa-trash"
            class="p-button-danger p-mr-2"
            (click)="deleteConfigModalOpen=true">
          </button>
          <button
            pButton
            label="Cancel"
            class="p-button-warning"
            icon="fas fa-undo"
            type="button"
            (click)="onCancel($event)"></button>
        </p>
      </p-tabPanel>
    </p-tabView>
  </form>

</div>
<app-config-history
  [(visible)]="configHistoryModalOpen"
  [configType]="'device'"
  [dConfigs]="deviceConfigs"
  (onClose)="onClose()">
</app-config-history>
<app-update-shadow
  [(visible)]="updateShadowModalOpen"
  [devices]="devicesToUpdate"
  (submitData)="submitData()"
  (onClose)="onClose()">
</app-update-shadow>
<app-delete-config
  [(visible)]="deleteConfigModalOpen"
  [configs]="configs"
  (onClose)="onClose()">
</app-delete-config>
<app-add-advanced-rule
  [(visible)]="addAdvancedRuleModalOpen"
  [isViewing]="isViewing"
  [isAdding]="isAdding"
  [isReviewer]="isReviewer"
  [advancedRule]="ruleSelected"
  [advancedRuleBase]="ruleSelectedBase"
  (onSave)="onRuleAdded($event)">
</app-add-advanced-rule>
<app-delete-advanced-rule
  [(visible)]="deleteRuleModalOpen"
  [baseRules]="mergedRulesToDisplayBase"
  [rulesToBeRemoved]="rulesSelected"
  (onClose)="onRulesDeleted($event)">
</app-delete-advanced-rule>
<app-add-static-dhcp-lease
  [(visible)]="addStaticDhcpLeaseModalOpen"
  [isViewing]="isViewing"
  [isAdding]="isAddingDhcp"
  [isReviewer]="isReviewer"
  [staticDhcp]="staticDhcpLeaseSelected"
  [staticDhcpBase]="staticDhcpLeaseSelectedBase"
  [allStaticDhcpLeases]="this.staticDhcpLeases"
  (onSave)="onStaticDhcpLeaseAdded($event)">
</app-add-static-dhcp-lease>
<app-delete-static-dhcp-lease
  [(visible)]="deleteStaticDhcpLeaseModalOpen"
  [dhcpToBeRemoved]="staticDhcpLeasesSelected"
  (onClose)="onStaticDhcpLeaseDeleted($event)">
</app-delete-static-dhcp-lease>
<app-profile-descriptions
  [(visible)]="profileDescriptionsModalOpen"
  (onClose)="onClose()">
</app-profile-descriptions>
<app-delete-host-name
  [(visible)]="deleteWLHostnameModalOpen"
  [hostnameToBeRemoved]="selectedWLHostName"
  [index]="selectedHostNameIdx"
  (onClose)="onWhitelistHostnameDeleted($event)">
</app-delete-host-name>
<app-delete-ip-address
  [(visible)]="deleteWLIpaddressModalOpen"
  [ipaddressToBeRemoved]="selectedWLIpAddress"
  [index]="selectedIpAddressIdx"
  (onClose)="onWhitelistIpAddressDeleted($event)">
</app-delete-ip-address>
<app-add-message-hub-channel
  [(visible)]="addMessageHubChannelModalOpen"
  [isViewing]="isViewing"
  [isAdding]="isAddingMsgChannel"
  [isReviewer]="isReviewer"
  [msgHubChannel]="msgChannelSelected"
  [msgHubChannelBase]="msgChannelSelectedBase"
  [allMsgHubChannels]="messageHubChannels"
  (onSave)="onMessageHubChannelAdded($event)">
</app-add-message-hub-channel>
<app-delete-message-hub-channel
  [(visible)]="deleteMessageHubChannelModalOpen"
  [msgChannelsToBeRemoved]="msgChannelsSelected"
  (onClose)="onMessageHubChannelDeleted($event)">
</app-delete-message-hub-channel>
