<div class="container">
  <h1>Profiles</h1>
  <!-- <p class="help-block">Create and manage users throughout the system.</p> -->
  <div class="card">
  <p-tabView [activeIndex]="index">
    <p-tabPanel header="Device">
      <p-table
        #configsList
        [value]="configs"
        styleClass="p-datatable-striped"
        dataKey="id"
        [rows]="50"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['name','org.name']"
        [(selection)]="configsSelected"
        sortMode="single"
        sortField="name"
        [loading]="isLoading"
      >
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <button
              *ngIf="!isReviewer"
              pButton
              pRipple
              label="New"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              routerLink="/configs/add"
              [params]={index:0}>
            </button>
            <button *ngIf="!isReviewer"
              pButton
              pRipple
              label="Delete"
              icon="fas fa-trash"
              class="p-button-danger p-mr-2"
              [disabled]="!configsSelected.length"
              (click)="deleteConfigModalOpen=true">
            </button>
            <button
              pButton
              pRipple
              label="Clear"
              class="p-button p-mr-2"
              icon="pi pi-filter-slash"
              (click)="clear(configsList)">
            </button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search(configsList, $event)" placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
              Name
              <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
            </th>
            <th>
              Org
              <p-columnFilter type="text" field="org.name" display="menu"></p-columnFilter>
            </th>
            <th>
              Created
              <p-columnFilter type="text" field="created" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-config>
          <tr>
            <td>
              <p-tableCheckbox [value]="config"></p-tableCheckbox>
            </td>
            <td>
              <a [routerLink]="['/configs/edit', config.id, { index : 0 }]" class="d-block">{{ config.name }}</a>
            </td>
            <td>{{ config.org.name }}</td>
            <td>{{ config.createdAt | date: 'MM/dd/yyyy'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Voice">
      <p-table
        #vConfigsList
        [value]="vConfigs"
        styleClass="p-datatable-striped"
        dataKey="id"
        [rows]="50"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['name', 'org.name']"
        [(selection)]="vConfigsSelected"
        sortMode="single"
        sortField="name"
        [loading]="isLoading"
      >
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <button
              *ngIf="!isReviewer"
              pButton
              pRipple
              label="New"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              routerLink="/configs/add-vconfig"
              [params]={index:1}>
            </button>
            <button *ngIf="!isReviewer"
              pButton
              pRipple
              label="Delete"
              icon="fas fa-trash"
              class="p-button-danger p-mr-2"
              [disabled]="!vConfigsSelected.length"
              (click)="deleteVConfigModalOpen=true"></button>
            <button
              pButton
              pRipple
              label="Clear"
              class="p-button p-mr-2"
              icon="pi pi-filter-slash"
              (click)="clear(vConfigsList)">
            </button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search(vConfigsList, $event)" placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
              Name
              <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
            </th>
            <th>
              Org
              <p-columnFilter type="text" field="org.name" display="menu"></p-columnFilter>
            </th>
            <th>
              Created
              <p-columnFilter type="text" field="created" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vConfig>
          <tr>
            <td>
              <p-tableCheckbox [value]="vConfig"></p-tableCheckbox>
            </td>
            <td>
              <a [routerLink]="['/configs/edit-vconfig', vConfig.id, { index : 1 }]" class="d-block">{{ vConfig.name }}</a>
            </td>
            <td>{{ vConfig.org.name }}</td>
            <td>{{ vConfig.createdAt | date: 'MM/dd/yyyy'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
  </div>
</div>
<app-delete-config [(visible)]="deleteConfigModalOpen" [configs]="configsSelected"  (onClose)="onClose()"></app-delete-config>
<app-delete-vconfig [(visible)]="deleteVConfigModalOpen" [vConfigs]="vConfigsSelected"  (onClose)="onClose()"></app-delete-vconfig>
