import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigModel } from 'src/app/models/config.model';
import { FirewallRule, FirewallRulesModel } from 'src/app/models/config.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Message } from "primeng/api";

@Component({
  selector: 'app-delete-advanced-rule',
  templateUrl: './delete-advanced-rule.component.html',
  styleUrls: ['./delete-advanced-rule.component.scss']
})
export class DeleteAdvancedRuleComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() rulesToBeRemoved: FirewallRule[] = [];
  @Input() baseRules: FirewallRule[] = [];
  @Input() baseModels: FirewallRulesModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      // myArray = myArray.filter(ar => !toRemove.find(rm => (rm.name === ar.name && ar.place === rm.place) ))

      console.log(`in DELETE to be removed = ${JSON.stringify(this.rulesToBeRemoved)}`);
      console.log(`in DELETE base = ${JSON.stringify(this.baseRules)}`);

      this.baseRules = this.baseRules.filter(entry => !this.rulesToBeRemoved.find(rule => rule.protocol+":"+rule.ipAddress+":"+rule.destinationPort+":"+rule.sourcePort ===
                                                                                          entry.protocol+":"+entry.ipAddress+":"+entry.destinationPort+":"+entry.sourcePort)
      );

      console.log(`DELETE ADVANTAGED:: BASE RULES AFTER FILTER is ${JSON.stringify(this.baseRules)}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
    } finally {
      this.rulesToBeRemoved = [];

      this.onClose.emit(this.baseRules);
      this.visible = false;
      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }
  async onCloseDialog() {
    this.rulesToBeRemoved = [];
    console.log(`onCloseDialog  DONE`);
  }
}
