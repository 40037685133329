import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { OrgModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';

@Component({
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  currentUser?: UserModel;
  assignModalOpen = false;
  isLoading: boolean = false;
  customers: OrgModel[] = [];
  customersSelected: OrgModel[] = [];

  resellers: OrgModel[] = [];
  resellerSelected?: OrgModel;

  deleteCustomerModalOpen = false;
  totalRecords = 0;
  take = 50;
  skip = 0;
  selectAll = false;
  globalFilterFields=['adminContact', 'name', 'parentOrg.name', 'supportContact'];
  searchInput = '';
  clearing = false;
  lastLazyEvent: any = ''


  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Customers' }])
    this.lastLazyEvent = '';
    this.loadResellers();
    this.clearing = false;
  }

  onSelectionChange(value = []) {
    this.selectAll = value.length === this.totalRecords;
    this.customersSelected = [...value];
    console.log(`customersSelected=${this.customersSelected}`);
  }

  async loadCustomers(loadEvent: LazyLoadEvent) {
    console.log(`loadCustomers() - loadEvent = ${loadEvent}`);
    try {

      this.isLoading =true;
      this.skip = loadEvent.first || 0;
      this.take = loadEvent.rows || this.take;

      if (this.clearing) {
        loadEvent.globalFilter = '';
        loadEvent.filters = {};
        return;
      }

      // Skip any possible duplicate event
      console.log(`LAST=${this.lastLazyEvent}`);
      if (this.lastLazyEvent === JSON.stringify(loadEvent)) {
        console.log(`skip`);
        return;
      }

      this.lastLazyEvent = JSON.stringify(loadEvent);
      console.log(`THIS IS THE EVENT: ${JSON.stringify(loadEvent)}`);
      await this.load(JSON.stringify(loadEvent));

    } finally {
      this.isLoading = false;
    }
  }

  // Load reseller, just needs to be loaded once on page initial load. This is used only for assignment. 
  // the load cannot be paginated
  async loadResellers() {
      this.api.get(`/resellers`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.resellers = rsp.orgs;
    });
  }

  async load(loadEvent: any) {
    let globalFilterFieldsQueryParm = '';

    if (this.globalFilterFields) {
      globalFilterFieldsQueryParm = `&globalFilterFields=${JSON.stringify(this.globalFilterFields)}`
    }

    console.log(`globalFilterFieldsQueryParm: ${JSON.stringify(globalFilterFieldsQueryParm)}`);
    this.api.get(`/customers?paginationParm=${loadEvent}${globalFilterFieldsQueryParm}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.customers = rsp.orgs;
    });

  }

  // Reloads page with previous 'configuration' 
  async reload() {
    this.api.get(`/customers?take=${this.take}&skip=${this.skip}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.customers = rsp.orgs;
    });
  }

  assign(reseller: OrgModel, customers: OrgModel[]) {
    this.confirmationService.confirm({
      message: "Are you sure you want to perform this action?",
      accept: async () => {
        try {
          await Promise.all(customers.map(customer =>
            // Assign customer to reseller
            this.api.put(`/customers/${customer.id}`, {
              parentId: reseller?.id
            })
          ));
          this.reload();
          this.assignModalOpen = false;
        } catch (err) {
          this.messageService.addAll(parseApiErrorsToToast(err));
        }
      }
    });
  }

  clear(table: Table) {
    console.log(`clear`);
    this.searchInput='';
    this.clearing = true;
    table.clear();
    table.filterGlobal('', 'contains'); 
    this.clearing = false; // reset flag after clearing is done.
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onClose() {
    this.customersSelected = [];
    await this.reload();
  }

}
