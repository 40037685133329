<p-dialog
  header="Update ALL Devices?" 
  [visible]="visible" 
  (visibleChange)="visibleChange.emit($event)" 
  [style]="{ width: '50vw' }">
  <p>Updating this profile will sync ALL devices </p>
  <ul class="two-column">
    <li *ngFor="let device of devices">Name: {{ device.name }}</li>
  </ul>
  <p><b>Are you sure you want to continue?</b></p>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (onClick)="cancel()" label="No" styleClass="p-button"></p-button>
    <p-button icon="pi pi-check" (onClick)="confirm()" label="Yes" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
