<div class="container">
  <h1>My Organization</h1>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- <section ngIf="!isEditing" > -->
    <div class="card">
      <section>
        <h3>Contact</h3>
        <!-- <small class="text-danger">Defines the type and placement of the new user. This cannot be changed after creating the user.</small> -->
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-sm-6">
            <label for="nameField">Name</label>
            <input id="nameField" type="text" formControlName="name" pInputText />
          </div>
          <div *ngIf="isVam" class="p-field p-sm-6">
            <label for="accountOwnerField">Account Owner</label>
            <input id="accountOwnerField" type="text" formControlName="accountOwner" pInputText />
          </div>
          <div class="p-field p-sm-12">
            <label for="adminContactField">Administrative Contact</label>
            <textarea id="adminContactField" type="text" rows="2" formControlName="adminContact" pInputText></textarea>
          </div>
          <div class="p-field p-sm-12">
            <label for="supportContactField">Support Contact</label>
            <textarea id="supportContactField" type="text" rows="3" formControlName="supportContact" pInputText></textarea>
            <small>Support contact information will be displayed to the organizations they manage.</small>
          </div>
        </div>
      </section>
    </div>

    <div *ngIf="isAdmin && (isReseller || isVam)" class="card">
      <section>
        <h5>Branding</h5>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-sm-12">
            <label for="logoField">Logo</label>
            <app-upload formControlName="logo"></app-upload>
            <span>Max File Size: 2 MB; Best Aspect Ratio: 10:3; Formats: PNG, JPG, GIF</span>
          </div>
        </div>
      </section>
    </div>

    <section *ngIf="orgTree.length > 0">
      <div class="card">
        <h5>Org Tree</h5>
        <p-tree [value]="orgTree" layout="horizontal" selectionMode="single" [(selection)]="selectedOrg"></p-tree>
      </div>
      <hr />
    </section>

    <div *ngIf="parentOrg" class="card">
    <section >
      <h5>Reseller Org</h5>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-sm-12">
          <label for="parentOrgName">Name</label>
          <div id="parentOrgName" >{{ parentOrg?.name || '' }}</div>
        </div>
        <div class="p-field p-sm-12 p-md-6">
          <label for="parentOrgAdminContact">Admin Contact</label>
          <pre id="parentOrgAdminContact" >{{ parentOrg?.adminContact || '' }}</pre>
        </div>
        <div class="p-field p-sm-12 p-md-6">
          <label for="parentOrgSupportContact">Support Contact</label>
          <pre id="parentOrgSupportContact" >{{ parentOrg?.supportContact || '' }}</pre>
        </div>
      </div>
    </section>
    </div>

    <!--
    <h4>Branding</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-12">
        <label for="displayNameField">Display Name</label>
        <input id="displayNameField" type="text" formControlName="displayName" pInputText  />
      </div>
      <div class="p-field p-sm-9">
        <label for="logoField">Logo</label>
        <input id="logoField" type="text" formControlName="logo" pInputText  />
      </div>
      <div class="p-field p-sm-6">
        <label for="headerColorField">Header Color</label>
        <input id="headerColorField" type="text" formControlName="headerColor" pInputText  />
      </div>
      <div class="p-field p-sm-6">
        <label for="bodyHeaderColorField">Body Header Color</label>
        <input id="bodyHeaderColorField" type="text" formControlName="bodyHeaderColor" pInputText  />
      </div>
      <div class="p-field p-sm-6">
        <label for="bodyContentColorField">Body Content Color</label>
        <input id="bodyContentColorField" type="text" formControlName="bodyContentColor" pInputText  />
      </div>
      <div class="p-field p-sm-6">
        <label for="footerColorField">Footer Color</label>
        <input id="footerColorField" type="text" formControlName="footerColor" pInputText  />
      </div>
      <div class="p-field p-sm-6">
        <label for="bodyColorField">Body Color</label>
        <input id="bodyColorField" type="text" formControlName="bodyColor" pInputText  />
      </div>

    </div>
-->
    <p>
      <button
        *ngIf="!isReviewer && !isOps && !isAccountManager"
        pButton
        label="Save"
        class="p-button-warning p-mr-2"
        icon="fas fa-save"
        type="submit"
        [disabled]="!form.valid || !form.dirty">
      </button>
      <!-- <button pButton label="Cancel" class="p-button-warning" icon="fas fa-undo" type="button" (click)="onCancel($event)"></button> -->
    </p>
  </form>


</div>