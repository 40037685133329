<p-dialog header="Delete Reseller" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Do you want to delete the following?</p>
  <ul class="two-column">
    <li *ngFor="let reseller of resellers">Reseller Name: {{ reseller.name }}</li>
  </ul>

  <ng-template pTemplate="footer">
    <p-button icon="fas fa-trash"
              (onClick)="delete()"
              label="Delete"
              styleClass="p-button-danger">
    </p-button>
  </ng-template>

</p-dialog>
