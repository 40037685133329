import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { DeviceModel, /*DeviceLastContact*/ } from 'src/app/models/device.model';

import { CustomerModel } from 'src/app/models/customer.model';
import { ApiService } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { environment } from 'src/environments/environment';

import * as xlsx from 'xlsx';

@Component({
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  currentUser?: UserModel;
  isVam: boolean = false;
  isCustomer: boolean = false;
  isReseller: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  assignResellerModalOpen = false;
  assignCustomerModalOpen = false;
  assignOrgModalOpen = false;
  assignConfigModalOpen = false;
  isLoading = false;
  devices: DeviceModel[] = [];
  devicesSelected: DeviceModel[] = [];
  totalRecords = 0;
  take = 50; // needs to be in synch with table
  skip = 0;
  selectAll = false;
  globalFilterFields=['name', 'serial', 'assetTag', 'deviceType.name', 'org.name', 'iridium_imei', 'lte_imei'];
  searchInput = '';
  clearing = false;
  lastLazyEvent: any = '';
  exportColumns: any[] = [];

  firmwareVersions: any[] = [];

  imtFirmwareVersion: string = environment.imt.firmwareVersion;

  saveLoadEvent: LazyLoadEvent = {};

  // DEVICE_LAST_CONTACT_ENUM = DeviceLastContact;

  constructor(
    private api: ApiService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isCustomer = this.currentUser?.organization.type === 'Customer';
      this.isReseller = this.currentUser?.organization.type === 'Reseller';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Devices' }]);
    this.lastLazyEvent = '';
    this.clearing = false;
  }

  onSelectionChange(value = []) {
    this.selectAll = value.length === this.totalRecords;
    this.devicesSelected = [...value];
    console.log(`devicesSelected=${this.devicesSelected}`);
  }

  async loadDevices(loadEvent: LazyLoadEvent) {
    try {
      this.isLoading =true;

      this.skip = loadEvent.first || 0;
      this.take = loadEvent.rows || this.take;

      // Force clearing global filter when user press the 'Clear' button
      // Why many searches (1 per page) are issue. (we don't have control over it,  since it's a callback event received)
      // Solution below:
      // When performing a clear filter, do not reissue search, since the table.filterGlobal('', 'contains');
      // will reissue the search will proper search after filters are cleared and proper 1 time search 
      if (this.clearing) {
        loadEvent.globalFilter = '';
        loadEvent.filters = {};
        return;
      }

      // Save LoadEvent
      this.saveLoadEvent = loadEvent;

      // Skip any possible duplicate event
      console.log(`LAST=${this.lastLazyEvent}`);
      if (this.lastLazyEvent === JSON.stringify(loadEvent)) {
          console.log(`skip`);
          return;
      }
      this.lastLazyEvent = JSON.stringify(loadEvent);
      console.log(`THIS IS THE EVENT: ${JSON.stringify(loadEvent)}`);
      await this.load(JSON.stringify(loadEvent));

    } finally {
      this.isLoading = false;
    }
  }

  async load(loadEvent: any) {
    let globalFilterFieldsQueryParm = '';
    if (this.globalFilterFields) {
      globalFilterFieldsQueryParm = `&globalFilterFields=${JSON.stringify(this.globalFilterFields)}`
    }
    console.log(`globalFilterFieldsQueryParm: ${JSON.stringify(globalFilterFieldsQueryParm)}`);

    this.api.get(`/devices?paginationParm=${loadEvent}${globalFilterFieldsQueryParm}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.devices = rsp.devices;
    });

    // TODO: NEED THIS or something similar to filter firmware verions
    // const firmwareVersionsRsp: any = await this.api.get<{ "firmwareVersionsList": any[] }>(`/firmware/versions`);
    // if (firmwareVersionsRsp) {
    //   firmwareVersionsRsp.forEach((fw: any) => {
    //     this.firmwareVersions.push({version: fw.version.S});
    //   })
    //   console.log(`firmwareVersions = ${JSON.stringify(this.firmwareVersions)}`);
    // }
  }

  // Reloads page with previous 'configuration' 
  async reload() {
    this.api.get(`/devices?take=${this.take}&skip=${this.skip}`).then((rsp: any)=> {
      this.totalRecords = rsp.total;
      this.devices = rsp.devices;
    });
  }

  async removeCustomerAssociation(device: DeviceModel, customer: CustomerModel) {
    this.confirmationService.confirm({
      message: 'Are you sure? This will remove the device from the customers account.',
      accept: async () => {
        try {
          this.blockUI?.start();
          await this.api.put(`/devices/${device.id}`, {
            customerOrgId: null
          })
          await this.reload();
        } finally {
          this.blockUI?.stop();
        }
      }
    });
  }

  async onClose() {
    console.log(`onclose`);
    this.devicesSelected = [];
    await this.reload();
  }

  clear(table: Table) {
    console.log(`clear`);
    this.searchInput='';
    this.clearing = true;
    table.clear();
    table.filterGlobal('', 'contains'); 
    this.clearing = false; // reset flag after clearing is done.
  }

  async search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains'); 
    }
  }

  async exportExcel() {
    console.log(`In exportExcel() - this.saveLoadEvent = ${JSON.stringify(this.saveLoadEvent)}`);
    // We need ALL devices for the report so must remove rows
    this.saveLoadEvent.rows = 10000;  // Work-around so as not to change the Skylink API yet!
    console.log(`In exportExcel() - this.saveLoadEvent = ${JSON.stringify(this.saveLoadEvent)}`);
    const loadEvent = JSON.stringify(this.saveLoadEvent);

    let globalFilterFieldsQueryParm = '';
    if (this.globalFilterFields) {
      globalFilterFieldsQueryParm = `&globalFilterFields=${JSON.stringify(this.globalFilterFields)}`
    }
    console.log(`In exportExcel() - globalFilterFieldsQueryParm = ${globalFilterFieldsQueryParm}`);

    const deviceRsp: any = await this.api.get(`/devices?paginationParm=${loadEvent}${globalFilterFieldsQueryParm}`);
    if (deviceRsp) {
      const allDevices = deviceRsp.devices;
      console.log(`Exporting ALL devices = `, allDevices);

      // Clean up
      // Keep deviceId, deviceName, imtSupported, imtEnabled, fwVersion, iridiumIMEI, cellularIMEI, orgName
      const reportDevices = allDevices.map((d: any) => ({
        deviceId: d.id,
        deviceName: d.name,
        imtSupported: d.firmwareVersion >= this.imtFirmwareVersion,
        imtEnabled: d.cmidMappingId ? true : false,
        fwVersion: d.firmwareVersion || "n/a",
        iridiumIMEI: d.iridium_imei,
        cellularIMEI: d.lte_imei,
        orgName: d.org?.name || ''
      }));

      console.log(`Reported Devices = `, reportDevices);

      const worksheet = xlsx.utils.json_to_sheet(reportDevices);

      // fix headers
      xlsx.utils.sheet_add_aoa(worksheet, [
        ["Device ID", "Device Name", "IMT Supported", "IMT Enabled", "F/W Version", "IridiumIMEI", "Cellular IMEI", "Org Name"]],
         { origin: "A1" }
      );
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Devices");

      /* calculate column width */
      const deviceIdWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.deviceId?.length + 5), 15);
      const deviceNameWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.deviceName?.length + 5), 15);
      const imtSupportedWidth = 15;
      const imtEnabledWidth = 15;
      const fwVersionWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.fwVersion?.length + 5), 15);
      const iridiumIMEIWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.iridiumIMEI?.length + 5), 15);
      const cellularIMEIWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.cellularIMEI?.length + 5), 15);
      const orgNameWidth = reportDevices.reduce((w: any, r: any) => Math.max(w, r.orgName?.length + 5), 15);

      worksheet["!cols"] = [ { wch: deviceIdWidth }, { wch: deviceNameWidth }, { wch: imtSupportedWidth }, { wch: imtEnabledWidth },
                             { wch: fwVersionWidth }, { wch: iridiumIMEIWidth }, { wch: cellularIMEIWidth }, { wch: orgNameWidth } ];

      /* create an XLSX file and try to save to Presidents.xlsx */
      xlsx.writeFile(workbook, `Devices_export_${new Date().getTime()}.xlsx`, { compression: true });

    }
  }

}