import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { SipExtension } from 'src/app/models/voice-config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { nameValidator } from '../../../validators/nameValidator';
import { usernameRegEx, pwdRegEx, descriptionRegEx } from 'src/app/util/regex-utils';

@Component({
  selector: 'app-edit-sip-extension',
  templateUrl: './edit-sip-extension.component.html',
  styleUrls: ['./edit-sip-extension.component.scss']
})
export class EditSipExtensionComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() isViewing = false;
  @Input() isReviewer = false;
  @Input() sipExtension?: SipExtension;
  @Input() sipExtensionBase?: SipExtension;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isEditing = false;
  isLoading = false;
  configUtils = ConfigUtils;

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  extensionBase = '';
  line1Base = false;
  line2Base = false;
  outboundLineBase = 'any';
  usernameBase = '';
  passwordBase = '';
  descriptionBase = '';

  // Form
  outboundLines = [
    { name: "Line 1", value: "line_1" },
    { name: "Line 2", value: "line_2" },
    { name: "Any Available", value: "any" }
  ];

  sipExtForm = this.fb.group({
    extension: [{ value: null, disabled: true }, Validators.required],
    inboundLine1: [false],
    inboundLine2: [false],
    outboundLine: [{ value: "any" }],
    username: [{ value: null },
      [Validators.required, Validators.maxLength(50), nameValidator(usernameRegEx)]],
    password: [{ value: null },
      [Validators.required, Validators.maxLength(100), nameValidator(pwdRegEx)]],
    description: [{ value: null },
      [Validators.maxLength(50), nameValidator(descriptionRegEx)]],
  });

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    console.log("loading sipExtension");
    try {
      this.isLoading = true;

    } finally {
      this.isLoading = false;
    }
  }

  async onShow() {
    console.log("onShow() sipExtension = ", this.sipExtension);
    if (this.isViewing || this.isReviewer) {
      this.disableControlsForView();

      // Load SIP Desired
      if (this.sipExtensionBase) {
        this.extensionBase = this.sipExtensionBase.ext;
        this.line1Base = this.sipExtensionBase.inbound.line_1;
        this.line2Base = this.sipExtensionBase.inbound.line_2;
        this.outboundLineBase = this.sipExtensionBase.outbound;
        this.usernameBase = this.sipExtensionBase.username;
        this.passwordBase = this.sipExtensionBase.password;
        this.descriptionBase = this.sipExtensionBase.description;
      }
    }

    if (this.sipExtension) {
      this.isEditing = true;
      this.sipExtForm.patchValue({
        extension: this.sipExtension.ext,
        inboundLine1: this.sipExtension.inbound.line_1,
        inboundLine2: this.sipExtension.inbound.line_2,
        outboundLine: this.sipExtension.outbound,
        username: this.sipExtension.username,
        password: this.sipExtension.password,
        description: this.sipExtension.description
      });

    }
  }

  async onCloseDialog() {
    console.log("onCloseDialog() sipExtension = ", this.sipExtension);
    this.sipExtForm.reset();

    this.extensionBase = '';
    this.line1Base = false;
    this.line2Base = false;
    this.outboundLineBase = 'any';
    this.usernameBase = '';
    this.passwordBase = '';
    this.descriptionBase = '';
  }

  async save() {
    console.log("save() - this.sipExtForm.value = ", this.sipExtForm.value);
    try {
      this.blockUI?.start();

      const outSipExtension = {
        ext: this.sipExtension?.ext,
        inbound: {
          line_1: this.sipExtForm.value.inboundLine1,
          line_2: this.sipExtForm.value.inboundLine2
        },
        outbound: this.sipExtForm.value.outboundLine,
        description: this.sipExtForm.value.description,
        username: this.sipExtForm.value.username,
        password: this.sipExtForm.value.password
      }

      // This calls the onClose defined in the parent ie. (onClose)="saveSipExtension($event)"
      this.onSave.emit(outSipExtension);
      this.visible = false;
      this.visibleChange.emit(false);
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  disableControlsForView() {
    this.sipExtForm.get('extension')?.disable();
    this.sipExtForm.get('inboundLine1')?.disable();
    this.sipExtForm.get('inboundLine2')?.disable();
    this.sipExtForm.get('outboundLine')?.disable();
    this.sipExtForm.get('username')?.disable();
    this.sipExtForm.get('password')?.disable();
    this.sipExtForm.get('description')?.disable();
  }
}
