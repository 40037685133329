<div class="container">
  <h1>Device Types</h1>
  <div class="card">
  <p-table
    #deviceTypesList
    [value]="deviceTypes"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['name', 'manufacturer', 'productCode', 'productVersion', 'productVariant']"
    sortMode="single"
    sortField="name"
    [loading]="loading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          *ngIf="!isReviewer && !isAccountManager"
          pButton
          pRipple
          label="New"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          routerLink="/device-type/add">
        </button>
        <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(deviceTypesList)"></button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="search(deviceTypesList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          Name
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Manufacturer
          <p-columnFilter type="text" field="manufacturer" display="menu"></p-columnFilter>
        </th>
        <th>
          Product Code
          <p-columnFilter type="text" field="productCode" display="menu"></p-columnFilter>
        </th>
        <th>
          Product Version
          <p-columnFilter type="text" field="productVersion" display="menu"></p-columnFilter>
        </th>
        <th>
          Product Variant
          <p-columnFilter type="text" field="productVariant" display="menu"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-deviceTypes>
      <tr>
        <td>
          <a [routerLink]="['/device-type/edit', deviceTypes.id]">{{ deviceTypes.name }}</a>
        </td>
        <td>
          <span *ngIf="deviceTypes.manufacturer ; else manufacturerNotAssigned">
            {{ deviceTypes.manufacturer }} <br/>
          </span>
          <ng-template #manufacturerNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="deviceTypes.productCode ; else productCodeNotAssigned">
            {{ deviceTypes.productCode }} <br/>
          </span>
          <ng-template #productCodeNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="deviceTypes.productVersion ; else productVersionNotAssigned">
            {{ deviceTypes.productVersion }} <br/>
          </span>
          <ng-template #productVersionNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="deviceTypes.productVariant ; else productVariantNotAssigned">
            {{ deviceTypes.productVariantDisplay }} <br/>
          </span>
          <ng-template #productVariantNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
