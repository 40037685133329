import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { MyApiKeyModel } from 'src/app/models/my-api-key.model';

@Component({
  selector: 'app-my-api-keys',
  templateUrl: './my-api-keys.component.html',
  styleUrls: ['./my-api-keys.component.scss']
})
export class MyApiKeysComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  createKeyModalOpen = false;
  isLoading = false;
  apikeys: MyApiKeyModel[] = [];
  minFirmwareVersionMsg: Message[] = [];

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: AppBreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'My API Keys' }]);
    this.minFirmwareVersionMsg = [
      {severity:'warn', summary:'Warning', detail:'Minimum SkyLink firmware version needed for this feature: 2.33'}
    ];
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const response = await this.api.get<{ "apikeys": MyApiKeyModel[] }>("/my-apikeys");
      console.log(`response = `, response)
      this.apikeys = response.apikeys;
    } finally {
      this.isLoading = false;
    }
  }

  deleteKey(apikey: MyApiKeyModel) {
    console.log(`About to delete keyid = ${apikey.keyid}`)
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this API Key?",
      acceptButtonStyleClass: "p-button-warning",
      accept: async () => {
        try {
          await this.api.delete(`/my-apikeys/${apikey.keyid}`);
          await this.load();
        } catch (err) {
          this.messageService.addAll(parseApiErrorsToToast(err));
        }
      }
    });
  }

  //#region table handling
  clear(table: Table) {
    table.clear();
  }
  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }
  //#endregion
}
