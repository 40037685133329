<p-dialog 
  header="POTS Extension"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '50vw' }"
  (onShow)="onShow()"
  (onHide)="onCloseDialog()">
<!-- <p-dialog header={{line}} [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="(onClose)"> -->
  <form [formGroup]="potsExtForm">
    <div class="p-fluid">
      <div class="p-field p-sm-9">
        <label for="extensionField">Extension</label>
        <input id="extensionField" type="text" formControlName="extension" pInputText required/>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': potsExtForm.get('extension').value != extensionBase }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(extensionBase)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label>Inbound Line(s)</label>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <div class="p-field-checkbox">
              <p-checkbox name="line1" [binary]="true" formControlName="inboundLine1" inputId="line1"></p-checkbox>
              <label for="line1">Line1</label>
            </div>
            <div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': potsExtForm.get('inboundLine1').value != line1Base }"
                id="line1BaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(line1Base)}}
              </small>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <div class="p-field-checkbox">
              <p-checkbox name="line2" [binary]="true" formControlName="inboundLine2" inputId="line2"></p-checkbox>
              <label for="line2">Line2</label>
            </div>
            <div>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': potsExtForm.get('inboundLine2').value != line2Base }"
                id="line2BaseField">
                  {{baseValue}} {{configUtils.displayEnabledDisabled(line2Base)}}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field p-sm-9">
        <label for="outboundLineField">Outbound Line</label>
        <p-dropdown id="outboundLineField"
          [options]="outboundLines"
          formControlName="outboundLine"
          placeholder="Select an Outbound Line"
          optionLabel="name"
          optionValue="value"
          appendTo="body"
          required>
        </p-dropdown>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': potsExtForm.get('outboundLine').value != outboundLineBase }"
          id="outboundLineBaseField">
            {{baseValue}} {{configUtils.displayConversion(outboundLines, outboundLineBase)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="descriptionField">Description</label>
        <input id="descriptionField" type="text" formControlName="description" pInputText />
        <div *ngIf="!isViewing && potsExtForm.get('description')?.errors?.nameValidator">
          Description is invalid.
        </div>
        <div *ngIf="!isViewing && potsExtForm.get('description')?.errors?.maxlength">
          Description must be at most 50 characters long.
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': potsExtForm.get('description').value != descriptionBase }"
          id="descriptionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(descriptionBase)}}
        </small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button
      *ngIf="!isViewing && !isReviewer"
      pButton
      (click)="save()"
      label="Save"
      class="p-button-warning p-mr-2"
      icon="fas fa-save"
      [disabled]="!potsExtForm.valid || !potsExtForm.dirty">
    </button>
  </ng-template>
</p-dialog>
