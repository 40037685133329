<p-dialog header="Delete Notes" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Do you want to delete the following?</p>
  <ul class="one-column">
    <li *ngFor="let n of notes">{{ n.note }} <br/><b>Dated: {{ n.createdAt  | date: 'short' }}</b></li>
  </ul>

  <ng-template pTemplate="footer">
    <p-button
      icon="fas fa-trash"
      label="Delete"
      type="button"
      styleClass="p-button-danger"
      (onClick)="delete()">
    </p-button>
  </ng-template>

</p-dialog>
