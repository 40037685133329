import { TmplAstTemplate } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() title: string = '';
  @Input() footer: string = '';
  @Input() show?: boolean;
  @Input() chartData: any;

  constructor() { }

  ngOnInit(): void {
    if (this.show === undefined) {
      this.show = true;
    }
  }

}
