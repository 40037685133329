<p-card>
  
  <div class="header">
    {{title}}
  </div>

  <div class="content">
    {{content}}
  </div>
  
  <div class="footer">
    {{footer}}
  </div>
</p-card>