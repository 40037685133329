<p-dialog 
  header="Create My API Key"
  [visible]="visible"
  (visibleChange)="close($event)"
  [style]="{ width: '50vw' }" 
  (onHide)="(onClose)">

  <section *ngIf="createdKey">
    <h6>Successfully created the key.</h6>
    <p class="warning-block">This is the only place you can view this key. Make sure you save it!</p>
    <pre>{{ createdKey?.apikey }}</pre>
  </section>
  <form *ngIf="!createdKey" [formGroup]="form" (ngSubmit)="submit()">
    <p>Creates an API Key for the current user. This API Key can be used to send messages to a device through a REST endpoint.</p>
    <p class="warning-block">
      This will allow access to this user outside of the application flow. This can be a security risk. Please be very careful about creating these.
    </p>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-12">
        <label for="titleField">Title</label>
        <input id="titleField" type="text" formControlName="title" pInputText required />
        <div *ngIf="!isViewing && form.get('title')?.errors?.nameValidator">
          Title is invalid.
        </div>
        <div *ngIf="!isViewing && form.get('title')?.errors?.minlength">
          Title must be at least 5 characters long.
        </div>
        <div *ngIf="!isViewing && form.get('title')?.errors?.maxlength">
          Title must be at most 50 characters long.
        </div>
        <div *ngIf="!isViewing && form.get('title')?.errors?.duplicateValidatorForApiKeys">
          Title must be unique!
        </div>
      </div>
    </div>
    <p>
      <button pButton label="Create" class="p-button-warning" icon="fas fa-save" type="submit" [disabled]="!form.valid"></button>
    </p>
  </form>
</p-dialog>
