import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { OrgModel, OrgNotesModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  isVam: boolean = false;
  isEditing: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  currentUser?: UserModel;
  parentOrgs: OrgModel[] = [];

  customer: OrgModel = <OrgModel>{};
  customer_id: string = "";
  deleteCustomerModalOpen = false;
  convertToResellerModalOpen = false;
  customers: OrgModel[] = [];

  notes: OrgNotesModel[] = [];

  form = this.fb.group({
    name: [""],
    accountOwner: [""],
    adminContact: [""],
    supportContact: [""],
    parentOrg: this.fb.group({
      id: ['', Validators.required]
    })
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
    }

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.isEditing = false;
      if (params.customer_id) {
        if (!this.isReviewer && !this.isOps) {
          this.isEditing = true;
        }
        this.customer_id = params.customer_id;
        this.onLoad(params.customer_id);
      }

      if (this.isReviewer || this.isOps) {
        this.breadcrumbService.setItems([{ label: 'Customers', routerLink:'/customers'}, { label: 'View' }])
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([{ label: 'Customers', routerLink:'/customers'}, { label: 'Edit' }])
      } else {
        this.breadcrumbService.setItems([{ label: 'Customers', routerLink:'/customers'}, { label: 'Add' }])
      }
    });
    this.onLoad();
  }

  async onLoad(customer_id?: string) {
    try {
      this.blockUI?.start();

      if (this.isReviewer || this.isOps) {
        this.disableControlsForView();
      }

      // Get all resellers
      const response = await this.api.get<{ "orgs": OrgModel[] }>('/orgs');
      const filteredOrgs = response.orgs.filter( o => o.type != 'Customer');
      this.parentOrgs = filteredOrgs;

      if (customer_id) {
        this.customer = await this.api.get<OrgModel>(`/customers/${customer_id}`);

        console.log(`${JSON.stringify(this.customer)}`);

        if (this.customer) {
          const notes: any = await this.api.get(`/org-notes/org/${this.customer.id}`);
          this.notes = notes.orgNotes;
          console.log(`this.notes = ${JSON.stringify(this.notes)}`);

          this.form.patchValue(this.customer);

          if (this.isEditing) {
            this.customers.push(this.customer);
            console.log(`loading this user= ${this.customer.name}`);
          }
        }
      }

    } finally {
      this.blockUI?.stop();
    }
  }

  async onSubmit() {
    try {
      this.blockUI?.start();
      if (this.isEditing) {
        await this.api.put(`/customers/${this.customer?.id}`, this.form.value);
      } else {
        await this.api.post('/customers', this.form.value);
      }
      this.router.navigate(["/customers"]);
    } catch (error) {
      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }

  async onClose() {
    this.router.navigate(["/customers"]);
  }

  async onCloseDialog() {
    console.log(`onCloseDialog()`);
    this.onLoad(this.customer_id);
  }

  disableControlsForView() {
   this.form.get('name')?.disable();
   this.form.get('accountOwner')?.disable();
   this.form.get('adminContact')?.disable();
   this.form.get('supportContact')?.disable();
   this.form.get('parentOrg')?.disable();
  }

}
