
<div class="card">
  <h4>Notes</h4>
  <p-table
    #notesList
    [value]="notes"
    styleClass="p-datatable-sm p-datatable-gridlines"
    dataKey="id"
    [rows]="5"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[5, 10, 15]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['createdAt', 'notes']"
    [(selection)]="notesSelected"
    sortMode="single"
    sortOrder="-1"
    sortField="createdAt"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          pButton
          pRipple
          label="New"
          icon="pi pi-plus"
          type="button"
          class="p-button-success p-mr-2"
          (click)="addNoteModalOpen=true">
        </button>
        <button
          pButton
          pRipple
          label="Delete"
          icon="fas fa-trash"
          type="button"
          class="p-button-danger p-mr-2"
          [disabled]="!notesSelected.length"
          (click)="deleteNoteModalOpen=true">
        </button>

        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="search(notesList, $event)" placeholder="Search keyword" />
        </span>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th>Notes</th>
        <th style="width: 25%">Date</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-notes>
      <tr>
        <td style="vertical-align: top">
          <p-tableCheckbox [value]="notes"></p-tableCheckbox>
        </td>
        <td style="white-space:pre-wrap">{{ notes.note }}</td>
        <td style="vertical-align: top">{{ notes.createdAt  | date: 'short' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<app-add-note
  [(visible)]="addNoteModalOpen"
  [orgId]="orgId"
  (onClose)="onCloseDialog()"
>
</app-add-note>
<app-delete-note
  [(visible)]="deleteNoteModalOpen"
  [notes]="notesSelected"
  (onClose)="onCloseDialog()">
</app-delete-note>