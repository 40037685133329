import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { AppComponent } from '../../../../app.component';
import { OrgModel } from 'src/app/models/org.model';
import { ApiService } from 'src/app/services/api.service';
// <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
// <li app-menuitem *ngFor="let item of model; let i = index;" ></li>
// <li app-menuitem *ngFor="let item of model; let i = index;" >{{item}}</li>
@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>


            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="dashboard">Dashboard</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="my-org">My Org</a>
            </li>
            <li class="nav-item" *ngIf="!isCustomer" routerLinkActive="active">
            <a class="nav-link" routerLink="resellers">Resellers</a>
            </li>
            <li class="nav-item" *ngIf="!isCustomer" routerLinkActive="active">
            <a class="nav-link" routerLink="customers">Customers</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="devices">Devices</a>
            </li>
            <li class="nav-item" *ngIf="isVam" routerLinkActive="active">
            <a class="nav-link" routerLink="device-type">Device Types</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="users">Users</a>
            </li>
            <li class="nav-item" *ngIf="isVam && (isAdmin || isOps)" routerLinkActive="active">
            <a class="nav-link" routerLink="api-keys">Manufacturing API Keys</a>
            </li>
            <li class="nav-item" *ngIf="messageHubEnabled && (isAdmin || isOps)" routerLinkActive="active">
            <a class="nav-link" routerLink="my-api-keys">My API Keys</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="configs">Profiles</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="firmware">Firmware</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="faq">Help</a>
            </li>

        </ul>
    `
})
export class AppMenuComponent implements OnInit {


    currentUser?: UserModel;
    isVam: boolean = false;
    isCustomer: boolean = false;
    isReviewer: boolean = false;
    isAdmin: boolean = false;
    isOps: boolean = false;
    messageHubEnabled: boolean = false;

    model: any[] = [];

    constructor(
        public app: AppComponent,
        private auth: AuthService,
        private api: ApiService,
    ) {

        const userStr = localStorage.getItem('currentUser');
        console.log(`In AppMenuComponent - userStr = `, userStr);

        if (userStr && userStr != "undefined") {
            this.currentUser = JSON.parse(userStr);
            this.isReviewer = this.currentUser?.role === 'Reviewer';
            this.isAdmin = this.currentUser?.role === 'Admin';
            this.isOps = this.currentUser?.role === 'Ops';
        } else {
          this.logOut();
        }
    }

    async load() {
        // Need to retrieve organization again
        // Organization properties saved in localStorage may be obsolete
        const orgId = this.currentUser?.organization.id;
        const organization = await this.api.get<OrgModel>(`/orgs/${orgId}`)

        this.isVam = organization.type === 'VAM';
        this.isCustomer = organization.type === 'Customer';
        this.messageHubEnabled = organization.messageHubEnabled || false;
        console.log(`In AppMenuComponen.load() - messageHubEnabled = `, this.messageHubEnabled);
    }

    async logOut() {
        await this.auth.logOut();

        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');

        window.location.reload();
    }


    ngOnInit() {
        this.load();
        this.model = [
            {
                label: 'Favorites', icon: 'pi pi-fw pi-home',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
            {
                label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                items: [
                    { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                    { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                    { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                    { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
                    { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon' },
                    { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                    { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                    { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                    { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                    { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                    { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                    { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
                    { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                    { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
                    { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
                    { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
                ]
            },
            {
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
                items: [
                    { label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display'] },
                    { label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation'] },
                    { label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox'] },
                    { label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons'] },
                    { label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text'] },
                    { label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets'] },
                    { label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid'] },
                    { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing'] },
                    { label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography'] }
                ]
            },
            {
                label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
                items: [
                    { label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud'] },
                    { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar'] },
                    { label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline'] },
                    { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
                    { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
                    { label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice'] },
                    { label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help'] },
                    { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
                    { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
                    { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
                    { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] }
                ]
            },
            {
                label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
                                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
                                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' }
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
                                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Start', icon: 'pi pi-fw pi-download',
                items: [
                    {
                        label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
                    },
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
                    }
                ]
            }
        ];
    }
}
