// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  amplify: {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_vFQFJXDOB',
      userPoolWebClientId: '43jkv7m0m813uk7d8up3i2plsr'
    }
  },
  api: {
    baseUrl: 'https://api.dev-skylink.net/api/v1'
  },
  grafana: {
    baseUrl: 'https://graph.dev-skylink.net/d-solo/YXTrBkm4z/data-usage?orgId=1&panelId=2&theme=light'
  },
  imt: {
    firmwareVersion: '2.25'
  },
  smh: {
    standardTopicId: '203'
  },
  callLogs: {
    limit: 300
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
