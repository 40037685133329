<div class="p-fluid p-formgrid p-grid">
  <iframe [src]="url" width="100%" height="400" frameborder="0">
  </iframe>
  <div class="p-field p-sm-6">
    <p>Time displayed in UTC</p>
  </div>
  <div class="p-field p-sm-6">
  </div> 
  <div [formGroup]="parentGroup" class="p-field p-sm-6">
    <p>{{ label }}</p>
    <p-dropdown id="formDropDownFieldId" [options]="formOptions" [formControlName]="formControlDropdownName"
      optionLabel="name" optionValue="value" scrollHeight="300px">
    </p-dropdown>
  </div>
  <!-- <div *ngIf="parentGroup?.get(formControlDropdownName)?.value === 'custom'" class="p-field p-sm-4">
    <p>From</p>
    <p-calendar inputId="basic" [(ngModel)]="dateFromName" [showIcon]="true"></p-calendar>
  </div>
  <div *ngIf="parentGroup?.get(formControlDropdownName)?.value === 'custom'" class="p-field p-sm-4">
    <p>To</p>
    <p-calendar inputId="basic" [minDate]="dateFromName" [defaultDate]="dateFromName" [(ngModel)]="dateToName" [showIcon]="true"></p-calendar>
  </div>  -->
</div>
<div class="p-fluid p-formgrid p-grid">
  <div *ngIf="parentGroup?.get(formControlDropdownName)?.value === 'custom'" class="p-field p-sm-6">
    <p>From</p>
    <p-calendar inputId="basic" [(ngModel)]="dateFromName" [showIcon]="true"></p-calendar>
  </div>
  <div *ngIf="parentGroup?.get(formControlDropdownName)?.value === 'custom'" class="p-field p-sm-6">
    <p>To</p>
    <p-calendar inputId="basic" [minDate]="dateFromName" [defaultDate]="dateFromName" [(ngModel)]="dateToName" [showIcon]="true"></p-calendar>
  </div>
</div>
<div class="p-fluid p-formgrid p-grid">
  <div class="p-field p-sm-2">
    <button type="button" [disabled]="checkIfDisabled()" pButton pRipple class="p-button"
      (click)="applyClickAction()" label="{{ applyClickLabel }}"> </button>
  </div>
</div>