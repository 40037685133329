import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function tagValidator(tagRegEx?: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      if (!tagRegEx) {
        tagRegEx = /^[\dA-Za-z_-]+$/;
      }
      console.log("tagRegEx = ", tagRegEx);
      console.log("control.value = ", control.value);
      const tagMatch = tagRegEx.test(control.value);
      console.log("tagMatch = ", tagMatch);
      res = !tagMatch ? { 
        tagValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
