import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';

/**
 * Implements unauth guard as shown in
 * @see https://github.com/mlabieniec/AngularMaterialPWA/blob/master/partTwo/src/app/auth/unauth.guard.ts
 */
@Injectable({
  providedIn: 'root'
})
export class UnauthGuard implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return Auth.currentAuthenticatedUser()
      .then(() => {
        this._router.navigate(['devices']);
        return false;
      })
      .catch(() => true);
  }

}
