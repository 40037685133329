import { OrgModel } from "./org.model";

export interface UserModel {
  id?: string;
  createDate?: Date;
  username: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  isEnabled: boolean;
  role: "User" | "Admin" | "Ops" | "AccountManager" | "Reviewer";
  organization: OrgModel
};

export const Roles = [
  { id: 1, label: 'Admin', value: 'Admin' },
  { id: 2, label: 'Ops', value: 'Ops' },
  { id: 3, label: 'Account Manager', value: 'AccountManager' },
  { id: 4, label: 'Reviewer', value: 'Reviewer'}
];

