<div class="container">
  <h1>Devices</h1>
  <p class="help-block">
    This is a list of all the devices present in the system.
  </p>
  <div class="card">
  <p-table
    #devicesList
    [value]="devices"
    [lazy]="true"
    (onLazyLoad)="loadDevices($event)"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-striped"
    responsiveLayout="scroll"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="globalFilterFields"
    [selection]="devicesSelected" 
    (selectionChange)="onSelectionChange($event)" 
    sortMode="single"
    sortField="name"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div>
        <div class="p-d-flex p-flex-row-reverse p-mb-4">
          <button *ngIf="isOps || isVam"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            label="IMT Report"
            (click)="exportExcel()"
            class="p-button-success p-mr-2"
            pTooltip="XLS"
            tooltipPosition="top">
          </button>
          <!-- NOTE:  We may want to add export CSV and PDF files later!!!
            <button
            type="button"
            pButton pRipple
            icon="pi pi-file"
            label="CSV"
            (click)="devicesList.exportCSV()"
            class="p-mr-2"
            pTooltip="CSV"
            tooltipPosition="top">
          </button> -->
          <!-- <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-pdf"
            label="PDF"
            (click)="exportPdf()"
            class="p-button-warning p-mr-2"
            pTooltip="PDF"
            tooltipPosition="bottom">
          </button> -->
        </div>
        <div class="p-d-flex">
          <button *ngIf="isOps" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" routerLink="/devices/add"></button>
          <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(devicesList)"></button>
          <button
            *ngIf="!isCustomer && !isReviewer"
            pButton
            pRipple
            label="Assign Org"
            class="p-button-warning p-mr-2"
            icon="far fa-plus-square"
            (click)="assignOrgModalOpen = true"
            [disabled]="!devicesSelected.length">
          </button>
          <button
            *ngIf="!isReviewer"
            pButton
            pRipple
            label="Assign Profiles"
            class="p-button-help p-mr-2"
            icon="far fa-plus-square"
            (click)="assignConfigModalOpen = true"
            [disabled]="!devicesSelected.length">
          </button>
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" id="globalSearch" [(ngModel)]="searchInput" (input)="search(devicesList, $event)" placeholder="Search keyword" />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="!isReviewer" style="width: 3rem">
        </th>
        <th>
          Name
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th *ngIf="isVam || isOps">
          IMT Supported
          <!-- <p-columnFilter field="firmwareVersion" matchMode="equals" [showMenu]="false"></p-columnFilter> -->
          <!-- <p-columnFilter field="firmwareVersion" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Firmware Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="firmwareVersions" placeholder="Any" (onChange)="filter($event.value)" optionLabel="version">
                    <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                            <span class="ml-1">{{option.version}}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </ng-template>
        </p-columnFilter> -->
        </th>
        <th *ngIf="isVam || isOps" style="text-align: center">
          IMT Enabled
          <p-columnFilter type="boolean" field="cmid_mapping_id" display="menu"></p-columnFilter>
        </th>
        <th>
          Asset Tag
          <p-columnFilter type="text" field="assetTag" display="menu"></p-columnFilter>
        </th>
        <th>
          Hardware
          <p-columnFilter type="text" field="deviceType.name" display="menu"></p-columnFilter>
        </th>
        <th>
          Iridium IMEI
          <p-columnFilter type="text" field="iridium_imei" display="menu"></p-columnFilter>
        </th>
        <th>
          Cellular IMEI
          <p-columnFilter type="text" field="lte_imei" display="menu"></p-columnFilter>
        </th>
        <th>
          Org
          <p-columnFilter type="text" field="org.name" display="menu"></p-columnFilter>
        </th>
        <th>
          Device Profile
          <p-columnFilter type="text" field="deviceConfig.name" display="menu"></p-columnFilter>
        </th>
        <th>
          Voice Profile
          <p-columnFilter type="text" field="voiceConfig.name" display="menu"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-device>
      <tr>
        <td *ngIf="!isReviewer">
          <p-tableCheckbox [value]="device"></p-tableCheckbox>
        </td>
        <td>
          <a [routerLink]="['/devices/edit', device.id]">{{ device.name || 'No Name Assigned' }}</a>
          <i class="p-d-block">{{ device.serial }}</i>
        </td>
        <td *ngIf="isVam || isOps" >
          <span class="customer-badge" [ngClass]="{'status-success': device.firmwareVersion >= imtFirmwareVersion, 'status-danger': device.firmwareVersion && device.firmwareVersion < imtFirmwareVersion, 'status-unknown': device.firmwareVersion === null}">FW: {{(device.firmwareVersion || 'Not Reported')}}</span>
        </td>
        <td *ngIf="isVam || isOps" style="text-align: center; width: 100%">
          <i class="pi" [ngClass]="{'true-icon pi-check-circle': device.cmidMappingId, 'false-icon pi-times-circle': !device.cmidMappingId}"></i>
        </td>
        <td>
          <span *ngIf="device.assetTag ; else assetTagNotAssigned">
            {{ device.assetTag }} <br/>
          </span>
          <ng-template #assetTagNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>{{ device.deviceType.name }}<br/></td>
       <td>
          <span *ngIf="device.iridium_imei; else iridium_imeiNotAssigned">
            {{ device.iridium_imei }}
          </span>
          <ng-template #iridium_imeiNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="device.lte_imei; else lte_imeiNotAssigned">
            {{ device.lte_imei }}
          </span>
          <ng-template #lte_imeiNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="device.org?.name; else orgNotAssigned">
            {{ device.org?.name }}
            <!-- <br />
            ({{ device.customerOrg?.updatedAt | date: 'longDate' }}) -->
          </span>
          <ng-template #orgNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="device.deviceConfig?.name; else deviceConfigNotAssigned">
            {{ device.deviceConfig?.name }} <br />
          </span>
          <ng-template #deviceConfigNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="device.voiceConfig?.name; else voiceConfigNotAssigned">
            {{ device.voiceConfig?.name }} <br />
          </span>
          <ng-template #voiceConfigNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
<app-device-assign-reseller [(visible)]="assignResellerModalOpen" [devices]="devicesSelected" (onClose)="onClose()"></app-device-assign-reseller>
<app-device-assign-customer [(visible)]="assignCustomerModalOpen" [devices]="devicesSelected" (onClose)="onClose()"></app-device-assign-customer>
<app-device-assign-org [(visible)]="assignOrgModalOpen" [devices]="devicesSelected" (onClose)="onClose()"></app-device-assign-org>
<app-device-assign-config [(visible)]="assignConfigModalOpen" [devices]="devicesSelected" (onClose)="onClose()"></app-device-assign-config>
