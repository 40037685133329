import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { OrgModel } from 'src/app/models/org.model';
import { ApiService } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';

@Component({
  templateUrl: './resellers.component.html',
  styleUrls: ['./resellers.component.scss']
})
export class ResellersComponent implements OnInit {

  isVam: boolean = false;
  isLoading: boolean = false;
  currentUser?: UserModel;
  isReviewer: boolean = false;
  isOps: boolean = false;

  resellers: OrgModel[] = [];
  resellersSelected?: OrgModel[] = [];

  totalRecords = 0;
  take = 50;
  skip = 0;
  selectAll = false;
  globalFilterFields=['adminContact', 'name', 'parentOrg.name', 'supportContact'];
  searchInput = '';
  clearing = false;
  lastLazyEvent: any = ''

  deleteResellerModalOpen = false;

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Resellers'}]);
    this.lastLazyEvent = '';
    this.clearing = false;
  }

  onSelectionChange(value = []) {
    this.selectAll = value.length === this.totalRecords;
    this.resellersSelected = [...value];
    console.log(`resellersSelected=${this.resellersSelected}`);
  }


  async loadResellers(loadEvent: LazyLoadEvent) {
    try {
      this.isLoading =true;
      this.skip = loadEvent.first || 0;
      this.take = loadEvent.rows || this.take;

      if (this.clearing) {
        loadEvent.globalFilter = '';
        loadEvent.filters = {};
        return;
      }

      // Skip any possible duplicate event
      console.log(`LAST=${this.lastLazyEvent}`);
      if (this.lastLazyEvent === JSON.stringify(loadEvent)) {
        console.log(`skip`);
        return;
      }

      this.lastLazyEvent = JSON.stringify(loadEvent);
      console.log(`THIS IS THE EVENT: ${JSON.stringify(loadEvent)}`);
      await this.load(JSON.stringify(loadEvent));

    } finally {
      this.isLoading = false;
    }
  }

  async load(loadEvent: any) {
    let globalFilterFieldsQueryParm = '';

    if (this.globalFilterFields) {
      globalFilterFieldsQueryParm = `&globalFilterFields=${JSON.stringify(this.globalFilterFields)}`
    }

    console.log(`globalFilterFieldsQueryParm: ${JSON.stringify(globalFilterFieldsQueryParm)}`);
    this.api.get(`/resellers?paginationParm=${loadEvent}${globalFilterFieldsQueryParm}`).then((rsp: any)=> {
      
      // Filter out user's ORG
      let orgs = [...rsp.orgs];
      const userStr = localStorage.getItem('currentUser');
      if (userStr) {
        const currentUser = JSON.parse(userStr);
        orgs = orgs.filter(org => org.id !== currentUser.organization.id);
      }
      this.totalRecords = rsp.total;
      this.resellers = orgs;
    });

  }

  // Reloads page with previous 'configuration' 
  async reload() {
    this.api.get(`/resellers?take=${this.take}&skip=${this.skip}`).then((rsp: any)=> {
     
      // Filter out user's ORG
      let orgs = [...rsp.orgs];
      const userStr = localStorage.getItem('currentUser');
      if (userStr) {
        const currentUser = JSON.parse(userStr);
        orgs = orgs.filter(org => org.id !== currentUser.organization.id);
      }
      this.totalRecords = rsp.total;
      this.resellers = orgs;
    });
  }


  clear(table: Table) {
    console.log(`clear`);
    this.searchInput='';
    this.clearing = true;
    table.clear();
    table.filterGlobal('', 'contains'); 
    this.clearing = false; // reset flag after clearing is done.
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  async onClose() {
    this.resellersSelected = [];
    await this.reload();
  }
}
