import { ThisReceiver } from '@angular/compiler';
import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ConfigModel } from 'src/app/models/config.model';
import { VoiceConfigModel } from 'src/app/models/voice-config.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';

@Component({
  selector: 'app-config-history',
  templateUrl: './config-history.component.html',
  styleUrls: ['./config-history.component.scss']
})
export class ConfigHistoryComponent implements 
                          OnInit, OnChanges, AfterContentInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() configType: string = '';
  @Input() dConfigs: ConfigModel[] = [];
  @Input() vConfigs: VoiceConfigModel[] = []; 
// @Input() groupId: string = '';
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  configSelected?: ConfigModel;
  configs: any[] = [];
//  deviceConfigs: ConfigModel[] = [];
  configName: string = "";

  constructor(
    private router: Router,
    private api: ApiService,
    private messageService: MessageService
  ) { }
  ngAfterContentInit(): void {
    console.log("ngOngAfterContentInit()");
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges - changes = ", changes);
  }

  ngOnInit(): void {
    console.log("ngOnInit()");
//    this.load();
  }

  async onShow() {
    console.log("config-history - onShow()");
    console.log("this.configType = ", this.configType);
    console.log("this.dConfigs = ", this.dConfigs);
    console.log("this.vConfigs = ", this.vConfigs);
    this.load();
  }

  async load() {
    console.log("config-history - load()");
    try {
      this.isLoading = true;
      this.isLoading = true;
      if (this.configType === 'device') {
        this.configs = this.dConfigs;
        this.configName = this.dConfigs[0].name;
      } else if (this.configType === 'voice') {
        this.configs = this.vConfigs;
        this.configName = this.vConfigs[0].name;
      }

      // if (this.configs) {
      //   this.configName = this.configs[0].name;
      // } else if (this.vConfigs) {
      //   this.configName = this.vConfigs[0].name;
      // } else {
      //   const rspConfig = await this.api.get< { "configs": ConfigModel[] } >(`/configs/group/${this.groupId}`);
      //   console.log("config-history - rspConfig = ", rspConfig);
      //   this.configs = rspConfig.configs;
      //   this.configName = rspConfig.configs[0].name;
      // }
    } finally {
      this.isLoading = false;
    }
  }

  async loadVersion(id: string) {
    console.log("loadVersion - id = ", id);
    if (this.configType === 'voice')
      this.router.navigate(['/configs/edit-vconfig', id, { index : 0 }]);
    else 
      this.router.navigate(['/configs/edit', id, { index : 0 }]);
    this.visibleChange.emit(false);
  }

  async onCloseDialog() {
    console.log("onCloseDialog()");
    // this.deviceConfigSelected = undefined;
    // this.voiceConfigSelected = undefined;
  }
}
