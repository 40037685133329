<p-dialog
  header="Assign Device and/or Voice Profile"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '50vw' }"
  (onHide)="onCloseDialog()">
  <p>Assign a Device/Voice Profile to a device or multiple devices.</p>
  <!-- <p class="warning-block">Resellers are not able to see customers assigned to different resellers. If you change reseller and leave customers assigned, it may have unknown consequences.</p> -->
  <p>Would you like to assign profiles to the following devices?</p>
  <ul class="two-column">
    <li *ngFor="let device of devices">{{ device.serialNumber.toString(16) }} {{ device.name }}</li>
  </ul>

  <div class="p-fluid">
    <div class="p-field">
      <label>Device Profile to assign devices to:</label>
      <p-dropdown
        [options]="deviceConfigs"
        [(ngModel)]="deviceConfigSelected"
        placeholder="Select a Device Profile"
        optionLabel="name"
        [showClear]="true"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>
  <br/>
  <div class="p-fluid">
    <div class="p-field">
      <label>Voice Profile to assign devices to:</label>
      <p-dropdown
        [options]="voiceConfigs"
        [(ngModel)]="voiceConfigSelected"
        placeholder="Select a Voice Profile"
        optionLabel="name"
        [showClear]="true"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (onClick)="assign()" [disabled]="!deviceConfigSelected && !voiceConfigSelected" label="Ok" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
