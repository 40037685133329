import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AppComponent implements OnInit {
  user: CognitoUserInterface | undefined;
  authState?: AuthState;

  topbarTheme = 'light';

  menuTheme = 'dim';

  layoutMode = 'light';

  menuMode = 'horizontal';

  isRTL = false;

  inputStyle = 'outlined';

  ripple: boolean = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    auth: AuthService
  ) {
    auth.configure();
  }

  ngOnInit() {
    // Turn on ripple button effect
    // see https://www.primefaces.org/primeng/showcase/#/setup
    this.primengConfig.ripple = true;
  }
}
