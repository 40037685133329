<div class="container">
  <h1>Users</h1>
  <p class="help-block">Create and manage users throughout the system.</p>
  <div class="card">
  <p-table
    #usersList
    [value]="users"
    [lazy]="true"
    (onLazyLoad)="loadUsers($event)"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="globalFilterFields"
   
    sortMode="single"
    sortField="username"
    [selection]="usersSelected" 
    (selectionChange)="onSelectionChange($event)" 
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button *ngIf="!isReviewer"
          pButton
          pRipple
          label="New"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          routerLink="/users/add">
        </button>
        <button *ngIf="!isReviewer"
          pButton
          pRipple
          label="Delete"
          icon="fas fa-trash"
          class="p-button-danger p-mr-2"
          (click)="deleteUserModalOpen = true"
          [disabled]="!usersSelected.length">
        </button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text"  id="globalSearch" [(ngModel)]="searchInput" (input)="search(usersList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="!isReviewer" style="width: 3rem">
        </th>
        <th>Name</th>
        <th>Contact</th>
        <th>Role
          <p-columnFilter field="role" matchMode="equals" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"showButtonBar="false" display="menu" class="p-ml-auto">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                [options]="roleOptions"
                optionLabel="label"
                optionValue="value"
                (onChange)="filter($event.value)"
                placeholder="Choose your filter">
                  <ng-template let-option pTemplate="item">
                      <span>{{ option.label }}</span>
                  </ng-template>
              </p-dropdown>           
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          Organization
          <p-columnFilter type="text" field="organization.name" display="menu"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td *ngIf="!isReviewer">
          <p-tableCheckbox
            [value]="user"
            [disabled]="this.currentUsername === user.username || isParentRole(user.organization.id, user.role)">
          </p-tableCheckbox>
        </td>
        <td>
          <a [routerLink]="['edit', user.id]">{{ user.username }}</a>
          <i class="p-d-block">{{ user.name }}</i>
          <span *ngIf="!user.isEnabled" class="badge bg-danger">DISABLED</span>
        </td>
        <td>
          <span class="p-d-block">{{ user.email }}</span>
          <span class="p-d-block">{{ user.phone }}</span>
          <address>{{ user.address }}</address>
        </td>
        <td>
          <span>{{ displayableRole(user.role) }}</span>
        </td>
        <td>
          <div *ngIf="user.organization">{{ user.organization.name }}</div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
<app-user-delete-user [(visible)]="deleteUserModalOpen" [users]="usersSelected"  (onClose)="onClose()"></app-user-delete-user>
