<p-dialog header="Delete Messaging Channel" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Do you want to delete the following Messaging HUB Channels ?</p>
  <ul class="one-column">
    <li *ngFor="let channel of msgChannelsToBeRemoved">
      <p><b>ID:</b> {{ channel.value.channelId }}   <b>Name:</b> {{ channel.value.channelName || 'Unknown' }} </p>
    </li>
  </ul>

  <ng-template pTemplate="footer">
    <p-button icon="fas fa-trash"
              (onClick)="delete()"
              label="Delete"
              styleClass="p-button-danger">
    </p-button>
  </ng-template>

</p-dialog>
