<p-dialog
  header="Firewall Rule"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '30vw' }"
  (onShow)="onShow()"
  (onHide)="onCloseDialog()">
  <!-- TODO use multiple headers to create view etc.-->
  <!-- <h3 *ngIf="isViewing || isReviewer">Firewall Rule</h3>
  <h3 *ngIf="!isViewing && !isAdding">Edit Firewall Rule</h3>
  <h3 *ngIf="!isViewing && isAdding && !isReviewer">Add Firewall Rule</h3> -->

  <form [formGroup]="addFirewallAdvancedRuleForm">
    <div class="p-fluid ">
      <div class="p-field p-sm-9">
        <label for="actionField">Action</label>
        <input id="actionField" type="text" formControlName="action" pInputText optional/>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('action').value != action }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.action)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="sourceField">Source</label>
        <input id="sourceield" type="text" formControlName="source" pInputText optional/>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('source').value != source }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.source)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="ipAddressField">IP Address</label>
        <input id="ipAddressField" type="text" formControlName="ipAddress" pInputText required/>
        <div *ngIf="!isViewing && addFirewallAdvancedRuleForm.get('ipAddress')?.errors?.ipv4Validator">
          IP Address is invalid.
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('ipAddress').value != ipAddress }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.ipAddress)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="sourcePortField">Source Port</label>
        <input id="sourcePortField" type="text" formControlName="sourcePort" pInputText required/>
        <div *ngIf="!isViewing && addFirewallAdvancedRuleForm.get('sourcePort')?.errors?.portValidator">
          Source Port is invalid (0-65535)
        </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('sourcePort').value != sourcePort }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.sourcePort)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <label for="destinationPortField">Destination Port</label>
        <input id="destinationPortField" type="text" formControlName="destinationPort" pInputText required/>
         <div *ngIf="!isViewing && addFirewallAdvancedRuleForm.get('destinationPort')?.errors?.portValidator">
          Destination Port is invalid (0-65535)
         </div>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('destinationPort').value != destinationPort }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.destinationPort)}}
        </small>
      </div>
      <div class="p-field p-sm-9">
        <app-form-dropdown class="p-field p-sm-3"
          [parentGroup]="addFirewallAdvancedRuleForm"
          formControlName="protocol"
          [isViewing]="isViewing"
          label="Protocol"
          [options]="protocolOptions"
          [baseValue]="protocol"
          ngDefaultControl
          [placeholder]="protocolPlaceHolder"
          required
        >
        </app-form-dropdown>
      </div>
      <div class="p-field p-sm-9">
        <label for="commentField">Comment</label>
        <input id="Field" type="text" formControlName="comment" pInputText optional/>
        <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': addFirewallAdvancedRuleForm.get('comment').value != comment }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(advancedRuleBase?.comment)}}
        </small>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button
      *ngIf="!isViewing && !isReviewer"
      pButton
      (click)="save()"
      label="Save"
      class="p-button-warning p-mr-2"
      icon="fas fa-save"
      [disabled]="!addFirewallAdvancedRuleForm.valid || !addFirewallAdvancedRuleForm.dirty">
    </button>
  </ng-template>
</p-dialog>
