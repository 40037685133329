<div class="container">
  <h1>Manufacturing Api Keys</h1>
  <p class="help-block">
    This is a list of all the Manufacturing API Keys currently present in the system.
  </p>
  <div class="card">
  <p-table
    #keysList
    [value]="keys"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['purpose', 'user.name', 'org.name']"
    sortMode="single"
    sortField="user.name"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"  (click)="createKeyModalOpen = true"></button>
        <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(keysList)"></button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="search(keysList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th>User</th>
        <th>Organization</th>
        <th>Purpose</th>
        <th>Created</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-key>
      <tr>
        <td style="width: 3rem">
          <button pButton pRipple icon="pi pi-times" class="p-button-sm p-button-rounded p-button-danger p-mr-2"  (click)="deleteKey(key)"></button>
        </td>
        <td>
          {{ key.user.name }}
        </td>
        <td>
          <span *ngIf="key.org?.name; else orgNotAssigned">
            {{ key.org.name }} <br />
          </span>
          <ng-template #orgNotAssigned>
            <span class="customer-badge status-warning">Not Assigned</span>
          </ng-template>
        </td>
        <td>{{ key.purpose || "None Provided" }}</td>
        <td>{{ key.createdAt | date: 'longDate' }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
<app-api-keys-create [(visible)]="createKeyModalOpen" (onClose)="load()"></app-api-keys-create>
