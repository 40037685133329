<div class="container">
  <router-outlet></router-outlet>
  <h1>Resellers</h1>
  <p class="help-block">Resellers control all aspects of customers and devices.</p>
  <div class="card">
  <p-table
    #resellersList
    [value]="resellers"
    [lazy]="true"
    (onLazyLoad)="loadResellers($event)"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-striped"
    dataKey="id"
    [rows]="50"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="globalFilterFields"
    [selection]="resellersSelected" 
    (selectionChange)="onSelectionChange($event)" 
    sortMode="single"
    sortField="name"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button *ngIf="!isReviewer && !isOps"
          pButton
          pRipple
          label="New"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          routerLink="/resellers/add">
        </button>
        <button *ngIf="!isReviewer && !isOps" pButton pRipple label="Delete" icon="fas fa-trash" class="p-button-danger p-mr-2" [disabled]="!resellersSelected.length" (click)="deleteResellerModalOpen=true"></button>
        <button pButton pRipple label="Clear" class="p-button p-mr-2" icon="pi pi-filter-slash" (click)="clear(resellersList)"></button>

        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" id="globalSearch" [(ngModel)]="searchInput" (input)="search(resellersList, $event)" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th  *ngIf="!isReviewer" style="width: 3rem">
        </th>
        <th>Name</th>
        <!-- Consider changing Reseller Org to Allow for VAM too -->
        <th *ngIf="isVam">Account Owner</th>
        <th>Reseller Org</th>
        <th>Admin Contact</th>
        <th>Support Contact</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-resellers>
      <tr>
        <td *ngIf="!isReviewer">
          <p-tableCheckbox [value]="resellers"></p-tableCheckbox>
        </td>
        <td>
          <a [routerLink]="['/resellers/edit', resellers.id]" class="d-block">{{ resellers.name }}</a>
        </td>
        <td *ngIf="isVam">
          {{ resellers.accountOwner || 'None'}}
        </td>
        <td>
          {{ resellers.parentOrg.name }} ({{ resellers.parentOrg.type }})
        </td>
        <td>
          <address>
            {{ resellers.adminContact }}
          </address>
        </td>
        <td>
          <address>
            {{ resellers.supportContact }}
          </address>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
<app-delete-reseller [(visible)]="deleteResellerModalOpen" [resellers]="resellersSelected"  (onClose)="onClose()"></app-delete-reseller>
