import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function nameValidator(nameRegEx?: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      if (!nameRegEx) {
        nameRegEx = /^[0-9a-zA-Z._()#\-\/ ]*$/;
      }
      console.log("nameRegEx = ", nameRegEx);
      console.log("control.value = ", control.value);
      const nameMatch = nameRegEx.test(control.value);
      console.log("nameMatch = ", nameMatch);
      res = !nameMatch ? { 
        nameValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
