import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() title: string = '';
  @Input() footer: string = '';
  @Input() chartData: any;
  @Input() options: any;

  constructor() { }

  ngOnInit(): void {
  }

}
