export class Utils {

  /**
   * Receives an array and returns the object that matches a given condition
   *
   * @param arr
   * @param callback
   * @returns
   */
  static altFind(arr : any, callback : any) {
    for (var i = 0; i < arr.length; i++) {
      var match = callback(arr[i]);
      if (match) {
        return arr[i];
      }
    }
  }

}
