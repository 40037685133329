import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { UserModel } from 'src/app/models/user.model';
import { DeviceTypeModel, ProductVariant } from 'src/app/models/device-type.model';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { Utils } from 'src/app/util/utils';

@Component({
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.scss']
})
export class DeviceTypeComponent implements OnInit {

  ProductVariantEnum =  ProductVariant;

  deviceTypes: DeviceTypeModel[] = [];
  loading: boolean = false;
  currentUser?: UserModel;
  isReviewer: boolean = false;
  isAccountManager: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isAccountManager = (this.currentUser?.role === 'AccountManager' ||
                               this.currentUser?.role === 'User');
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Device Types'}]);
    this.load();
  }

  async load() {
    try {
      this.loading = true;

      const rsp = await this.api.get<{"deviceTypes": DeviceTypeModel[]}>("/device-types");
      this.deviceTypes = rsp.deviceTypes;

      this.deviceTypes.forEach( (device: DeviceTypeModel) => {
        if (device.productVariant === ProductVariant.Land) {
          device.productVariantDisplay = 'Land';
        } else if (device.productVariant === this.ProductVariantEnum.LandIoT) {
          device.productVariantDisplay = 'Land IoT';
        } else if (device.productVariant === ProductVariant.Maritime) {
          device.productVariantDisplay = 'Maritime';
        } else if (device.productVariant === this.ProductVariantEnum.MaritimeIoT) {
          device.productVariantDisplay = 'Maritime IoT';
        } else if (device.productVariant === this.ProductVariantEnum.Aviation) {
          device.productVariantDisplay = 'Aviation';
        } else if (device.productVariant === this.ProductVariantEnum.AviationIoT) {
          device.productVariantDisplay = 'Aviation IoT';
        }
      });
    } finally {
      this.loading = false;
    }
  }

  //#region table handling
  clear(table: Table) {
    table.clear();
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }
}
