<div class="container">
  <h1>Account Settings</h1>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="card">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="nameField">Name</label>
        <input id="nameField" type="text" formControlName="name" pInputText required />
      </div>
    </div>
    <h4>Contact</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="emailField">Email</label>
        <input id="emailField" type="email" formControlName="email" pInputText required />
      </div>
      <div class="p-field p-sm-6">
        <label for="phoneField">Phone</label>
        <input id="phoneField" type="tel" formControlName="phone" pInputText required />
      </div>
      <div class="p-field p-col-12">
        <label for="addressField">Address</label>
        <textarea
          id="addressField"
          formControlName="address"
          pInputTextarea
          [autoResize]="true"
          placeholder="123 Main Street.
Springfield, CA 1234"
        ></textarea>
      </div>
    </div>
    </div>
    <p>
      <button pButton label="Save" class="p-button-warning" icon="fas fa-save" type="submit" [disabled]="!form.valid || !form.dirty"></button>
    </p>
  </form>
</div>
