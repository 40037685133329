import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfigModel } from 'src/app/models/config.model';
import { VoiceConfigModel } from 'src/app/models/voice-config.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})

export class ConfigsComponent implements OnInit {

  currentUser?: UserModel;
  isVam: boolean = false;
  isCustomer: boolean = false;
  isReviewer: boolean = false;
  isLoading = false;
  configs: ConfigModel[] = [];
  vConfigs: VoiceConfigModel[] = [];
  index: any = 0;

  configsSelected: ConfigModel[] = [];
  vConfigsSelected: VoiceConfigModel[] = [];

  deleteConfigModalOpen = false;
  deleteVConfigModalOpen = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private breadcrumbService: AppBreadcrumbService,
    private route: ActivatedRoute
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isCustomer = this.currentUser?.organization.type === 'Customer';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Profiles' }]);
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;

      this.route.params.subscribe(params => {
        if (params.index) {
          this.index = params.index;
        }
      });
      console.log("index=", this.index);

      // Device Configs
      const deviceConfig = await this.api.get<{ "configs": ConfigModel[] }>("/configs/latest");
      console.log("deviceConfig = ", deviceConfig);
      this.configs = deviceConfig.configs;

      // Voice Configs
      const voiceConfigs = await this.api.get<{ "voiceConfigs": VoiceConfigModel[] }>("/voice-configs/latest");
      console.log("voiceConfig = ", voiceConfigs);
      this.vConfigs = voiceConfigs.voiceConfigs;
    } finally {
      this.isLoading = false;
    }
  }

  //#region table handling
  clear(table: Table) {
    table.clear();
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

  onClose () {
    this.configsSelected = [];
    this.vConfigsSelected = [];
    this.load();
  }
}
