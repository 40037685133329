import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError, from, Observable, of } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Auth } from 'aws-amplify';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

/**
 * Implement HTTP Interceptor based on this
 * @see https://medium.com/@umashankar.itn/aws-cognito-hosted-ui-with-angular-and-asp-net-core-5ddf351680a5
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf(environment.api.baseUrl) == 0) {
      return this.getToken().pipe(mergeMap(token => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(request).pipe(catchError(x => this.handleError(x))) as Observable<HttpEvent<any>>;
      }));
    }
    return next.handle(request);
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error && error.status == 401) {
      window.location.reload(); // Reload the page to cleanup resources? or just navigate
      return of(error.message);
    }
    return throwError(error);
  }

  private getToken() {
    return from(
      new Promise((resolve, reject) => {
        Auth.currentSession()
          .then((session) => {
            if (!session.isValid()) {
              resolve(null);
            } else {
              resolve(session.getIdToken().getJwtToken());
            }
          })
          .catch(err => {
            return resolve(null)
          });
      })
    );
  }
}
