import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() orgId: string = "";
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;

  addNoteForm = this.fb.group({
    noteText: [{ value: null, disabled: false }],
  });

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log(`ngOnInit()`);
    this.load();
  }

  async load() {
  }


  async save() {
    try {
      console.log(`save()`);
      this.blockUI?.start();

      const orgNotes = {
        note: this.addNoteForm.value.noteText,
        orgId: this.orgId,
      }

      console.log(`POST: ${orgNotes}`);
      const rsp = await this.api.post(`/org-notes`, orgNotes);

      console.log(`rsp: ${JSON.stringify(rsp)}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
//      const errors = createFailureToDeleteMsg(err, this.notes, 'notes');
      this.messageService.addAll(err);
    } finally {
      this.addNoteForm.reset();
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    console.log(`onCloseDialog()`);
    this.addNoteForm.reset();
  }
}
