<p-dialog header="Profile Descriptions" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <div class="p-field">
    <h6>0. Blocked</h6>
    <div class="p-field">Blocks all network traffic to the internet.</div>
  </div>

  <div class="p-field">
    <h6>1. Unrestricted</h6>
    <div class="p-field">Allows all outgoing (and response) network traffic to the Internet. This is the least restrictive
      option and is not recommended as it could incur high data usage fees.</div>
  </div>

  <div class="p-field">
    <h6>2. SkyLink Email</h6>
    <div class="p-field">Allows for the following Compressed (optimized for SkyLink) Email services:</div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="p-m-2"></i>teleport-mail</div>
    </div>
  </div>


  <div class="p-field">
    <h6>3. Weather</h6>
    <div class="p-field">Allows the same network traffic as SkyLink Email and adds the following weather apps:</div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="p-m-2"></i>LuckGrib</div>
      <div class="p-col box"><i class="p-m-2"></i>PocketGrib</div>
      <div class="p-col box"><i class="p-m-2"></i>PredictWind</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="p-m-2"></i>SEAMAN</div>
      <div class="p-col box"><i class="p-m-2"></i>Squid</div>
      <div class="p-col box"><i class="p-m-2"></i>Theyr</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="p-m-2"></i>TIMEZERO</div>
      <div class="p-col box"><i class="p-m-2"></i>Windy.app</div>
      <div class="p-col box"><i class="p-m-2"></i>Windy.com</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="p-m-2"></i>XyGrib</div>
    </div>
  </div>

  <div class="p-field">
    <h6>4. Chatty</h6>
    <div class="p-field">Allows network traffic as Weather and adds the following chat apps (text only):</div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fas fa-comment fa-fw signal-chat p-m-2"></i>Signal</div>
      <div class="p-col box"><i class="fab fa-telegram fa-fw p-m-2"></i>Telegram</div>
      <div class="p-col box"><i class="fab fa-weixin fa-fw p-m-2"></i>WeChat</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-whatsapp fa-fw p-m-2"></i>WhatsApp</div>
      <div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wickr</div>
      <div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wire</div>
    </div>
  </div>

  <div class="p-field">
    <h6>5. Social</h6>
    <div class="p-field">Allows the same network traffic as Chatty and adds the following email, social networks, chat, video and news apps:</div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>AP News</div>
      <div class="p-col box"><i class="fab fa-apple fa-fw p-m-2"></i>Apple Mail</div>
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>BBC News</div>
    </div>
      <div class="p-field p-grid">
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>CNN News</div>
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Economist</div>
      <div class="p-col box"><i class="fab fa-facebook fa-fw p-m-2" ></i>Facebook</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-flipboard fa-fw p-m-2"></i>Flipboard</div>
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Financial Times</div>
      <div class="p-col box"><i class="fab fa-google fa-fw p-m-2"></i>Gmail</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>The Guardian</div>
      <div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Huffington Post</div>
      <div class="p-col box"><i class="fab fa-instagram fa-fw p-m-2"></i>Instagram</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-linkedin fa-fw p-m-2"></i>LinkedIn</div>
      <div class="p-col box"><i class="fab fa-microsoft fa-fw p-m-2"></i>Outlook/Office 365</div>
      <div class="p-col box"><i class="fas fa-comment fa-fw signal-chat p-m-2"></i>Signal</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-linkedin fa-fw p-m-2"></i>Sky News</div>
      <div class="p-col box"><i class="fab fa-microsoft fa-fw p-m-2"></i>Teams</div>
      <div class="p-col box"><i class="fab fa-telegram fa-fw p-m-2"></i>Telegram</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-tiktok fa-fw p-m-2"></i>TikTok</div>
      <div class="p-col box"><i class="fab fa-twitter fa-fw p-m-2"></i>Twitter</div>
      <div class="p-col box"><i class="fas fa-users fa-fw eschat p-m-2"></i>Viber (adds voice calls)</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fab fa-weixin fa-fw p-m-2"></i>WeChat (adds voice calls)</div>
      <div class="p-col box"><i class="fab fa-whatsapp fa-fw p-m-2"></i>WhatsApp (adds voice calls)</div>
      <div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wickr</div>
    </div>
    <div class="p-field p-grid">
      <div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wire (adds voice calls)</div>
      <div class="p-col box"><i class="fas fa-video fa-fw zoom p-m-2"></i>Zoom</div>
      <div class="p-col box"><i class="fas fa-fw p-m-2"></i></div>
    </div>
  </div>

  <div class="p-field">
    <h6>6. Email</h6>
    <div class="p-field">Allows the same network traffic as SkyLink Email and adds support for the common email (POP3/IMAP/SMTP) protocos and the following additional email services:</div>
      <div class="p-field p-grid">
        <div class="p-col "><i class="fab fa-apple fa-fw p-m-2"></i>Apple Mail</div>
        <div class="p-col "><i class="fab fa-google fa-fw p-m-2"></i>Gmail</div>
        <div class="p-col "><i class="fab fa-microsoft fa-fw p-m-2"></i>Outlook/Office 365</div>
      </div>
  </div>

  <div class="p-field">
    <h6>7. Whitelist</h6>
    <div class="p-field">Allows network traffic for the items defined in the <b>Whitelist</b>. All other traffic is blocked.</div>
    <div class="p-field">Unless you are the intended user for this profile, it is NOT recommended to be used.</div>
    <div class="p-field"><i>The allowed apps for this profile may change without notice.</i></div>
  </div>

</p-dialog>
