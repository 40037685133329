<div class="container">
  <h1 *ngIf="isReviewer">Device Type</h1>
  <h1 *ngIf="isEditing">Edit Device Type</h1>
  <h1 *ngIf="!isEditing && !isReviewer">Add Device Type</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-sm-6">
          <label for="nameField">Display Name</label>
          <input id="nameField" type="text" formControlName="name" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="manufacturerField">Manufacturer</label>
          <input id="manufacturerField" type="text" formControlName="manufacturer" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="productModelField">Product Model</label>
          <input id="productModelField" type="text" formControlName="productModel" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="productNameField">Product Name</label>
          <input id="productNameField" type="text" formControlName="productName" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="productCodeField">Product Code</label>
          <input id="productCodeField" type="text" formControlName="productCode" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="productVersionField">Product Version</label>
          <input id="productVersionField" type="text" formControlName="productVersion" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="platformTypeField">Platform Type</label>
          <input id="platformTypeField" type="text" formControlName="platformType" pInputText />
        </div>
        <div class="p-field p-sm-6">
          <label for="productVarianField">Product Variant</label>
          <p-dropdown
            id="productVariantField"
            [options]="productVariantOptions"
            formControlName="productVariant"
            placeholder="Select Product Variant"
            optionLabel="label"
            optionValue="value"
            appendTo="body">
          </p-dropdown>
        </div>
      </div>
    </div>
    <p>
      <button
        *ngIf="!isReviewer && !isAccountManager"
        pButton
        label="Save"
        class="p-button-warning p-mr-2"
        icon="fas fa-save"
        type="submit"
        [disabled]="!form.valid || !form.dirty">
      </button>
      <button
        pButton
        label="Cancel"
        class="p-button-warning"
        icon="fas fa-undo"
        type="button"
        (click)="onCancel($event)">
      </button>
    </p>
  </form>
</div>
<app-update-shadow
  [(visible)]="updateShadowModalOpen"
  [devices]="devicesToUpdate"
  (submitData)="submitData()"
  (onClose)="onClose()">
</app-update-shadow>
