import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  logOut(): Promise<any> {
    return Auth.signOut();
  }

  configure() {
    Amplify.configure(environment.amplify);
  }
}


