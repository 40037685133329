import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delete-static-dhcp-lease',
  templateUrl: './delete-static-dhcp-lease.component.html',
  styleUrls: ['./delete-static-dhcp-lease.component.scss']
})

export class DeleteStaticDhcpLeaseComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() dhcpToBeRemoved: any[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      console.log(`In delete() to be removed = ${JSON.stringify(this.dhcpToBeRemoved)}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
    } finally {
      this.onClose.emit(this.dhcpToBeRemoved);
      this.dhcpToBeRemoved = [];
      this.visible = false;
      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.dhcpToBeRemoved = [];
    console.log(`onCloseDialog  DONE`);
  }
}
