import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';

@Component({
  selector: 'app-form-dropdown',
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss']
})
export class FormDropdownComponent implements OnInit {


  @Input() parentGroup?: FormGroup;
  @Input() label = "";
  @Input() isViewing = false;
  @Input() baseValue?: any;
  @Input() placeholder?: string;
  @Input() formControlName?: string;
  @Input() options?: [{ key: string, value: string }];

  // Bring these into scope so that the HTML page can access them.
  BASE_VALUE = BASE_VALUE
  ConfigUtils = ConfigUtils;


  constructor() { }

  ngOnInit(): void {
  }

}
