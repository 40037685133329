import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ApiKeyModel } from 'src/app/models/api-key.model';
import { OrgModel } from 'src/app/models/org.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  createKeyModalOpen = false;
  isLoading = false;
  keys: ApiKeyModel[] = [];
  orgs: OrgModel[] = [];

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: AppBreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Manufacturing API Keys' }]);
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const response = await this.api.get<{ "apiKeys": ApiKeyModel[] }>("/apiKeys");
      this.keys = response.apiKeys;
    } finally {
      this.isLoading = false;
    }
  }

  deleteKey(key: ApiKeyModel) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this Api Key?",
      acceptButtonStyleClass: "p-button-warning",
      accept: async () => {
        try {
          await this.api.delete(`/apiKeys/${key.id}`);
          await this.load();
        } catch (err) {
          this.messageService.addAll(parseApiErrorsToToast(err));
        }
      }
    });
  }

  //#region table handling
  clear(table: Table) {
    table.clear();
  }
  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }
  //#endregion
}
