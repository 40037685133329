import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DevicesComponent } from './pages/protected/pages/devices/devices.component';
// import { AddComponent as DevicesAddComponent } from './pages/protected/pages/devices/add/add.component';
import { DeviceEditComponent } from './pages/protected/pages/devices/edit/edit.component';
import { CustomersComponent } from './pages/protected/pages/customers/customers.component';
import { SettingsComponent } from './pages/protected/pages/settings/settings.component';
import { ResellersComponent } from './pages/protected/pages/resellers/resellers.component';
import { DeviceTypeComponent } from './pages/protected/pages/device-type/device-type.component';
import { DeviceTypeEditComponent } from './pages/protected/pages/device-type/edit/edit.component';
import { ResellerEditComponent } from './pages/protected/pages/resellers/edit/edit.component';
import { UsersComponent } from './pages/protected/pages/users/users.component';
import { UsersEditComponent } from './pages/protected/pages/users/edit/edit.component';
import { LoginComponent } from './pages/public/login/login.component';
import { ProtectedComponent } from './pages/protected/protected.component';
import { AuthGuard } from './auth/auth.guard';
import { UnauthGuard } from './auth/unauth.guard';
import { ApiKeysComponent } from './pages/protected/pages/api-keys/api-keys.component';
import { MyApiKeysComponent } from './pages/protected/pages/my-api-keys/my-api-keys.component';
import { CustomerEditComponent } from './pages/protected/pages/customers/edit/edit.component';
import { MyOrgComponent } from './pages/protected/pages/my-org/my-org.component';
import { ConfigsComponent } from './pages/protected/pages/configs/configs.component';
import { DashboardComponent } from './pages/protected/pages/dashboard/dashboard.component';
import { ConfigEditComponent } from './pages/protected/pages/configs/edit/edit.component';
import { VConfigEditComponent } from './pages/protected/pages/configs/edit-vconfig/edit.component';
import { FirmwareComponent } from './pages/protected/pages/firmware/firmware.component';
import { FirmwareEditComponent } from './pages/protected/pages/firmware/edit/edit.component';
import { FaqComponent } from './pages/protected/pages/faq/faq.component';

const routes: Routes = [

  {
    path: "login",
    component: LoginComponent,
    canActivate: [UnauthGuard]
  },
  {
    path: '',
    component: ProtectedComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'configs', component: ConfigsComponent, pathMatch: 'full'},
      { path: 'configs/view/:device_id', component: ConfigEditComponent , pathMatch: 'full'},
      { path: 'configs/edit/:config_id', component: ConfigEditComponent, pathMatch: 'full' },
      { path: 'configs/add', component: ConfigEditComponent, pathMatch: 'full' },
      { path: 'configs/edit-vconfig/:config_id', component: VConfigEditComponent , pathMatch: 'full'},
      { path: 'configs/add-vconfig', component: VConfigEditComponent, pathMatch: 'full' },

      { path: 'dashboard', component: DashboardComponent },
      { path: 'my-org', component: MyOrgComponent },

      { path: 'devices', component: DevicesComponent },
      { path: 'devices/config/:device_id', component: ConfigEditComponent },
      { path: 'devices/vconfig/:device_id', component: VConfigEditComponent },
      { path: 'devices/edit/:device_id', component: DeviceEditComponent },
      { path: 'devices/add', component: DeviceEditComponent },

      { path: 'customers', component: CustomersComponent },
      { path: 'customers/add', component: CustomerEditComponent },
      { path: 'customers/edit/:customer_id', component: CustomerEditComponent },

      { path: 'resellers', component: ResellersComponent },
      { path: 'resellers/add', component: ResellerEditComponent },
      { path: 'resellers/edit/:reseller_id', component: ResellerEditComponent },

      { path: 'device-type', component: DeviceTypeComponent },
      { path: 'device-type/add', component: DeviceTypeEditComponent },
      { path: 'device-type/edit/:device_type_id', component: DeviceTypeEditComponent },

      { path: 'users', component: UsersComponent },
      { path: 'users/add', component: UsersEditComponent },
      { path: 'users/edit/:user_id', component: UsersEditComponent },

      { path: 'api-keys', component: ApiKeysComponent },

      { path: 'my-api-keys', component: MyApiKeysComponent },

      { path: 'firmware', component: FirmwareComponent },
      { path: 'firmware/add', component: FirmwareEditComponent },
      { path: 'firmware/edit/:firmware_id', component: FirmwareEditComponent },

      { path: 'faq', component: FaqComponent },

      { path: 'settings', component: SettingsComponent },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
