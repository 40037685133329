import { Component, ElementRef, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadComponent,
      multi: true
    }
  ]
})
export class UploadComponent implements ControlValueAccessor {
  onChange!: Function;
  private file: File | null = null;

  constructor( private host: ElementRef<HTMLInputElement> ) {
    console.log("host = ", host);
  }

  writeValue( value: null ) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  // writeValue(obj: any): void {
  //   console.log("writeValue - obj  = ", obj);
  //   // clear file input
  //   this.host.nativeElement.value = '';
  //   this.file = null;
  // }

  registerOnChange(fn: any): void {
    console.log("registerOnChange - fn  = ", fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onSelect(event: any) {
    console.log("onSelect - event = ", event);
    const file = event && event.currentFiles[0];
    console.log("onSelect - file = ", file);
    this.onChange(file);
    this.file = file;
  }

  onClear(event: any) {
    console.log("onClear - event = ", event);
  }

  onRemove(event: any) {
    console.log("onRemove - event = ", event);
    this.file = null;
  }
}
