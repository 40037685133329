<p-dialog header="Assign Device" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">
  <p>
    Assigning a device to a customer will also change the reseller to match the reseller for that customer. More than one customer can be assigned at a
    time so this page will only add to the customer assignments.
  </p>
  <p>Would you like to assign the following devices to the customer below?</p>
  <ul class="two-column">
    <li *ngFor="let device of devices">Serial: {{ device.serialNumber.toString(16) }} Name: {{ device.name }}</li>
  </ul>

  <div class="p-fluid">
    <div class="p-field">
      <label>Reseller to assign devices to</label>
      <p-dropdown
        [options]="customers"
        [(ngModel)]="customerSelected"
        placeholder="Select a Customer"
        optionLabel="name"
        [showClear]="true"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (onClick)="unAssign()" [disabled]="customerSelected" label="Unassign" styleClass="p-button"></p-button>
    <p-button icon="pi pi-check" (onClick)="assign()" [disabled]="!customerSelected" label="Assign" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
