import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // Load version and Git hash from package.json
  packageVersion = require('../../../../package.json').version;
  packageHash = require('../../../../package.json').hash;
  currentYear = (new Date()).getFullYear();
  constructor() { }

  ngOnInit(): void {
    console.log(`App Version: ${this.packageVersion}-${this.packageHash}`);
  }
}
