<div class="container">
    <div>
        <h1 *ngIf="isEditing">
            Edit Firmware
            <!-- <span class="float-end">
          <p-button label="Reset Password" icon="fas fa-key" styleClass="p-button-danger" (onClick)="resetPassword()"></p-button>
        </span> -->
        </h1>
        <h1 *ngIf="!isEditing">Add Firmware</h1>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-sm-6">
                    <label for="versionField">Version</label>
                    <input id="versionField" type="text" formControlName="version" pInputText required />
                </div>
                <div class="p-field p-sm-6">
                    <label for="versionNameField">Version Name</label>
                    <input id="versionNameField" type="text" formControlName="versionName" pInputText required />
                </div>
                <div class="p-field p-col-12">
                    <label for="releaseDateField">Release Date</label>
                    <input id="releaseDateField" type="text" formControlName="releaseDate" pInputText required />
                </div>
                <div class="p-field p-col-12">
                    <label for="fileUrlField">File URL</label>
                    <textarea id="fileUrlField" formControlName="fileUrl" pInputTextarea [autoResize]="true"
                        placeholder=""></textarea>
                </div>
                <div class="p-field p-sm-6">
                    <label for="AlgorithmField" class="required">Algorithm</label>
                    <p-dropdown
                      id="algorithmField"
                      [options]="algorithms"
                      formControlName="algorithm"
                      placeholder="Select a Check Sum Algorithm"
                      optionValue="value"
                      optionLabel="label">
                    </p-dropdown>
                    <small>Algorithm used to generate check sum.</small>
                  </div>
                <div class="p-field p-col-12">
                    <label for="checkSumField">Check Sum</label>
                    <textarea id="checkSumField" formControlName="checkSum" pInputTextarea [autoResize]="true"
                        placeholder=""></textarea>
                </div>
                <div class="p-field p-col-12">
                    <label for="releaseNotesField">Release Notes</label>
                    <textarea id="releaseNotesField" formControlName="releaseNotes" pInputTextarea [autoResize]="true"
                        placeholder=""></textarea>
                </div>
            </div>
        </div>

        <p>
            <button pButton label="Save" class="p-button-warning p-mr-2" icon="fas fa-save" type="submit"
                [disabled]="!form.valid || !form.dirty"></button>
            <button pButton label="Cancel" class="p-button-warning p-mr-2" icon="fas fa-undo" type="button"
                (click)="onCancel($event)"></button>
            <!-- <button *ngIf="isEditing" pButton pRipple type="button" label="Delete" class="p-button-danger p-mr-2"
                icon="fas fa-trash" (click)="deleteUserModalOpen = true" [disabled]="false"></button> -->
        </p>
    </form>
</div>
<!-- <app-user-delete-user [(visible)]="deleteUserModalOpen" [users]="users" (onClose)="onClose()"></app-user-delete-user> -->