import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast, createFailureToDeleteMsg  } from 'src/app/services/api.service';
import { ConfigModel } from 'src/app/models/config.model';
import { UserModel } from 'src/app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Message } from "primeng/api";

@Component({
  selector: 'app-delete-config',
  templateUrl: './delete-config.component.html',
  styleUrls: ['./delete-config.component.scss']
})
export class ConfigDeleteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() configs: ConfigModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  currentUser?: UserModel;
  configsSelected: ConfigModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
      console.log(`currentUser:: ${this.currentUser?.username} `);
    }
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      let payload: any[] = [];

      this.configs.forEach(config => {
        payload.push(config.id);
      });

      console.log(`delete payload is ${payload}`);

      const response = await this.api.request('DELETE', `/configs`, { body: { ids: payload } });

      console.log(`${JSON.stringify(response)}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
      const errors = createFailureToDeleteMsg(err, this.configs, 'profile');
      this.messageService.addAll(errors);
    } finally {
      this.configsSelected = [];
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }



  async onCloseDialog() {
    this.configsSelected = [];

    console.log(`onCloseDialog  DONE`);
  }
}
