import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function portValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let res = null;
    if (control.value?.length > 0) {
      const portRegEx: RegExp = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
      const portMatch = portRegEx.test(control.value);
      res = !portMatch ? {
        portValidator: {
          value : control.value
        }
      } : null;
    }
    return res;
  }
}
