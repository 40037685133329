<p-dialog header="Assign Device" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">
  <p>Assigning a device to a Reseller will mean the reseller becomes in charge of assignments to Customers.</p>
  <p class="warning-block">Resellers are not able to see customers assigned to different resellers. If you change reseller and leave customers assigned, it may have unknown consequences.</p>
  <p>Would you like to assign the following devices to the reseller below?</p>
  <ul class="two-column">
    <li *ngFor="let device of devices">{{ device.serialNumber.toString(16) }} {{ device.name }}</li>
  </ul>

  <div class="p-fluid">
    <div class="p-field">
      <label>Reseller to assign devices to</label>
      <p-dropdown
        [options]="resellers"
        [(ngModel)]="resellerSelected"
        placeholder="Select a Reseller"
        optionLabel="name"
        [showClear]="true"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (onClick)="unAssign()" [disabled]="resellerSelected" label="Unassign" styleClass="p-button"></p-button>
    <p-button icon="pi pi-check" (onClick)="assign()" [disabled]="!resellerSelected" label="Assign" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
