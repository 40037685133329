import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI | undefined;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private breadcrumbService: AppBreadcrumbService
  ) { }

  session?: any;
  user?: UserModel;
  form = this.fb.group({
    name: [],
    email: [, Validators.email],
    phone: [],
    address: []
  });

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Settings' }]);
    this.load();
  }

  async load() {
    try {
      this.blockUI?.start();
      this.session = await Auth.currentUserInfo();
      if (this.session) {
        this.user = await this.api.get(`/users/current`);
        if (this.user) {
          this.form.patchValue(this.user);
        }
      }
    } finally {
      this.blockUI?.stop();
    }
  }

  async submit() {
    try {
      this.blockUI?.start();
      if (this.user) {

        const user: any = {};
        Object.assign(user, this.user);
        Object.assign(user, this.form.value);

        // Remove the 'organization' property that is added to the user when the user is retrieved
        // via /users/current API so that we don't have both 'orgId' and an 'organization' object that
        // we try to save. If we didn't do this, we'd try to save the orgId twice and the query
        // and API request would fail.
        delete user.organization;

        await this.api.put(`/users/${user.id}`, user);
      }
    } catch (error) {
      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }
}
