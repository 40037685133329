<!-- <div class="container"> -->
<h1>Dashboard</h1>
<!-- <p class="help-block">
    dashboard works!
  </p> -->
<div class="p-grid">

  <!-- Reseller version of the count boxes -->
  <div class="p-col-12 p-grid" *ngIf="isVam || isReseller">
    <!-- <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/devices" title="Assigned Devices" [content]="assignedDeviceCount" footer="Devices Assigned to Your Customers">
    </app-countbox>
    <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/devices" title="Unassigned Devices" [content]="unassignedDeviceCount" footer="Devices Waiting to Be Assigned">
    </app-countbox> -->
    <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/devices" title="Total Devices" [content]="totalDeviceCount" footer="All Your Devices"></app-countbox>
    <app-countbox *ngIf="isVam || isReseller" class="p-lg-3 p-md-4 p-col-12" routerLink="/devices" title="Reseller Devices" [content]="resellerDeviceCount" footer="Assigned to Resellers"></app-countbox>
    <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/devices" title="Customer Devices" [content]="customerDeviceCount" footer="Assigned to Customers"></app-countbox>

    <app-countbox *ngIf="isVam || isReseller" class="p-lg-3 p-md-4 p-col-12" routerLink="/resellers" title="Resellers" [content]="resellerCount" footer="Resellers of Your Org"></app-countbox>
    <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/customers" title="Customers" [content]="customerCount" footer="Customers of Your Org"></app-countbox>
    <app-countbox class="p-lg-3 p-md-4 p-col-12" routerLink="/users" title="Users" [content]="userCount" footer="Users in Your Org"></app-countbox>

  </div>

  <!-- Customer view of the count boxes -->
  <div class="p-col-12 p-grid" *ngIf="isCustomer">
    <app-countbox class="p-lg-6 p-md-6 p-col-12" routerLink="/devices" title="Devices" [content]="totalDeviceCount" footer="Devices in Your Org">
    </app-countbox>
    <app-countbox class="p-lg-6 p-md-6 p-col-12" routerLink="/users" title="Users" [content]="userCount" footer="Users in Your Org"></app-countbox>
  </div>

  <div class="p-col-12 p-grid" *ngIf="totalDeviceCount; then deviceAdded else noDevicesAdded"></div>
  <ng-template #deviceAdded>
    <!-- <app-bar-chart class="p-lg-4 p-md-6 p-col-12" title="Data Usage by Device Type" footer="Coming Soon" [chartData]="deviceCountChartData" [options]="deviceCountOptionsData"></app-bar-chart> -->

    <!-- <app-pie-chart [show]="totalDeviceCount" class="p-lg-4 p-md-6 p-col-12" title="Device Last Contact Status" footer="Based on all your devices" [chartData]="devicesByLastContactedChartData" ></app-pie-chart> -->

    <!-- <app-doughnut-chart class="p-lg-4 p-md-6 p-col-12" title="Device Usage by Interface" footer="Coming Soon"  [chartData]="dataUsageByInterfaceChartData"></app-doughnut-chart> -->
    <app-pie-chart [show]="totalDevicesWithCellStatus.length" class="p-lg-6 p-md-6 p-col-12" title="Quality of Cellular Reception" footer="Based on Last 7 Days by Reporting Devices" [chartData]="signalStrengthCellularData"></app-pie-chart>
    <app-pie-chart [show]="totalDevicesWithCellStatus.length"   class="p-lg-6 p-md-6 p-col-12" title="Quality of Satellite Reception" footer="Average Based on Last 7 Days by Reporting Devices" [chartData]="signalStrengthSatelliteData"></app-pie-chart>
  <!--  <app-line-chart class="p-lg-6 p-col-12" title="Data Usage by Device Type" footer="Coming Soon" [chartData]="dataUsageByDeviceTypeChartData" [options]='dataUsageByDeviceTypeOptions'></app-line-chart> -->
  </ng-template>

  <ng-template #noDevicesAdded>
    <div class="card p-col-12">
      <div class="header">No Devices in your Account</div>
      <br/>
      <p>Please add devices to your account in order to see the dashboard charts</p>
    </div>
  </ng-template>
  <app-device-map class="p-col-12" title="Device Map" [devices]="devices" [mapHeight]="'450px'"></app-device-map>

</div>

<!-- </div> -->
