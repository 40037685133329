import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast, createFailureToDeleteMsg  } from 'src/app/services/api.service';
import { ConfigModel } from 'src/app/models/config.model';
import { UserModel } from 'src/app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Message } from "primeng/api";
import { JobsModel } from 'src/app/models/job.model';

@Component({
  selector: 'app-cancel-jobs',
  templateUrl: './cancel-jobs.component.html',
  styleUrls: ['./cancel-jobs.component.scss']
})
export class CancelJobsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() jobs: JobsModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  isVam: boolean = false;
  isReviewer: boolean = false;
  isOps: boolean = false;

  currentUser?: UserModel;
  jobsSelected: JobsModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
      console.log(`currentUser:: ${this.currentUser?.username} `);
    }
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

  async confirm() {
    try {
      this.blockUI?.start();
      const response = await this.api.put(`/jobs/cancel`, this.jobs);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
      const errors = createFailureToDeleteMsg(err, this.jobs, 'profile');
      this.messageService.addAll(errors);
    } finally {
      this.jobsSelected = [];
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }



  async onCloseDialog() {
    this.jobsSelected = [];

    console.log(`onCloseDialog  DONE`);
  }
}
