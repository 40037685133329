import { Component, OnInit, } from '@angular/core';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';


@Component({
  selector: 'app-user-login-name',
  templateUrl: './user-login-name.component.html',
  styleUrls: ['./user-login-name.component.scss']
})
export class UserLoginNameComponent implements OnInit {
  user?: CognitoUserInterface;

  constructor() { }

  ngOnInit(): void {
    Auth.currentAuthenticatedUser().then(authData => {
      this.user = authData as CognitoUserInterface;
    });
  }
}
