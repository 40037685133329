<p-dialog header="Assign Organization" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">
  <p>Would you like to assign the following devices to the Organization below?</p>
  <ul class="two-column">
    <li *ngFor="let device of devices">Serial: {{ device.serialNumber.toString(16) }} Name: {{ device.name }}</li>
  </ul>

  <div class="p-fluid">
    <div class="p-field">
      <label>Organization to assign devices to:</label>
      <p-dropdown
        [options]="orgs"
        [(ngModel)]="orgSelected"
        placeholder="Select an Organization"
        optionLabel="name"
        [showClear]="true"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button *ngIf="isVam" icon="pi pi-times" (onClick)="unAssign()" [disabled]="orgSelected" label="Unassign" styleClass="p-button"></p-button>
    <p-button icon="pi pi-check" (onClick)="assign()" [disabled]="!orgSelected" label="Assign" styleClass="p-button-warning"></p-button>
  </ng-template>
</p-dialog>
