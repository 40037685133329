<footer>
    <div class="layout-footer" style="background-color: black;">
      <div>
        <a href="https://blueskynetwork.com/" tabindex="-1"><img height="60px" width="180px" src="../../../assets/images/BSN_Logo_Horizontal_W.svg" alt="" title="" /> </a>
      </div>
      <div>
        <div class="text-muted">Copyright © {{currentYear}} | Blue Sky Network</div>
        <div class="text-muted centered" *ngIf="packageVersion && packageHash">Vers: {{packageVersion}}-{{packageHash}}</div>
        <div class="text-muted centered" *ngIf="packageVersion && !packageHash">Vers: {{packageVersion}}</div>
      </div>
      <div>
        <div class="p-d-flex brand-icons">
          <a href="https://www.facebook.com/Blue-Sky-Network-334713043216585" target="_blank" rel="nofollow" title="Facebook"><i class="fab fa-facebook-f"></i></a>
          <a href="https://twitter.com/blueskynetwork" target="_blank" rel="nofollow" title="Twitter"><i class="fab fa-twitter"></i></a>
          <a href="https://www.youtube.com/channel/UC97Wwv6MwD78KHCU10U94Fg" target="_blank" rel="nofollow" title="Youtube"><i class="fab fa-youtube"></i></a>
        </div>
      </div>
    </div>
</footer>
