import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser?: UserModel;
  isVam: boolean = false;
  isCustomer: boolean = false;

  constructor(
    private auth: AuthService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isCustomer = this.currentUser?.organization.type === 'Customer';
    }
  }

  navbarOpen = false;

  ngOnInit(): void {
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  async logOut() {
    await this.auth.logOut();

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    window.location.reload();
  }
}
