<p-dialog header="Cancel Jobs" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Are you sure you would like to try to cancel these jobs?</p>
  <ul class="two-column">
    <li *ngFor="let job of jobs">Job Number: {{ job.jobNumber }}</li>
  </ul>

  <ng-template pTemplate="footer">
    <p-button icon="fas fa-times"
              (onClick)="confirm()"
              label="Confirm"
              styleClass="p-button-danger">
    </p-button>
  </ng-template>

</p-dialog>
