<p-dialog header="Delete User" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Do you want to delete the following users?</p>
  <ul class="two-column">
    <li *ngFor="let user of users">Username: {{ user.username }}</li>
  </ul>

  <ng-template pTemplate="footer">
    <p-button icon="fas fa-trash"
              (onClick)="delete()"
              label="Delete"
              styleClass="p-button-danger">
    </p-button>
  </ng-template>

</p-dialog>
