import { AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { MenuService } from './components/menu/app.menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from '../../app.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements AfterViewInit, OnDestroy {

  rotateMenuButton: boolean = false;

  topbarMenuActive: boolean = true;

  overlayMenuActive: boolean = true;

  staticMenuDesktopInactive: boolean = false;

  staticMenuMobileActive: boolean = false;

  // Highlight selected menu item
  menuClick: boolean = false;

  topbarItemClick: boolean = true;

  activeTopbarItem: any;

  documentClickListener: () => void = () => { console.log(`something was clicked!?!?!`) };

  // App config right menu bar
  configActive: boolean = false;

  configClick: boolean = true;

  // Right panel
  rightPanelActive: boolean = false;

  rightPanelClick: boolean = true;

  menuHoverActive = false;

  searchClick = false;

  search = false;

  constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig, private auth: AuthService, private router: Router, public app: AppComponent) { }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', (event: any) => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick && this.isHorizontal()) {
        this.menuService.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.rightPanelClick) {
        this.rightPanelActive = false;
      }

      if (!this.menuClick) {
        if (this.overlayMenuActive) {
          this.overlayMenuActive = false;
        }
        if (this.staticMenuMobileActive) {
          this.staticMenuMobileActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      if (!this.searchClick) {
        this.search = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.menuClick = false;
      this.rightPanelClick = false;
    });
  }

  onMenuButtonClick(event: Event) {

    console.log(`onMenuButtonClick(event: ${event})`);

    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;
    this.menuClick = true;

    if (this.app.menuMode === 'overlay' && !this.isMobile()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onMenuClick($event: Event) {
    this.menuClick = true;
    console.log(`onMenuClick(event: ${event})`);
  }

  onTopbarItemClick(event: Event, item: any) {

    console.log(`onTopBarItemClick(event: ${event}, item: ${item})`);

    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    if (item.className === 'search-item topbar-item') {
      this.search = !this.search;
      this.searchClick = !this.searchClick;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event: Event, item: any) {
    console.log(`onTopbarSubItemClick(event: ${event}, item:${item})`);

    if (item === 'logout') {
      console.log(`Logging out!`);
      this.logOut();
    } else if (item === 'account-info') {
      console.log(`Going to account info screen`);
      this.router.navigate(['settings']);
    }



    event.preventDefault();
  }


  async logOut() {
    await this.auth.logOut();

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    window.location.reload();
  }


  onRTLChange(event: any) {
    this.app.isRTL = event.checked;
  }

  onRippleChange(event: any) {
    this.app.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
  }

  onConfigClick(event: any) {
    this.configClick = true;
  }

  onRightPanelButtonClick(event: any) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }

  onRightPanelClick() {
    this.rightPanelClick = true;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isStatic() {
    return this.app.menuMode === 'static';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

}