<div class="container">
  <h3 *ngIf="isViewing || isReviewer">Voice Profile</h3>
  <h3 *ngIf="!isViewing && isEditing">Edit Voice Profile</h3>
  <h3 *ngIf="!isViewing && !isEditing && !isReviewer">Create Voice Profile</h3>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="nameField">Profile Name</label>
        <input id="nameField" type="text" formControlName="name" pInputText required/>
        <div *ngIf="!isViewing && form.get('name')?.errors?.required">
          Profile Name is required.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.nameValidator">
          Profile Name is invalid.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.minlength">
          Profile Name must be at least 5 characters long.
        </div>
        <div *ngIf="!isViewing && form.get('name')?.errors?.maxlength">
          Profile Name must be at most 25 characters long.
        </div>
      </div>
      <div *ngIf="isViewing || isEditing || isReviewer" class="p-field p-sm-6">
        <label for="versionField">Version</label>
        <input id="versionField" type="text" formControlName="version" pInputText/>
        <a *ngIf="!isViewing" (click)="displayHistoryDlg()">History</a>
      </div>
    </div>

    <div class="card">
      <h4>SIP Extensions</h4>
      <p-table
        #sipExtensionsList
        [value]="sipExtensions"
        styleClass="p-datatable-striped"
        dataKey="id"
        [rows]="50"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[
          'ext',
          'inbound.line_1',
          'inbound.line_2',
          'username',
          'outbound',
          'description'
        ]"
        sortMode="single"
        sortField="ext"
        [loading]="isLoading"
      >
        <ng-template *ngIf="!isViewing" pTemplate="caption">
          <div class="p-d-flex">
            <!-- <button
              pButton
              pRipple
              label="New"
              [disabled]="!form.valid"
              class="p-button-warning p-mr-2"
              icon="far fa-plus-square"
              (click)="showSipDialog()">
            </button> -->
            <button
              pButton
              pRipple
              label="Clear"
              class="p-button p-mr-2"
              icon="pi pi-filter-slash"
              type="button"
              (click)="clear(sipExtensionsList)">
            </button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search(sipExtensionsList, $event)" placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              Extension
              <p-columnFilter type="text" field="ext" display="menu"></p-columnFilter>
            </th>
            <th *ngIf="isViewing">
              Status
              <p-columnFilter type="text" field="status" display="menu"></p-columnFilter>
            </th>
            <th>
              Description
              <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
            </th>
            <th>
              Inbound Line(s)
              <p-columnFilter type="text" field="inbound.line_1" display="menu"></p-columnFilter>
            </th>
            <th>
              Outbound Line
              <p-columnFilter field="outbound" matchMode="equals" display="menu" class="p-ml-auto">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown
                      [options]="outboundLinesArr"
                      optionLabel="name"
                      optionValue="value"
                      (onChange)="filter($event.value)"
                      placeholder="Any">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-primary'">{{option.name}}</span>
                        </ng-template>
                    </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
            <th>
              Username
              <p-columnFilter type="text" field="username" display="menu"></p-columnFilter>
            </th>
            <th>Password</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sipExtension>
          <tr>
            <td>
              <a (click)="showSipDialog(sipExtension)">{{ sipExtension.ext }}</a>
            </td>
            <!-- <td *ngIf="isViewing">{{ sipExtension.ext }}</td> -->
            <td *ngIf="isViewing">
              <span>
                <span *ngIf="sipExtension.status=='Device-Only'" class="customer-badge status-renewal">{{ sipExtension.status }}</span>
                <span *ngIf="sipExtension.status=='Pending'" class="customer-badge status-proposal">{{ sipExtension.status }}</span>
                <span *ngIf="sipExtension.status=='Synched'" class="customer-badge status-qualified">{{ sipExtension.status }}</span>
                <span *ngIf="sipExtension.status=='Modified'" class="customer-badge status-new">{{ sipExtension.status }}</span>
              </span>
            </td>
            <td>{{ sipExtension.description }}</td>
            <td>
              <span style="padding: 5px;">
                <span *ngIf="sipExtension.inbound.line_1" class="customer-badge status-primary">Line 1</span>
                <span *ngIf="!sipExtension.inbound.line_1" class="customer-badge status-unknown">Line 1</span>
              </span>
              <span style="padding: 5px;">
                <span *ngIf="sipExtension.inbound.line_2" class="customer-badge status-primary">Line 2</span>
                <span *ngIf="!sipExtension.inbound.line_2" class="customer-badge status-unknown">Line 2</span>
              </span>
            </td>
            <td>
              <span class="customer-badge status-primary">{{ this.outboundLines[sipExtension.outbound] }}</span>
            </td>
            <td>{{ sipExtension.username }}</td>
            <td>{{ sipExtension.password }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <br>
    <div class="card">
      <h4>POTS Extensions</h4>
      <p-table
        #potsExtensionsList
        [value]="potsExtensions"
        styleClass="p-datatable-striped"
        dataKey="id"
        [rows]="50"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[
          'extension',
          'inboundLine1',
          'inboundLine2',
          'outboundLine',
          'description'
        ]"
        sortMode="single"
        sortField="extension"
        [loading]="isLoading"
      >
        <ng-template pTemplate="caption">
          <div class="p-field p-grid">
            <label for="regionField" class="p-col-1">Region:  </label>
            <div class="p-fluid">
              <p-dropdown id="regionField"
                [options]="regions"
                formControlName="region"
                placeholder="Select a Region"
                optionLabel="name"
                optionValue="value"
                [style]="{'minWidth':'200px', 'width': '200px'}"
                appendTo="body">
              </p-dropdown>
              <small
                *ngIf="isViewing"
                [ngClass]="{'item-differ': form.get('region').value != regionBase }"
                id="line1BaseField">
                  {{baseValue}} {{configUtils.displayConversion(regions, regionBase)}}
              </small>
            </div>
          </div>
          <div *ngIf="!isViewing" class="p-d-flex">
            <!-- <button
              pButton
              pRipple
              label="New"
              [disabled]="!form.valid"
              class="p-button-warning p-mr-2"
              icon="far fa-plus-square"
              (click)="showPotsDialog()">
            </button> -->
            <button
              pButton
              pRipple
              label="Clear"
              class="p-button p-mr-2"
              icon="pi pi-filter-slash"
              type="button"
              (click)="clear(potsExtensionsList)">
            </button>
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search(potsExtensionsList, $event)" placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              Extension
              <p-columnFilter type="text" field="ext" display="menu"></p-columnFilter>
            </th>
            <th *ngIf="isViewing">
              Status
              <p-columnFilter type="text" field="status" display="menu"></p-columnFilter>
            </th>
            <th>
              Description
              <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
            </th>
            <th>
              Inbound Line (s)
              <p-columnFilter type="text" field="inbound" display="menu"></p-columnFilter>
            </th>
            <th>
              Outbound Line
              <p-columnFilter field="outbound" matchMode="equals" display="menu" class="p-ml-auto">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown
                      [options]="outboundLinesArr"
                      optionLabel="name"
                      optionValue="value"
                      (onChange)="filter($event.value)"
                      placeholder="Any">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-primary'">{{option.name}}</span>
                        </ng-template>
                    </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
        </ng-template>
        <ng-template pTemplate="body" let-potsExtension>
          <tr>
            <td>
              <a (click)="showPotsDialog(potsExtension)">{{ potsExtension.ext }}</a>
            </td>
            <td *ngIf="isViewing">
              <span>
                <span *ngIf="potsExtension.status=='Device-Only'" class="customer-badge status-renewal">{{ potsExtension.status }}</span>
                <span *ngIf="potsExtension.status=='Pending'" class="customer-badge status-proposal">{{ potsExtension.status }}</span>
                <span *ngIf="potsExtension.status=='Synched'" class="customer-badge status-qualified">{{ potsExtension.status }}</span>
                <span *ngIf="potsExtension.status=='Modified'" class="customer-badge status-new">{{ potsExtension.status }}</span>
              </span>
            </td>
            <td>{{ potsExtension.description }}</td>
            <td>
              <span style="padding: 5px;">
                <span *ngIf="potsExtension.inbound.line_1" class="customer-badge status-primary">Line 1</span>
                <span *ngIf="!potsExtension.inbound.line_1" class="customer-badge status-unknown">Line 1</span>
              </span>
              <span style="padding: 5px;">
                <span *ngIf="potsExtension.inbound.line_2" class="customer-badge status-primary">Line 2</span>
                <span *ngIf="!potsExtension.inbound.line_2" class="customer-badge status-unknown">Line 2</span>
              </span>
            </td>
            <td>
              <span class="customer-badge status-primary">{{ this.outboundLines[potsExtension.outbound] }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br/>
    <p>
      <button
        *ngIf="!isViewing && !isReviewer"
        pButton
        label="Save"
        class="p-button-warning p-mr-2"
        icon="fas fa-save"
        type="submit"
        [disabled]="!form.valid || (!form.dirty && !extensionChanged)">
      </button>
      <button
        *ngIf="isEditing && !isReviewer"
        pButton
        pRipple
        label="Delete"
        type="button"
        icon="fas fa-trash"
        class="p-button-danger p-mr-2"
        (click)="deleteVConfigModalOpen=true">
      </button>
      <button
        pButton
        label="Cancel"
        class="p-button-warning"
        icon="fas fa-undo"
        type="button"
        (click)="onCancel($event)">
      </button>
    </p>
  </form>
</div>
<app-edit-sip-extension
  [(visible)]="editSipExtensionModalOpen"
  [isViewing]="isViewing"
  [isReviewer]="isReviewer"
  [sipExtension]=sipExtensionSelected
  [sipExtensionBase]=sipExtensionBaseSelected
  (onSave)="saveSipExtension($event)">
</app-edit-sip-extension>
<app-edit-pots-extension
  [(visible)]="editPotsExtensionModalOpen"
  [isViewing]="isViewing"
  [isReviewer]="isReviewer"
  [potsExtension]=potsExtensionSelected
  [potsExtensionBase]=potsExtensionBaseSelected
  (onSave)="savePotsExtension($event)">
</app-edit-pots-extension>
<app-config-history
  [(visible)]="configHistoryModalOpen"
  [configType]="'voice'"
  [vConfigs]="voiceConfigs"
  (onClose)="onClose()">
</app-config-history>
<app-update-shadow
  [(visible)]="updateShadowModalOpen"
  [devices]="devicesToUpdate"
  (submitData)="submitData()"
  (onClose)="onClose()">
</app-update-shadow>
<app-delete-vconfig
  [(visible)]="deleteVConfigModalOpen"
  [vConfigs]="vConfigs"
  (onClose)="onClose()">
</app-delete-vconfig>
