import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';

/**
 * It runs on every required input and adds the red '*' to the parent label
 * @see https://www.freakyjolly.com/angular-add-star-asterisk-sign-to-required-fields-using-custom-directive-for-bootstrap-form/#.YEabwZ1KhaQ
 */
@Directive({
  selector: '[required]'
})
export class MarkRequiredAsteriskDirective implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    const labels = parent.getElementsByTagName('LABEL') as HTMLElement[];

    if (labels.length) {
      labels[0].classList.add("required");
    }
  }
}
