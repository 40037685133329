
<p-fileUpload
    name="docs[]"
    multiple="false"
    fileLimit="1"
    accept="image/*"
    maxFileSize="2000000"
    (onSelect)="onSelect($event)"
    (onClear)="onClear($event)"
    (onRemove)="onRemove($event)"
    [showUploadButton]="false"
    [showCancelButton]="false">
</p-fileUpload>