import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { MyApiKeyModel } from 'src/app/models/my-api-key.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { nameValidator } from '../../../validators/nameValidator';
import { duplicateValidatorForApiKeys } from '../../../validators/duplicateValidator';

@Component({
  selector: 'app-my-api-keys-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class MyApiKeysCreateComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() allApiKeys: MyApiKeyModel[] = []
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  // users: UserModel[] = [];
  createdKey?: MyApiKeyModel;
  form = this.fb.group({
    title: [{ value: null, disabled: false },
      [Validators.required, Validators.minLength(5), Validators.maxLength(50), nameValidator(), duplicateValidatorForApiKeys(() => this.allApiKeys )]],
  });

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.blockUI?.start();
    } finally {
      this.blockUI?.stop();
    }
  }

  async submit() {
    try {
      this.blockUI?.start();

      this.createdKey = await this.api.post<MyApiKeyModel>(`/my-apikeys`, {
        title: this.form.value.title,
      });
    } catch (err) {
      this.messageService.addAll(parseApiErrorsToToast(err));
    } finally {
      this.blockUI?.stop();
    }
  }

  /**
   * Lots of work in order to properly emit the closing events..
   * This updates the 2-way bound visible property and also fires the onClose event
   * @param evt
   */
  close(evt: Event) {
    this.createdKey = undefined;
    this.form.reset();
    this.visibleChange.emit(evt);
    this.onClose.emit(evt);
  }
}
