<p-dialog header="Remove Host Name" [visible]="visible" (visibleChange)="visibleChange.emit($event)" [style]="{ width: '50vw' }" (onHide)="onCloseDialog()">

  <p>Are you sure you want to remove <strong>{{ endpointToBeRemoved }}</strong> from the list of endpoints?</p>

  <p><span style="color: red; padding-right: 5px;" class="pi pi-info-circle"></span><small>Endpoints will not be updated until after clicking Save in the main window.</small></p>

  <ng-template pTemplate="footer">
    <p-button icon="fas fa-trash"
              (onClick)="delete()"
              label="Delete"
              styleClass="p-button-danger">
    </p-button>
  </ng-template>

</p-dialog>
