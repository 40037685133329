<div class="container">
  <div>
    <h1 *ngIf="isReviewer">User</h1>
    <h1 *ngIf="isEditing">
      Edit User
      <!-- <span class="float-end">
        <p-button label="Reset Password" icon="fas fa-key" styleClass="p-button-danger" (onClick)="resetPassword()"></p-button>
      </span> -->
    </h1>
    <h1 *ngIf="!isEditing && !isReviewer">Add User</h1>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
    <section *ngIf="!isEditing">
      <h4>Type</h4>
    </section>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="roleField" class="required">User Role</label>
        <p-dropdown
          id="roleField"
          [options]="roles"
          formControlName="role"
          placeholder="Select a User Role"
          optionValue="value"
          optionLabel="label"
          (onChange)="handleRoleChange($event)">
        </p-dropdown>
        <small>Admin users can create other Admin users.</small>
      </div>
      <div class="p-field p-sm-6" formGroupName="organization">
        <label for="orgField">Organization</label>
        <p-dropdown
          id="orgField"
          [options]="orgs"
          formControlName="id"
          placeholder="Select an Organization"
          optionLabel="name"
          optionValue="id"
          [showClear]="true">
        </p-dropdown>
      </div>
      <div class="p-field" *ngIf="isEditing && (this.currentUsername !== this.user?.username)">
        <div class="p-field-checkbox">
          <p-inputSwitch inputId="isEnabledField" formControlName="isEnabled"></p-inputSwitch>
          <label for="isEnabledField">Is Enabled</label>
        </div>
        <small class="text-danger">Disabling a user will prevent them from logging in.</small>
      </div>
    </div>
    </div>

    <div class="card">
    <h4>Identity</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="nameField">Name</label>
        <input id="nameField" type="text" formControlName="name" pInputText required />
      </div>
      <div class="p-field p-sm-6">
        <label for="usernameField">Username</label>
        <input
          id="usernameField"
          type="text"
          formControlName="username"
          pInputText
          required
          pattern="^[\S]+$"
        />
      </div>
    </div>
    </div>

    <div class="card">
    <h4>Contact</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-6">
        <label for="emailField">Email</label>
        <input id="emailField" type="email" formControlName="email" pInputText required />
      </div>
      <div class="p-field p-sm-6">
        <label for="phoneField">Phone</label>
        <input id="phoneField" type="tel" formControlName="phone" pInputText required />
      </div>
      <div class="p-field p-col-12">
        <label for="addressField">Address</label>
        <textarea
          id="addressField"
          formControlName="address"
          pInputTextarea
          [autoResize]="true"
          placeholder="123 Main Street.
Springfield, CA 1234"
        ></textarea>
      </div>
    </div>
    </div>
    <p>
      <button
        *ngIf="!isReviewer"
        pButton label="Save"
        class="p-button-warning p-mr-2"
        icon="fas fa-save"
        type="submit"
        [disabled]="!form.valid || !form.dirty">
      </button>
      <button
        pButton
        label="Cancel"
        class="p-button-warning p-mr-2"
        icon="fas fa-undo"
        type="button"
        (click)="onCancel($event)">
      </button>
      <button
        *ngIf="isEditing && (this.currentUsername !== this.user?.username)"
        pButton
        pRipple
        type="button"
        label="Delete"
        class="p-button-danger p-mr-2"
        icon="fas fa-trash"
        (click)="deleteUserModalOpen = true"
        [disabled]="false">
      </button>
  </p>
  </form>
</div>
<app-user-delete-user [(visible)]="deleteUserModalOpen" [users]="users" (onClose)="onClose()"></app-user-delete-user>
