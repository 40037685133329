<p-dialog
  header="Config History"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '50vw' }" 
  (onShow)="onShow()"
  (onHide)="onCloseDialog()">
  <p>Config Name: {{configName}}</p>
  <div class="card">
    <p-table
      #configsList
      [value]="configs"
      styleClass="p-datatable-striped"
      dataKey="id"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 20, 30]"
      [paginator]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['version', 'createdAt']"
      [(selection)]="configSelected"
      sortMode="single"
      sortField="version"
      [loading]="isLoading"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            Version
            <p-columnFilter type="text" field="version" display="menu"></p-columnFilter>
          </th>
          <th>
            Created
            <p-columnFilter type="text" field="created" display="menu"></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-config>
        <tr>
          <td>
            <a [routerLink]="" (click)="loadVersion(config.id)" class="d-block">{{ config.version }}</a>
          </td>
          <td>{{ config.createdAt | date: 'MM/dd/yyyy'}}</td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</p-dialog>
