import { OrgModel } from "./org.model";

export interface MessageHubChannelModel {
  id?: string;
  channel_id: number;
  name: string;
  ttl: number;
  callback_url: string;
  endpoints: [];
}
export interface ChannelManagementModel {
  standard_topic_id: number
  channels: MessageHubChannelModel[];
}
export interface RemoteManagementModel {
  call_history_enabled: boolean;
  call_history_interval: string;
  jobs_host: string;
  jobs_port: string;
  position_reports_enabled: boolean
  position_reports_interval: string;
  remote_management_enabled: boolean;
  remote_management_interval: string;
  shadow_interval : string;
  shadow_version_host: string;
  shadow_version_port: string;
  status_report_interval: string;
  status_reports_enabled: boolean,
  usage_upload_interval: string;
  usage_upload_enabled: boolean,
  update_check_url: string;
  sos_report_interval: string
}

export interface DhcpReservationModel {
  id?: string;
  set_tag: string;
  hwaddr: string;
  ipv4: string;
  hostname: string;
  ipv6?: string;
  lease_time: string;
  comment?: string;
}

export interface DhcpModel {
  enabled: string;
  range_end: string;
  dns_servers: string[];
  lease_time: string;
  local_ipv4: string;
  range_start: string;
  local_domain: string;
  local_ipv4_prefix: string;
  reservations: DhcpReservationModel[];
}

export interface SimGroupsModel {
  apn: string;
  password: string;
  username: string;
}

export interface SimsModel {
  sims: SimGroupsModel[];
}

export interface SnmpModel {
  community_ro: string;
  community_rw: string;
  enabled: boolean;
}

export interface VideosoftModel {
  enabled: boolean;
}

export interface SystemModel {
  asset_tag: string;
}

export interface HostapdModel {
  ssid: string;
  channel: string;
  country: string;
  enabled: boolean;
  band_5ghz: boolean;
  wpa_passphrase: string;
}

export interface HostGroupModel {
  default_firewall: number;
  default_route: string;
}

export interface NetMgrModel {
  host_groups: HostGroupModel[];
}

export interface DataEnabledModel {
  enabled: boolean;
  data_enabled: boolean;
}

export interface EnabledModel {
  enabled: boolean
}

export interface SkylinkModel {
  wifi: EnabledModel;
  serial: EnabledModel;
  cellular: EnabledModel;
  iridium_sfx: DataEnabledModel;
}

export interface PlatformModel {
//  skylink: SkylinkModel;
  wifi_enabled: boolean;
  serial_enabled: boolean;
  cellular_enabled: boolean;
  iridium_sfx_data_enabled: boolean;
  iridium_sfx_enabled: boolean;
  sos_enabled: boolean;
  battery_shutdown_time: number;
}

export interface ModemEmulatorModel {
  baud: number;
  flow_control: String;
}

export interface NetInterfacesModel {
  ipv4: string;
}

export interface UiModel {
  data_routing_wired: boolean;
}

export enum FirewallRuleStatusEnum {
  DEVICE_ONLY = 'Device-Only',
  PENDING = 'Pending',
  SYNCHED = 'Synched',
  MODIFIED = 'Modified'
}
export interface FirewallRulesModel {
  comment: string;
  dest: string;    // loc:ipaddress:4300
  dport: string;   // source
  proto: string;
  source: string; //Internet
}

export interface IpsetsModel {
  name: string;
  description: string;
  hostnames: [];
  ips: [];
}
export interface FirewallIpsetsModel {
  ipsets: IpsetsModel[];
}

//
export interface FirewallDnatModel {
  name: string;
  rules: FirewallRulesModel[];
}

// FirewallRule for internal use and data disply
export interface FirewallRule {
  action: string; // Forward
  source: string; //Internet
  ipAddress: string;
  sourcePort: string;
  destinationPort: string;
  protocol: string;
  comment: string;
  status?: FirewallRuleStatusEnum;
  key?: string;
}

export interface AuthModel {
  password: string;
}

export interface MetaModel {
  name: string;
  version: number;
}

export interface ShadowsModel {
  device_meta: MetaModel;
  auth: AuthModel;
  ui: UiModel;
  dhcp: DhcpModel;
  sims: SimsModel;
  snmp: SnmpModel;
  system: SystemModel;
  hostapd: HostapdModel;
  net_mgr: NetMgrModel;
  platform: PlatformModel;
  modem_emulator: ModemEmulatorModel;
  net_interfaces: NetInterfacesModel[];
  firewall_dnat: FirewallDnatModel[];
  firewall_ipsets: FirewallIpsetsModel;
  remote_management: RemoteManagementModel;
  channel_management: ChannelManagementModel;
  videosoft: VideosoftModel;
}

export interface ConfigInfoModel {
  org: OrgModel;
  shadows: ShadowsModel;
}

export interface ConfigModel {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  groupId: string;
  version: number;
  config: ConfigInfoModel;
  channels: ChannelManagementModel;
}
