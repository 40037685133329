import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ProtectedComponent } from './protected.component';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { ApiService, HttpOptions } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

  isLoading: boolean = false;

  user?: CognitoUserInterface;
  parentOrgId?: number;
  orgId?: number;

  isCustomer: boolean = false;
  isReseller: boolean = false;

  image: string = 'assets/images/BSN_Logo_Horizontal_RGB.svg';

  constructor(
    public appMain: ProtectedComponent,
    public app: AppComponent,
    private router: Router,
    private api: ApiService
  ) {
    const userStr = localStorage.getItem('currentUser');
    console.log("userStr = ", userStr);
    if (userStr) {
      const currentUser = JSON.parse(userStr);
      console.log("currentUser = ", currentUser);
      this.orgId = currentUser.organizationId;
      this.parentOrgId = currentUser.organization?.parentId;
      this.isReseller = currentUser?.organization.type === 'Reseller';
      this.isCustomer = currentUser?.organization.type === 'Customer';
    }
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.isLoading = true;

      this.user = await Auth.currentAuthenticatedUser() as CognitoUserInterface;
      console.log("this.user = ", this.user);

      // Load Organization Image
      this.loadOrgImage();

    } catch (e) {
      console.log(`Error: ${e}`);
    } finally {
      this.isLoading = false;
    }
  }

  async loadOrgImage() {
    console.log("In loadOrgImage")
    try {
      const imageBuffer = await this.api.getImage(`/orgs/${this.orgId}/uploads`);
      if (imageBuffer) {
        this.image = `data:image/png;base64,` + this.arrayBufferToBase64(imageBuffer);
      }
    } catch (e) {
      console.debug(`No uploads found!!!`);
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  arrayBufferToBase64( buffer: ArrayBuffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

}
