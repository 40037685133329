<p-dialog
  header="Messaging Channel"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [style]="{ width: '40vw' }"
  (onShow)="onShow()"
  (onHide)="onCloseDialog()">

  <!-- TODO use multiple headers to create view etc.-->
  <!-- <h3 *ngIf="isViewing || isReviewer">Messaging Channel</h3>
  <h3 *ngIf="!isViewing && !isAdding">Edit Messaging Channel</h3>
  <h3 *ngIf="!isViewing && isAdding && !isReviewer">Add Messaging Channel</h3> -->

  <form [formGroup]="msgHubChannelForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-sm-9">
        <label for="nameField">Channel Name</label>
        <input id="nameField" type="text" formControlName="channelName" pInputText />
        <div *ngIf="!isViewing && msgHubChannelForm.get('channelName')?.errors?.nameValidator">
          Channel Name is invalid.
        </div>
        <div *ngIf="!isViewing && msgHubChannelForm.get('channelName')?.errors?.minlength">
          Channel Name must be at least 5 characters long.
        </div>
        <div *ngIf="!isViewing && msgHubChannelForm.get('channelName')?.errors?.maxlength">
          Channel Name must be at most 25 characters long.
        </div>
        <!-- <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('ipv4Address').value != ipv4Address }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(ipv4Address)}}
        </small> -->
      </div>

      <div class="p-field p-sm-4">
        <label for="channelIdField">Channel ID</label>
        <input id="channelIdField" type="number" formControlName="channelId" pInputText required/>
        <div *ngIf="!isViewing && msgHubChannelForm.get('channelId')?.errors?.required">
          Channel ID is required.
        </div>
        <div *ngIf="!isViewing && msgHubChannelForm.get('channelId')?.errors?.duplicateValidatorForChannel">
          Channel ID must be unique!
        </div>
        <!-- <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('macAddress').value != macAddress }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(macAddress)}}
        </small> -->
      </div>

      <div class="p-field p-sm-5">
        <label for="ttlField">TTL</label>
        <input id="ttlField" type="number" formControlName="ttl" pInputText required/>
        <div *ngIf="!isViewing && msgHubChannelForm.get('ttl')?.errors?.required">
          TTL is required.
        </div>
        <div *ngIf="!isViewing && msgHubChannelForm.get('ttl')?.errors?.pattern">
          TTL is invalid.
        </div>
        <!-- <small
          *ngIf="isViewing"
          [ngClass]="{'item-differ': staticDhcpLeaseForm.get('hostname').value != hostname }"
          id="extensionBaseField">
            {{baseValue}} {{configUtils.displayNotSetIfEmpty(hostname)}}
        </small> -->
      </div>
    </div>

    <div class="p-grid" [style]="{'margin-bottom': '2rem'}">
      <div class="p-col-12 ">
        <!-- <div class="card" [style]="{'height': '100%'}"> -->

          <h5>Endpoints</h5>
          <p class="card warning-rules-block">
            Supported URI schemes:
              <li><b>http</b> - ie, <i>http://www.skylink.net</i></li>
              <li><b>https</b> - ie, <i>https://www.skylink.net/path/api</i></li>
              <li><b>tcp</b> - ie, <i>tcp://www.skylink.net</i></li>
              <li><b>mailto</b> - ie, <i>mailto:email@skylink.net</i></li>
          </p>
          <div class="p-fluid p-formgrid p-grid" formArrayName="endpoints">
            <div class="p-field p-sm-9" *ngFor="let endpoint of endpoints.controls; let i = index">
              <div class="p-inputgroup whitelist-inputgroup">
                <input type="text" pInputText disabled placeholder="{{ endpoint?.value?.name }}">
                <button *ngIf="!isViewing"
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  styleClass="p-button-warn"
                  (click)="onDeleteEndpoint(endpoint.value.name, i)">
                </button>
                
                <div class="channel-badge" *ngIf="isViewing">
                  <span>
                    <span *ngIf="endpoint.value.status=='Device-Only'" class="customer-badge status-renewal">{{ endpoint.value.status }}</span>
                    <span *ngIf="endpoint.value.status=='Pending'" class="customer-badge status-proposal">{{ endpoint.value.status }}</span>
                    <span *ngIf="endpoint.value.status=='Synched'" class="customer-badge status-qualified">{{ endpoint.value.status }}</span>
                    <span *ngIf="endpoint.value.status=='Modified'" class="customer-badge status-new">{{ endpoint.value.status }}</span>
                  </span>
                </div>
               
              </div>
              <div>
                <div *ngIf="!isViewing && endpoint.errors?.duplicateArrayValidator" class="item-differ">
                  This line is duplicated
                </div>
              </div>
            </div>
          </div>
          
          <div *ngIf="!isViewing" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-sm-9">
              <input id="endpointField" type="text" formControlName="endpoint" placeholder="Endpoint" pInputText />
              <div *ngIf="!isViewing && msgHubChannelForm.get('endpoint')?.errors?.pattern">
                Supported schemes are: http(s), tcp and mailto!
              </div>
            </div>
            <div class="p-field p-sm-3">
              <button
                *ngIf="!isViewing && !isReviewer"
                pButton
                label="Add"
                type="button"
                icon="fas fa-plus"
                class="p-button-success p-mr-2"
                [isViewing]="false"
                (click)="onAddEndpoints()"
                [disabled]="!msgHubChannelForm.get('endpoint').valid || !msgHubChannelForm.get('endpoint').dirty">
              </button>
            </div>
          <!-- </div> -->

        </div>
      </div>
    </div>
  </form> 
  <ng-template pTemplate="footer">
    <button
      *ngIf="!isViewing && !isReviewer"
      pButton
      (click)="save()"
      label="Save"
      class="p-button-warning p-mr-2"
      icon="fas fa-save"
      [disabled]="!msgHubChannelForm.valid || !msgHubChannelForm.dirty">
    </button>
  </ng-template>
</p-dialog>
<app-delete-channel-endpoint
  [(visible)]="deleteChannelEndpointModalOpen"
  [endpointToBeRemoved]="selectedChannelEndpoint"
  [index]="selectedChannelEndpointIdx"
  (onClose)="onChannelEndpointDeleted($event)">
</app-delete-channel-endpoint>
