import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiService, parseApiErrorsToToast, createFailureToDeleteMsg  } from 'src/app/services/api.service';
import { UserModel } from 'src/app/models/user.model';
import { OrgModel } from 'src/app/models/org.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delete-reseller',
  templateUrl: './delete-reseller.component.html',
  styleUrls: ['./delete-reseller.component.scss']
})
export class ResellerDeleteComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() resellers: UserModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  currentUser?: UserModel;
  isVam: boolean = false;

  resellersSelected: UserModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private location: Location,
    private messageService: MessageService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      console.log(`currentUser:: ${this.currentUser?.username} `);
    }
  }

  ngOnInit(): void {
    console.log(`ngOnInit()`);
    this.load();
  }

  async load() {
  }

  async delete() {
    try {
      this.blockUI?.start();
      let payload: any[] = [];

      this.resellers.forEach(reseller => {
        payload.push(reseller.id);
      });

      console.log(`delete payload is ${payload}`);

      await this.api.request('DELETE', `/resellers`, { body: { ids: payload } });

    } catch (err) {
      console.log(`${JSON.stringify(err)}`);
      const errors = createFailureToDeleteMsg(err, this.resellers, 'reseller');
      this.messageService.addAll(errors);
    } finally {
      this.resellersSelected = [];
      this.onClose.emit();
      this.visible = false;

      this.visibleChange.emit(false);
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.resellersSelected = [];
  }
}
