// Angular Core & Angular Plugins
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// HTTP Stuff
import { AuthInterceptor } from './auth/auth.interceptor';

// Plugins
import { TabViewModule } from 'primeng/tabview';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BlockUIModule } from 'ng-block-ui';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";

// Main Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Global Components
import { HeaderComponent } from './pages/protected/components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

// Directives
import { MarkRequiredAsteriskDirective } from './directives/mark-required-asterisk.directive';

// Pages
import { NotesListComponent } from './pages/protected/components/widgets/notes-list/notes-list.component';
import { AddNoteComponent } from './pages/protected/components/dialogs/organization/add-note/add-note.component';
import { DeleteNoteComponent } from './pages/protected/components/dialogs/organization/delete-note/delete-note.component';
import { FaqComponent } from './pages/protected/pages/faq/faq.component';
import { DevicesComponent } from './pages/protected/pages/devices/devices.component';
import { CustomersComponent } from './pages/protected/pages/customers/customers.component';
import { ResellersComponent } from './pages/protected/pages/resellers/resellers.component';
import { ResellerEditComponent } from './pages/protected/pages/resellers/edit/edit.component';
import { ResellerDeleteComponent } from './pages/protected/pages/resellers/delete-reseller/delete-reseller.component';
import { SettingsComponent } from './pages/protected/pages/settings/settings.component';
import { DeviceEditComponent } from './pages/protected/pages/devices/edit/edit.component';
import { DeviceTypeComponent } from './pages/protected/pages/device-type/device-type.component';
import { DeviceTypeEditComponent } from './pages/protected/pages/device-type/edit/edit.component';
import { UsersComponent } from './pages/protected/pages/users/users.component';
import { UsersEditComponent } from './pages/protected/pages/users/edit/edit.component';
import { UsersDeleteComponent } from './pages/protected/pages/users/delete-user/delete-user.component';
import { DeviceAssignResellerComponent } from './pages/protected/pages/devices/assign-reseller/assign-reseller.component';
import { DeviceAssignCustomerComponent } from './pages/protected/pages/devices/assign-customer/assign-customer.component';
import { DeviceAssignOrgComponent } from './pages/protected/pages/devices/assign-org/assign-org.component';
import { DeviceAssignConfigComponent } from './pages/protected/pages/devices/assign-config/assign-config.component';
import { LoginComponent } from './pages/public/login/login.component';
import { UserLoginNameComponent } from './pages/protected/components/user-login-name/user-login-name.component';
import { ProtectedComponent } from './pages/protected/protected.component';
import { ApiKeysComponent } from './pages/protected/pages/api-keys/api-keys.component';
import { ApiKeysCreateComponent } from './pages/protected/pages/api-keys/create/create.component';
import { MyApiKeysComponent } from './pages/protected/pages/my-api-keys/my-api-keys.component';
import { MyApiKeysCreateComponent } from './pages/protected/pages/my-api-keys/create/create.component';
import { CustomerEditComponent } from './pages/protected/pages/customers/edit/edit.component';
import { CustomerDeleteComponent } from './pages/protected/pages/customers/delete-customer/delete-customer.component';
import { MyOrgComponent } from './pages/protected/pages/my-org/my-org.component';
import { ConfigsComponent } from './pages/protected/pages/configs/configs.component';
import { ConfigEditComponent } from './pages/protected/pages/configs/edit/edit.component';
import { ConfigDeleteComponent } from './pages/protected/pages/configs/delete-config/delete-config.component';
import { VConfigDeleteComponent } from './pages/protected/pages/configs/delete-vconfig/delete-vconfig.component';
import { ConfigHistoryComponent } from './pages/protected/pages/configs/config-history/config-history.component';
import { VConfigEditComponent } from './pages/protected/pages/configs/edit-vconfig/edit.component';
import { EditSipExtensionComponent } from './pages/protected/pages/configs/edit-sip-extension/edit-sip-extension.component';
import { EditPotsExtensionComponent } from './pages/protected/pages/configs/edit-pots-extension/edit-pots-extension.component';
import { AddAdvanceRuleComponent } from './pages/protected/components/dialogs/firewall/add-advanced-rule/add-advanced-rule.component';
import { DeleteAdvancedRuleComponent } from './pages/protected/components/dialogs/firewall/delete-advanced-rule/delete-advanced-rule.component';
import { DashboardComponent } from './pages/protected/pages/dashboard/dashboard.component';
import { AppTopBarComponent } from './pages/protected/app.topbar.component';
import { AppRightPanelComponent } from './pages/protected/app.rightpanel.component';
import { AppMenuComponent } from './pages/protected/components/menu/app.menu.component';
import { AppBreadcrumbComponent } from './pages/protected/app.breadcrumb.component';
import { AppConfigComponent } from './pages/protected/app.config.component';
import { MenuService } from './pages/protected/components/menu/app.menu.service';
import { AppBreadcrumbService } from './pages/protected/app.breadcrumb.service';
import { GrafanaComponent } from './pages/protected/components/grafana/grafana.component';
import { AddStaticDhcpLeaseComponent } from './pages/protected/components/dialogs/network/add-static-dhcp-lease/add-static-dhcp-lease.component';
import { DeleteStaticDhcpLeaseComponent } from './pages/protected/components/dialogs/network/delete-static-dhcp-lease/delete-static-dhcp-lease.component';
import { AddMessageHubChannelComponent } from './pages/protected/components/dialogs/channel-management/add-message-hub-channel/add-message-hub-channel.component';
import { DeleteMessageHubChannelComponent } from './pages/protected/components/dialogs/channel-management/delete-message-hub-channel/delete-message-hub-channel.component';
import { DeleteChannelEndpointComponent }from './pages/protected/components/dialogs/channel-management/delete-channel-endpoint/delete-channel-endpoint.component';

import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DividerModule} from 'primeng/divider';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {GMapModule} from 'primeng/gmap';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { CountboxComponent } from './pages/protected/components/widgets/countbox/countbox.component';
import { BarChartComponent } from './pages/protected/components/widgets/bar-chart/bar-chart.component';
import { PieChartComponent } from './pages/protected/components/widgets/pie-chart/pie-chart.component';
import { DoughnutChartComponent } from './pages/protected/components/widgets/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './pages/protected/components/widgets/line-chart/line-chart.component';
import { DeviceMapComponent } from './pages/protected/components/widgets/device-map/device-map.component';
import { UpdateShadowComponent } from './pages/protected/pages/configs/update-shadow/update-shadow.component';
import { FirmwareComponent } from './pages/protected/pages/firmware/firmware.component';
import { FirmwareEditComponent } from './pages/protected/pages/firmware/edit/edit.component';
import { UploadComponent } from './pages/protected/components/upload/upload.component';
import { FormDropdownComponent } from './pages/protected/components/widgets/form-dropdown/form-dropdown.component';
import { ConvertToResellerComponent } from './pages/protected/pages/customers/convert-to-reseller/convert-to-reseller.component';
import { CancelJobsComponent } from './pages/protected/pages/devices/cancel-jobs/cancel-jobs.component';
import { ProfileDescriptionsComponent } from './pages/protected/pages/configs/profile-descriptions/profile-descriptions.component';
import { DeleteHostNameComponent } from './pages/protected/components/dialogs/firewall/delete-host-name/delete-host-name.component';
import { DeleteIpAddressComponent } from './pages/protected/components/dialogs/firewall/delete-ip-address/delete-ip-address.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    DevicesComponent,
    CustomersComponent,
    NotesListComponent,
    AddNoteComponent,
    DeleteNoteComponent,
    ResellersComponent,
    ResellerEditComponent,
    ResellerDeleteComponent,
    SettingsComponent,
    DeviceEditComponent,
    DeviceTypeComponent,
    DeviceTypeEditComponent,
    MarkRequiredAsteriskDirective,
    UsersComponent,
    UsersEditComponent,
    UsersDeleteComponent,
    DeviceAssignResellerComponent,
    DeviceAssignCustomerComponent,
    DeviceAssignOrgComponent,
    DeviceAssignConfigComponent,
    LoginComponent,
    UserLoginNameComponent,
    ProtectedComponent,
    ApiKeysComponent,
    ApiKeysCreateComponent,
    MyApiKeysComponent,
    MyApiKeysCreateComponent,
    CustomerEditComponent,
    CustomerDeleteComponent,
    MyOrgComponent,
    ConfigsComponent,
    DashboardComponent,
    AppTopBarComponent,
    AppRightPanelComponent,
    AppMenuComponent,
    AppBreadcrumbComponent,
    AppConfigComponent,
    ConfigEditComponent,
    ConfigDeleteComponent,
    VConfigDeleteComponent,
    ConfigHistoryComponent,
    VConfigEditComponent,
    EditSipExtensionComponent,
    EditPotsExtensionComponent,
    AddAdvanceRuleComponent,
    DeleteAdvancedRuleComponent,
    DeleteHostNameComponent,
    DeleteIpAddressComponent,
    UpdateShadowComponent,
    CountboxComponent,
    BarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    LineChartComponent,
    DeviceMapComponent,
    FirmwareComponent,
    FirmwareEditComponent,
    UploadComponent,
    FormDropdownComponent,
    ConvertToResellerComponent,
    GrafanaComponent,
    CancelJobsComponent,
    AddStaticDhcpLeaseComponent,
    DeleteStaticDhcpLeaseComponent,
    ProfileDescriptionsComponent,
    AddMessageHubChannelComponent,
    DeleteMessageHubChannelComponent,
    DeleteChannelEndpointComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // Add forms
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    // Turn on animations
    // See https://www.primefaces.org/primeng/showcase/#/setup
    BrowserAnimationsModule,
    AppRoutingModule,
    // Add PrimeNG
    RippleModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    InputTextareaModule,
    CheckboxModule,
    InputSwitchModule,
    DropdownModule,
    DialogModule,
    ConfirmDialogModule,
    TabViewModule,
    // Plugins
    BlockUIModule.forRoot(),
    // Amplify bullshit
    AmplifyUIAngularModule,
    //
    //
    //
    GMapModule,

    ///
    ///
    //

    AccordionModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,

    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,

    ChipModule,
    ChipsModule,
    CodeHighlighterModule,

    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,

    DividerModule,

    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,



    KnobModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TagModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, MenuService, AppBreadcrumbService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
