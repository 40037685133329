import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrgModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-convert-to-reseller',
  templateUrl: './convert-to-reseller.component.html',
  styleUrls: ['./convert-to-reseller.component.scss']
})
export class ConvertToResellerComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Input() customers: OrgModel[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  currentUser?: UserModel;

  customersSelected: OrgModel[] = [];

  constructor(
    private api: ApiService,
    private messageService: MessageService) { }

  ngOnInit(): void {
  }

  async convertToReseller() {
    try {
      this.blockUI?.start();
      let payload: any[] = [];

      this.customers.forEach(customer => {
        payload.push(customer.id);
      });

      console.log(`Converting Customer org ${payload[0]}to Reseller org`);
      const response = await this.api.request('PUT', `/orgs/${payload[0]}`, { body: { type: 'Reseller' } });

      console.log(`${JSON.stringify(response)}`);

    } catch (err: any) {
      console.log(`${JSON.stringify(err)}`);
      // const errors = createFailureToDeleteMsg(err, this.customers, 'customer');
      // TODO: Make this better:
      const errors = [{
        severity: 'error',
        summary: `Cannot Convert Org ${this.customers[0].name} to Reseller type.`,
        detail: err.error.msg,
        // sticky: true
      }];
      this.messageService.addAll(errors);
    } finally {
      this.customersSelected = [];
      this.onClose.emit();
      this.visible = false;
      this.visibleChange.emit(true);
      this.blockUI?.stop();
    }
  }

  async onCloseDialog() {
    this.customersSelected = [];
  }

}
